export const FIELDS = {
  NAME: 'name',
  ADDRESS: 'address',
  PHONE: 'phone',
  LOCATION_ID: 'location_id',
  DESKTOP_MEDIA_URL: 'desktop_media_url',
  DESKTOP_MEDIA_URL_ALT: 'desktop_media_url_alt',
  MOBILE_MEDIA_URL: 'mobile_media_url',
  MOBILE_MEDIA_URL_ALT: 'mobile_media_url_alt',
  BUTTON_IS_ACTIVE: 'button_is_active',
  BUTTON_TEXT: 'button_text',
  BUTTON_MAP_LINK: 'button_map_link',
  BUTTON_MAP_BACKGROUND_COLOR: 'button_map_background_color',
  BUTTON_MAP_FONT_COLOR: 'button_map_font_color',
  BUTTON_WHATSAPP_LINK: 'button_wa_link',
  BUTTON_WHATSAPP_BACKGROUND_COLOR: 'button_wa_background_color',
  BUTTON_WHATSAPP_FONT_COLOR: 'button_wa_font_color',
  TEMPORARY_WHATSAPP_NUMBER: 'temporary_whatsapp_number',
  TEMPORARY_WHATSAPP_MESSAGE: 'temporary_whatsapp_message',
};
