export const modules = {
  clipboard: {
    matchVisual: false,
  },
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
};

export const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

export const FIELDS = Object.freeze({
  DOCTOR_TITLE: 'doctor_title',
  DOCTOR_DESCRIPTION: 'doctor_description',
  DOCTOR_BACKGROUND_COLOR: 'doctor_background_color',
  DOCTOR_ACTION_BUTTON: 'doctor_action_button',
});

export const DOCTOR_FIELDS = Object.freeze({
  NAME: 'name',
  MEDIA_URL: 'media_url',
});
