import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Form, Icon, Input, Layout, Typography, message } from 'antd';

import _ from 'lodash';
import 'react-quill/dist/quill.snow.css';

import OurOfferApi from 'api/v3/our-offer';

import { FIELDS } from './constants';
import { PageSpinner } from 'components/page-spinner';
import { PdUploader } from 'components/pd-uploader';
import { hasFormErrors } from 'utils';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;

export function OurOffer(props) {
  const { id } = useParams();

  const [formLayout] = useState();
  const [ourOffer, setOurOffer] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const { history, form } = props;
  const { getFieldDecorator, getFieldsError } = form;

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      const payload = _.pick(
        {
          ...ourOffer,
          ...values,
        },
        Object.values(FIELDS)
      );
      if (!err) {
        try {
          setLoadingButton(true);

          if (id) {
            await OurOfferApi.update(id, payload);
          } else {
            await OurOfferApi.create(payload);
          }

          message.success('Promo has been updated');
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
        }

        history.push({
          pathname: '/cms/home/our-offers',
        });
      }
    });
  }

  useEffect(() => {
    async function fetchOurOffer(id) {
      setLoadingPage(true);
      try {
        const { data } = await OurOfferApi.getBy(id);
        const list = data.data;
        setOurOffer(list);
      } catch (err) {
        const errMessage = _.get(err, 'response.data.message');
        message.error(errMessage || 'Cannot connect to the server');
      } finally {
        setLoadingPage(false);
      }
    }
    if (id) {
      fetchOurOffer(id);
    }
  }, [id]);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/home/our-offers">
            <Icon type="caret-left" />
          </Link>
          {id ? 'Edit' : 'Add New'} Our Offer
        </Title>
      </Header>
      <Content>
        <Form layout={formLayout} onSubmit={handleSubmit}>
          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              rules: [{ required: true, message: 'Please input the title!' }],
              initialValue: ourOffer[FIELDS.TITLE],
            })(<Input placeholder="Specify the ourOffer" />)}
          </Form.Item>

          <Form.Item label="Media Alt Text">
            {getFieldDecorator(FIELDS.MEDIA_URL_ALT, {
              initialValue: ourOffer[FIELDS.MEDIA_URL_ALT],
            })(<Input placeholder="Input Media Alt Text" />)}
          </Form.Item>

          <Form.Item label="Picture (600x400 px)">
            <PdUploader
              purpose="ourOffer"
              imgUrl={_.get(ourOffer, FIELDS.MEDIA_URL, '')}
              afterUpload={(url) => {
                setOurOffer((prevState) => ({
                  ...prevState,
                  [FIELDS.MEDIA_URL]: url,
                }));
              }}
            />
          </Form.Item>

          <Form.Item label="Promo URL">
            {getFieldDecorator(FIELDS.LINK_URL, {
              rules: [
                { required: true, message: 'Please input the promo url!' },
              ],
              initialValue: ourOffer[FIELDS.LINK_URL],
            })(<Input placeholder="Promo url" />)}
          </Form.Item>

          <Form.Item label="Description">
            {getFieldDecorator(FIELDS.DESCRIPTION, {
              initialValue: ourOffer[FIELDS.DESCRIPTION],
            })(<TextArea placeholder="Write the description" />)}
          </Form.Item>

          <Form.Item label="Button Text">
            {getFieldDecorator(FIELDS.BUTTON_TEXT, {
              rules: [
                { required: true, message: 'Please input the button text!' },
              ],
              initialValue: ourOffer[FIELDS.BUTTON_TEXT],
            })(<Input placeholder="Input button text" />)}
          </Form.Item>

          <Form.Item label="Button URL">
            {getFieldDecorator(FIELDS.BUTTON_LINK, {
              rules: [
                { required: true, message: 'Please input the button url!' },
              ],
              initialValue: ourOffer[FIELDS.BUTTON_LINK],
            })(<Input placeholder="Input button url" />)}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const OurOfferForm = Form.create({ name: 'validate_ourOffer_edit' })(
  OurOffer
);

export default { OurOffer };
