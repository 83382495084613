import api from './index';

export default {
  getCovidData() {
    return api.get(`/covid_pages`);
  },
  updateCovidData(payload) {
    return api.put(`/covid_pages`, { covid_page: payload });
  },
};
