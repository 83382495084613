import api from './index';

export default {
  get() {
    return api.get(`/promotions`);
  },
  show(id) {
    return api.get(`/promotions/${id}`);
  },
  create(payload) {
    return api.post(`/promotions`, { promotion: payload });
  },
  update(id, payload) {
    return api.put(`/promotions/${id}`, { promotion: payload });
  },
  delete(id) {
    return api.delete(`/promotions/${id}`);
  },
  duplicate(payload) {
    return api.post(`/promotions/duplicate`, { promotion: payload });
  },
};
