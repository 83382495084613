import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Layout,
  Row,
  Typography,
  Icon,
  Modal,
  Switch,
  message,
} from 'antd';

import OurOfferApi from 'api/v3/our-offer';
import { PageSpinner } from 'components/page-spinner';
import { PdSortable } from 'components/pd-sortable';
import { alertErr } from 'utils';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;
const { confirm } = Modal;

const OurOffer = () => {
  const [ourOffers, setOurOffers] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);

  async function getOurOfferList() {
    try {
      setLoadingPage(true);
      const { data } = await OurOfferApi.get();
      const list = data.data;

      setOurOffers(list);
    } catch (err) {
      alertErr(err);
    } finally {
      setLoadingPage(false);
    }
  }

  async function onSortEnd({ oldIndex, newIndex }) {
    let list = [...ourOffers];
    let temp = list[oldIndex];
    list.splice(oldIndex, 1);
    list.splice(newIndex, 0, temp);

    const payload = list.map((item, index) => {
      return {
        id: item.id,
        order_level: index,
        title: item.title,
        title_font_color: item.title_font_color,
        description: item.description,
        media_url: item.media_url,
        link_url: item.link_url,
        promo_button_text: item.promo_button_text,
        promo_button_background_color: item.promo_button_background_color,
        promo_button_font_color: item.promo_button_font_color,
        promo_button_link: item.promo_button_link,
        is_active: item.is_active,
      };
    });

    try {
      setOurOffers(list);
      await OurOfferApi.bulkUpdate(payload);
      message.success('Ordering has been updated');
    } catch (err) {
      alertErr(err);
    }
  }

  const handleIsActive = async (payload) => {
    const body = {
      ...payload,
      is_active: !payload.is_active,
    };

    try {
      if (!payload.checkedValue) {
        await OurOfferApi.updateStatus(payload.id, body);
      } else {
        await OurOfferApi.update(payload.id, body);
      }

      const updatedData = ourOffers.map((ourOffer) => {
        if (ourOffer.id === payload.id) {
          ourOffer.is_active = !ourOffer.is_active;
          return ourOffer;
        } else {
          return ourOffer;
        }
      });

      setOurOffers(updatedData);
      message.success('Berhasil mengubah data');
    } catch (err) {
      alertErr(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      await OurOfferApi.delete(id);
      const newData = ourOffers.filter((offer) => offer.id !== id);

      setOurOffers(newData);
      message.success('Berhasil menghapus data');
    } catch (err) {
      message.error('Cannot connect to the server');
    }
  };

  const showDeleteConfirm = (id, title) => {
    confirm({
      title: 'Delete This Item?',
      content: `Are you sure to delete "${title}"`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        handleDelete(id);
      },
    });
  };

  useEffect(() => {
    getOurOfferList();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const SortableItem = (ourOffer) => {
    return (
      <Row
        type="flex"
        align="middle"
        justify="space-around"
        className="our-offer-item draggable-item"
        gutter={[16, 0]}>
        <Col span={1}>
          <Icon type="drag" />
        </Col>

        {/* image */}
        <Col span={3}>
          <div className="profile-image">
            <img src={ourOffer.media_url} alt={ourOffer.title} />
          </div>
        </Col>

        {/* title and address */}
        <Col span={12}>
          <div className="container--center">
            <Typography.Title level={4}>{ourOffer.title}</Typography.Title>
            <div className="address-description">{ourOffer.link_url}</div>
          </div>
        </Col>

        {/* button group */}
        <Col span={8}>
          <Row
            type="flex"
            align="middle"
            justify="space-around"
            gutter={[32, 0]}>
            <Col xs={{ span: 4, offset: 8 }}>
              <Switch
                checked={ourOffer.is_active}
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={() => handleIsActive(ourOffer)}
              />
            </Col>
            <Col xs={{ span: 10, offset: 2 }}>
              <div className="action-group">
                <div className="edit">
                  <Link to={`/cms/home/our-offers/edit/${ourOffer.id}`}>
                    <Icon type="edit" />
                  </Link>
                </div>

                <div
                  className="delete"
                  onClick={() =>
                    showDeleteConfirm(ourOffer.id, ourOffer.title)
                  }>
                  <Icon type="delete" style={{ color: 'red' }} />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Layout className="pd-cms-home-our-offers">
      <Header>
        <Title>Manage Our Offers</Title>

        <Link to={'/cms/home/our-offers/create'}>
          <Button className="btn-full btn-font" type="primary">
            New Our Offer Item
          </Button>
        </Link>
      </Header>

      <Content id="drag-content">
        <PdSortable
          render={SortableItem}
          items={ourOffers}
          onSortEnd={onSortEnd}
          lockAxis="y"
          pressDelay={200}
          helperContainer={() => document.getElementById('drag-content')}
        />
      </Content>
    </Layout>
  );
};

export default OurOffer;
