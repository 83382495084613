import React, { useContext, useState } from 'react';
import { Button, Icon, Form, Input, Layout, message } from 'antd';

import { AuthenticationContext } from 'contexts/authentication';

import './style.scss';

const { Content } = Layout;

export function LoginPage({ form, history }) {
  const [loadingButton, seLoadingButton] = useState(false);
  const { getFieldDecorator } = form;

  const { login } = useContext(AuthenticationContext);

  async function handleLogin(payload) {
    try {
      seLoadingButton(true);
      await login(payload);

      history.push({
        pathname: '/cms/home/promo-banners',
      });
    } catch (err) {
      if (err.response.data.message) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    } finally {
      seLoadingButton(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        handleLogin(values);
      }
    });
  }

  return (
    <Content className="pd-login">
      <div className="logo-container">
        <img
          src={`${process.env.PUBLIC_URL}/img/logo/logo-red.png`}
          alt="Rata"
          style={{ marginBottom: '50px' }}
        />
      </div>
      <Form onSubmit={(event) => handleSubmit(event)} className="login-form">
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            style={{ backgroundColor: '#BE0D1E', color: 'white' }}
            type="danger"
            htmlType="submit"
            loading={loadingButton}
            className="login-form-button">
            Log in
          </Button>
        </Form.Item>
      </Form>
    </Content>
  );
}

export const LoginPageForm = Form.create({ name: 'login' })(LoginPage);

export default { LoginPage };
