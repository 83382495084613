import React, { useEffect, useState } from 'react';

import { ClinicSeoForm } from 'components/clinic/seo';

import ClinicApi from 'api/clinic';

const ClinicSEO = () => {
  const [content, setContent] = useState({});

  const fetchClinic = async () => {
    const { data } = await ClinicApi.getHeadlines();
    const content = data.data;

    setContent(content);
  };

  const handleContent = (val, key) => {
    if (val) {
      setContent((prevState) => ({
        ...prevState,
        [key]: val,
      }));
    }
  };

  useEffect(() => {
    fetchClinic();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ClinicSeoForm content={content} handleContent={handleContent} />;
};

export default ClinicSEO;
