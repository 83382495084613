import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Icon, message, Popconfirm, Switch } from 'antd';
import { PromotionContext } from 'contexts/promotion';
import { alertErr } from 'utils';

import './style.scss';

export const List = withRouter(() => {
  const {
    deletePromotion,
    promotions,
    updatePromotion,
    duplicatePromotion,
    getPromotion,
  } = useContext(PromotionContext || {});

  const handleDuplicate = async (payload) => {
    try {
      delete Object.assign(payload, { id_promo: payload['id'] })['id'];

      await duplicatePromotion(payload);
      message.success('Berhasil menduplikat promo');

      await getPromotion();
    } catch (err) {
      alertErr(err);
    }
  };

  const handleChange = async (body) => {
    const payload = {
      ...body,
      is_active: !body.is_active,
    };

    try {
      await updatePromotion(body.id, payload);
      message.success('Berhasil mengubah promo');
    } catch (err) {
      alertErr(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deletePromotion(id, { is_deleted: true });
      message.success('Berhasil menghapus promo');
    } catch (err) {
      alertErr(err);
    }
  };

  return (
    <div className="promotions-list">
      {promotions.length > 0 &&
        promotions.map((promotion, index) => (
          <div className="container" key={index}>
            <div>{promotion.title}</div>
            <div className="action-group">
              <div className="switch">
                <Switch
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  checked={promotion.is_active}
                  onChange={() => handleChange(promotion)}
                />
              </div>
              <div className="duplicate">
                <div onClick={() => handleDuplicate(promotion)}>
                  <Icon type="diff" />
                </div>
              </div>
              <div className="edit">
                <Link to={`/cms/promotions/${promotion.id}`}>
                  <Icon type="edit" />
                </Link>
              </div>
              <div className="delete">
                <Popconfirm
                  title="Are you sure？"
                  icon={
                    <Icon type="question-circle-o" style={{ color: 'red' }} />
                  }
                  onConfirm={() => handleDelete(promotion.id)}>
                  <Icon type="delete" />
                </Popconfirm>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
});
