import React, { useEffect, useState } from 'react';
import { Button, Form, Icon, Input, Layout, Typography, message } from 'antd';
import { Link, useParams } from 'react-router-dom';
import _ from 'lodash';

import { PageSpinner } from 'components/page-spinner';

import './style.scss';
import { hasFormErrors } from 'utils';
import question from 'api/v2/blog/faq';

const { Header, Content } = Layout;
const { Title } = Typography;
const FIELDS = {
  QUESTION: 'question',
  ANSWER: 'answer',
};

function BlogFAQsCreateEdit({ form, history }) {
  const [testimony, setTestimony] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    async function getQuestion() {
      setLoadingPage(true);
      try {
        const { data } = await question.getById(id);
        const testimonyData = data.data;

        setTestimony(testimonyData);
      } finally {
        setLoadingPage(false);
      }
    }

    if (id) {
      getQuestion();
    }
  }, [id]);

  const { TextArea } = Input;

  const { getFieldDecorator, getFieldsError } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          const payload = _.pick(
            {
              ...testimony,
              ...values,
            },
            Object.values(FIELDS)
          );
          if (id) {
            await question.update({
              ...payload,
              ...{ id: id },
            });
            message.success('Data has been updated');
          } else {
            await question.create(payload);
            message.success('Data has been created');
          }
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
        }
        history.push({
          pathname: '/cms/reviews-and-promos/faqs',
        });
      }
    });
  }

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/reviews-and-promos/faqs">
            <Icon type="caret-left" />
          </Link>
          {id ? 'Edit' : 'Add New'} FAQ
        </Title>
      </Header>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Question">
            {getFieldDecorator(FIELDS.QUESTION, {
              rules: [{ required: true, message: 'Please input name!' }],
              initialValue: testimony[FIELDS.QUESTION],
            })(<Input placeholder="Write your name here" />)}
          </Form.Item>

          <Form.Item label="Answer">
            {getFieldDecorator(FIELDS.ANSWER, {
              rules: [{ required: true, message: 'Please input message!' }],
              initialValue: testimony[FIELDS.ANSWER],
            })(<TextArea placeholder="Write your message here" />)}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

const FAQsForm = Form.create({
  name: 'validate_testimonials_edit',
})(BlogFAQsCreateEdit);

export default FAQsForm;
