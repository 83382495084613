import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Icon,
  Row,
  Layout,
  Table,
  Typography,
  message,
} from 'antd';

import moment from 'moment';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import QuizAnswersApi from 'api/v3/quiz_answer';

const { Header, Content } = Layout;
const { Title } = Typography;

const QuizAnswers = () => {
  const [answers, setAnswers] = useState([]);

  const columns = [
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telp',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Pernah Pake Behel?',
      dataIndex: 'is_ever',
      render: (is_ever) => <div>{is_ever ? 'Ya' : 'Tidak'}</div>,
    },
    {
      title: 'Tipe Gigi',
      dataIndex: 'teeth_type',
      key: 'teeth_type',
    },
    {
      title: 'Kepuasan Gigi',
      dataIndex: 'is_teeth_satesfied',
      key: 'is_teeth_satesfied',
      render: (is_teeth_satesfied) => (
        <div>{is_teeth_satesfied ? 'Ya' : 'Tidak'}</div>
      ),
    },
    {
      title: 'Mau Meratakan?',
      dataIndex: 'is_want_to',
      key: 'is_want_to',
      render: (is_want_to) => <div>{is_want_to ? 'Ya' : 'Tidak'}</div>,
    },
    {
      title: 'Domisili',
      dataIndex: 'location',
      key: 'location',
    },
  ];

  const manipulateDataWithTableProps = (data) => {
    data.forEach((datum, index) => {
      datum.number = index + 1;

      datum['Data Created'] = moment(datum.created_at).format('DD/MMMM/YYYY');
      datum['Time Created'] = moment(datum.created_at).format('hh:mm:ss');
    });

    setAnswers(data);
  };

  const renameKeys = (obj, newKeys) => {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });

    return Object.assign({}, ...keyValues);
  };

  const exportToCSV = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const newKeys = {
      email: 'Email',
      phone: 'Telp',
      is_ever: 'Pernah pake behel?',
      teeth_type: 'Tipe gigi',
      is_teeth_satesfied: 'Kepuasan gigi',
      is_want_to: 'Mau meratakan?',
      location: 'Domisili',
    };

    const newAnswerKey = answers.map((answer) => {
      delete answer.id;
      delete answer.number;
      delete answer.created_at;
      delete answer.updated_at;

      answer.is_ever = answer.is_ever ? 'Yes' : 'No';
      answer.is_teeth_satesfied = answer.is_teeth_satesfied ? 'Yes' : 'No';
      answer.is_want_to = answer.is_teeth_satesfied ? 'Yes' : 'No';

      return renameKeys(answer, newKeys);
    });

    const ws = XLSX.utils.json_to_sheet(newAnswerKey);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, 'rata_patient_quiz' + fileExtension);
  };

  useEffect(() => {
    const getQuizAnswers = async () => {
      try {
        const { data } = await QuizAnswersApi.get();
        const answers = data.data;

        manipulateDataWithTableProps(answers);
      } catch {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    };

    getQuizAnswers();
  }, []);

  return (
    <Layout className="rata-cms-quiz-answers pd-cms-form-1">
      <Header>
        <Title>Quiz Report</Title>
      </Header>

      <Content>
        <Row>
          <Col xs={24}>
            <Button
              type="primary"
              style={{ marginBottom: 20 }}
              onClick={exportToCSV}>
              <Icon type="download" />
              Download Report
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table dataSource={answers} columns={columns} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default QuizAnswers;
