import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { message } from 'antd';

import { BlogCreateEditForm } from 'components/blog/createEdit';

import BlogResultsApi from 'api/v2/blog/result';

const ResultsCreateEdit = () => {
  let history = useHistory();
  const { id } = useParams();

  const [content, setContent] = useState({
    section_images: [],
  });

  const handleContent = (val, key) => {
    if (val) {
      setContent((prevState) => ({
        ...prevState,
        [key]: val,
      }));
    }
  };

  const createData = async (payload) => {
    await BlogResultsApi.create(payload);

    message.success(`Successfully created new Before After`);

    history.push({
      pathname: '/cms/reviews-and-promos/results',
    });
  };

  const updateData = async (payload) => {
    await BlogResultsApi.update(payload);

    message.success(`Successfully updated new Before After`);

    history.push({
      pathname: '/cms/reviews-and-promos/results',
    });
  };

  useEffect(() => {
    const fetchPromo = async () => {
      const { data } = await BlogResultsApi.getById(id);
      const promoDetail = data.data;

      setContent(promoDetail);
    };

    id && fetchPromo();
  }, [id]);

  return (
    <BlogCreateEditForm
      pageName="Hasil Pakai Rata"
      handleCreate={createData}
      handleUpdate={updateData}
      content={content}
      handleContent={handleContent}
    />
  );
};

export default ResultsCreateEdit;
