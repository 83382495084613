import api from './index';

export default {
  get() {
    return api.get(`/home_pages`);
  },
  showHomePage(section) {
    return api.get(`/home_pages?section=${section}`);
  },
  updateHomePage(payload) {
    return api.put(`/home_pages`, { home_page: payload });
  },
};
