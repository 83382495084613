import api from './index';
import axios from 'axios';

const apiUrl = `${process.env.REACT_APP_API_URL}/v1/cms/uploads/medias`;

export default {
  getUploadURL(file_purpose, file_media) {
    return api.post(`/uploads/medias`, {
      file_purpose,
      file_media,
    });
  },
  uploadImage(payload) {
    const formData = new FormData();

    formData.append('file_purpose', payload.file_purpose);
    formData.append('file_media', payload.file_media);

    return axios.post(apiUrl, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('cms_token') || ''}`,
      },
    });
  },
};
