import { message } from 'antd';
import _ from 'lodash';

/**
 * @param  {object} antFormError
 * @return  {boolean}
 */

export function hasFormErrors(antFormError) {
  return Object.keys(antFormError).some((field) => antFormError[field]);
}

/**
 * @param  {object} error
 * @param  {string} defaultMessage - fallback message when API doesn't give an error message
 */
export function alertErr(
  err,
  defaultMessage = 'connection error, check your connection!'
) {
  const errMessage = _.get(err, 'response.data.message');
  message.error(errMessage || defaultMessage);
}
