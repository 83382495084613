import api from '../index';

export default {
  getPromos(q = '', month = null, status = '') {
    let url = '/blog/promos';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    month && filters.push(`month=${month}`);
    status.trim() !== '' && filters.push(`status=${status}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  getPromo(id) {
    return api.get(`/blog/promos/${id}`);
  },
  createPromo(payload) {
    return api.post(`/blog/promos`, { blog_promos: payload });
  },
  updatePromo(payload) {
    return api.put(`/blog/promos`, { blog_promos: [payload] });
  },
  updatePriority(payload) {
    return api.put(`/blog/promos/priority`, { blog_promos: [payload] });
  },
  updateMultiplePromo(payload) {
    return api.put(`/blog/promos`, { blog_promos: payload });
  },
  createSection(payload) {
    return api.post(`/blog/promo-section-images`, {
      blog_promo_section_images: payload,
    });
  },
  updateSection(id, payload) {
    return api.put(`/blog/promo-section-images/${id}`, {
      blog_promo_section_images: payload,
    });
  },
  deleteSection(id) {
    return api.delete(`/blog/promo-section-images/${id}`);
  },
};
