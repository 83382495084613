import api from './index';

export default {
  get() {
    return api.get(`floating-promo-banners`);
  },
  update(id, payload) {
    return api.put(`floating-promo-banners/${id}`, {
      floating_promo_banners: payload,
    });
  },
};
