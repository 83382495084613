import api from './index';

export default {
  showTermConditionPage(section) {
    if (section) {
      return api.get(`/terms_and_conditions_pages?section=${section}`);
    } else {
      return api.get(`/terms_and_conditions_pages`);
    }
  },
  updateTermConditionPage(payload) {
    return api.put(`/terms_and_conditions_pages`, payload);
  },
};
