import React, { useState } from 'react';
import { message, Button, Form, Icon, Input, Layout, Typography } from 'antd';
import { Link } from 'react-router-dom';

import AdminApi from 'api/admin';

const { Header, Content } = Layout;
const { Title } = Typography;

export function AdminChangePassword({ form, history }) {
  const [loadingButton, setLoadingButton] = useState(false);

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          await AdminApi.changeAdminPassword(values);

          history.push({
            pathname: '/cms/admin',
          });

          message.success('Berhasil mengubah password');
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  return (
    <Layout className="pd-cms-home-promo-banner pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/admin">
            <Icon type="caret-left" />
          </Link>
          Administrator: Change My Password
        </Title>
      </Header>
      <Content>
        <Form
          onSubmit={(event) => handleSubmit(event)}
          className="pd-admin-create-form">
          <Form.Item label="Old Password">
            {getFieldDecorator('old_password', {
              rules: [
                { required: true, message: 'Please input your Old Password!' },
              ],
            })(<Input type="password" placeholder="Old Password" />)}
          </Form.Item>

          <Form.Item label="New Password">
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Please input your Password!' },
              ],
            })(<Input type="password" placeholder="New Password" />)}
          </Form.Item>

          <Form.Item label="New Password Confirmation">
            {getFieldDecorator('password_confirmation', {
              rules: [
                { required: true, message: 'Please input your Password!' },
              ],
            })(
              <Input type="password" placeholder="New Password Confirmation" />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              className="btn-full">
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const AdminChangePasswordForm = Form.create({
  name: 'validate_create_admin',
})(AdminChangePassword);

export default { AdminChangePassword };
