import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ClinicForm } from 'components/clinic/createEdit';

import ClinicApi from 'api/clinic';

const Clinic = () => {
  const { id } = useParams();
  const [content, setContent] = useState({});

  const fetchArticles = async () => {
    const { data } = await ClinicApi.getById(id);
    const content = data.data;

    setContent(content);
  };

  const handleContent = (val, key) => {
    if (val) {
      setContent((prevState) => ({
        ...prevState,
        [key]: val,
      }));
    }
  };

  useEffect(() => {
    id && fetchArticles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <ClinicForm content={content} handleContent={handleContent} />;
};

export default Clinic;
