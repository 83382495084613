import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Form,
  Icon,
  Input,
  Layout,
  Switch,
  Typography,
  message,
} from 'antd';

import ReactQuill from 'react-quill';
import _ from 'lodash';
import 'react-quill/dist/quill.snow.css';

import LocationApi from 'api/v2/location';

import ColorPicker from 'rc-color-picker';
import { PageSpinner } from 'components/page-spinner';
import { FIELDS, formats, modules } from './constants';
import { PdUploader } from 'components/pd-uploader';
import { hasFormErrors } from 'utils';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

export function LocationEdit(props) {
  const [formLayout] = useState();
  const [location, setLocation] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const { history, form } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  const { getFieldDecorator, getFieldsError } = form;

  function setColor(value, key) {
    if (value.color) {
      setLocation((prevState) => ({
        ...prevState,
        [key]: value.color,
      }));
      return value.color;
    }
    return;
  }

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      const payload = _.pick(
        {
          ...location,
          ...values,
        },
        Object.values(FIELDS)
      );
      if (!err) {
        try {
          setLoadingButton(true);

          if (id) {
            await LocationApi.updateLocation(id, payload);
            message.success('Data has been updated');
          } else {
            await LocationApi.createLocation(payload);
            message.success('Data has been created');
          }
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
        }
        history.push({
          pathname: '/cms/home/location',
        });
      }
    });
  }

  useEffect(() => {
    async function getLocation(id) {
      setLoadingPage(true);
      try {
        const {
          data: { data: location },
        } = await LocationApi.getLocation(id);
        setLocation(location);
      } catch (err) {
        const errMessage = _.get(err, 'response.data.message');
        message.error(errMessage || 'Cannot connect to the server');
      } finally {
        setLoadingPage(false);
      }
    }
    if (id) {
      getLocation(id);
    }
  }, [id]);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/home/location">
            <Icon type="caret-left" />
          </Link>
          {id ? 'Edit' : 'Add New'} Location
        </Title>
      </Header>
      <Content>
        <Form layout={formLayout} onSubmit={handleSubmit}>
          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              rules: [{ required: true, message: 'Please input the title!' }],
              initialValue: location[FIELDS.TITLE],
            })(<Input placeholder="Specify the location" />)}
          </Form.Item>

          <Form.Item label="Address">
            {getFieldDecorator(FIELDS.ADDRESS, {
              rules: [{ required: true, message: 'Please input the address!' }],
              initialValue: _.get(location, [FIELDS.ADDRESS], ''),
            })(
              <ReactQuill
                modules={modules}
                formats={formats}
                className="address-quill"
              />
            )}
          </Form.Item>

          <Form.Item label="Show Action Button">
            {getFieldDecorator(FIELDS.ACTION_BUTTON_IS_ACTIVE, {
              valuePropName: 'checked',
              initialValue: _.get(
                location,
                [FIELDS.ACTION_BUTTON_IS_ACTIVE],
                ''
              ),
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Form.Item label="Action Button Text">
            {getFieldDecorator(FIELDS.ACTION_BUTTON_TEXT, {
              rules: [{ required: true, message: 'Required!' }],
              initialValue: _.get(location, [FIELDS.ACTION_BUTTON_TEXT], ''),
            })(<Input placeholder="Input action button text" />)}
          </Form.Item>

          <Form.Item label="Action Button Url">
            {getFieldDecorator(FIELDS.ACTION_BUTTON_URL, {
              rules: [{ required: true, message: 'Required!' }],
              initialValue: _.get(location, [FIELDS.ACTION_BUTTON_URL], ''),
            })(<Input placeholder="Input button url" />)}
          </Form.Item>

          <Form.Item label="Action Button Color">
            {getFieldDecorator(FIELDS.ACTION_BUTTON_COLOR, {
              getValueFromEvent: (e) => setColor(e, FIELDS.ACTION_BUTTON_COLOR),
            })(
              <ColorPicker
                animation="slide-up"
                color={_.get(location, [FIELDS.ACTION_BUTTON_COLOR], '')}
              />
            )}
          </Form.Item>

          <Form.Item label="Picture (600x400 px)">
            <PdUploader
              purpose="location"
              imgUrl={_.get(location, FIELDS.MEDIA_URL, '')}
              afterUpload={(url) => {
                setLocation((prevState) => ({
                  ...prevState,
                  [FIELDS.MEDIA_URL]: url,
                }));
              }}
            />
          </Form.Item>

          <Form.Item label="Picture Alt Text">
            {getFieldDecorator(FIELDS.MEDIA_ALT_TEXT, {
              initialValue: location[FIELDS.MEDIA_ALT_TEXT],
            })(<Input placeholder="Enter Picture Alt Text" />)}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const LocationEditForm = Form.create({ name: 'validate_location_edit' })(
  LocationEdit
);

export default { LocationEdit };
