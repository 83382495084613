import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Layout,
  Row,
  Typography,
  Button,
  message,
  Modal,
  Form,
  // Input,
} from 'antd';

import TestimonyApi from 'api/v3/testimony';
// import homeApi from 'api/v3/home';
import { PageSpinner } from 'components/page-spinner';
// import _ from 'lodash';
import './style.scss';
import { PdSortable } from 'components/pd-sortable';

const { Header, Content } = Layout;
const { Title } = Typography;
const { confirm } = Modal;
// const FIELDS = {
//   TESTIMONY_TITLE: 'testimony_title',
//   TESTIMONY_MOBILE_TITLE: 'testimony_mobile_title',
// };
export const Testimonials = Form.create({ name: 'testimonies' })(({ form }) => {
  const [testimonies, setTestimonies] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  // const [submitLoading, setSubmitLoading] = useState(false);

  async function deletingTestimony(id) {
    try {
      await TestimonyApi.deleteTestimonyDetail(id);
      await getTesimonyList();
      message.success('Item has been deleted');
    } catch {
      message.error('Cannot connect to the server');
    }
  }

  function handleDelete(id) {
    confirm({
      title: 'Are you sure to delete this?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deletingTestimony(id);
      },
      onCancel() {},
    });
  }

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   form.validateFields(async (err, values) => {
  //     if (!err) {
  //       try {
  //         setSubmitLoading(true);
  //         await homeApi.updateHomePage(values);
  //         message.success('Data has been updated');
  //       } catch (err) {
  //         const errMessage = _.get(err, 'response.data.message');
  //         message.error(errMessage || 'Cannot connect to the server');
  //       } finally {
  //         setSubmitLoading(false);
  //       }
  //     }
  //   });
  // }

  async function getTesimonyList() {
    try {
      setLoadingPage(true);
      const { data } = await TestimonyApi.listTestimonies();
      const testimonyList = data.data;

      setTestimonies(testimonyList);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getTesimonyList();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const SortableItem = (testimony) => {
    return (
      <Row
        type="flex"
        align="middle"
        justify="space-around"
        className="testimony-item draggable-item"
        gutter={32}>
        {/* image */}
        <Col span={5}>
          <div className="profile-image">
            <img
              src={testimony.media_url}
              alt={testimony.name}
              className="scale-img"
            />
          </div>
        </Col>

        {/* message and name */}
        <Col span={11}>
          <div className="container--center">
            <div className="title">{testimony.name}</div>
            <div className="description">{testimony.message}</div>
          </div>
        </Col>

        {/* button group */}
        <Col span={8}>
          <Row type="flex" justify="end">
            <Button
              type="danger"
              onClick={handleDelete.bind(this, testimony.id)}>
              Delete
            </Button>
            <Button style={{ marginLeft: 24 }} type="primary">
              <Link to={`/cms/home/testimonials/edit/${testimony.id}`}>
                Edit
              </Link>
            </Button>
          </Row>
        </Col>
      </Row>
    );
  };

  async function onSortEnd({ oldIndex, newIndex }) {
    let list = [...testimonies];
    let temp = list[oldIndex];
    list.splice(oldIndex, 1);
    list.splice(newIndex, 0, temp);

    const payload = list.map((item, index) => {
      return {
        id: item.id,
        order_level: index,
      };
    });

    try {
      setTestimonies(list);
      await TestimonyApi.updateTestimonyOrder(payload);
      message.success('Ordering has been updated');
    } catch {
      message.error('Cannot connect to the server');
    }
  }

  // const { getFieldDecorator } = form;

  return (
    <Layout className="pd-cms-home-testimonials pd-cms-form-1">
      <Header>
        <Title>Testimonies</Title>
      </Header>

      <Content id="drag-content">
        {/* <Form onSubmit={handleSubmit} className="pd-margin-bottom-xl">
          <Form.Item label="Desktop title">
            {getFieldDecorator(FIELDS.TESTIMONY_TITLE, {
              rules: [{ required: true, message: 'Please input the title!' }],
              initialValue: titles[FIELDS.TESTIMONY_TITLE],
            })(<Input placeholder="Input title" />)}
          </Form.Item>

          <Form.Item label="Mobile title">
            {getFieldDecorator(FIELDS.TESTIMONY_MOBILE_TITLE, {
              rules: [{ required: true, message: 'Please input the title!' }],
              initialValue: titles[FIELDS.TESTIMONY_MOBILE_TITLE],
            })(<Input placeholder="Input mobile title" />)}
          </Form.Item>

          <Form.Item>
            <Button
              loading={submitLoading}
              className="btn-full"
              type="primary"
              htmlType="submit">
              Publish
            </Button>
          </Form.Item>
        </Form>
        */}
        <Button
          type="primary"
          style={{ fontSize: '18px' }}
          className="pd-margin-bottom-lg">
          <Link to={'/cms/home/testimonials/create'}>Add New Testimony</Link>
        </Button>

        <PdSortable
          render={SortableItem}
          items={testimonies}
          onSortEnd={onSortEnd}
          lockAxis="y"
          pressDelay={100}
          helperContainer={() => document.getElementById('drag-content')}
        />
      </Content>
    </Layout>
  );
});
