import api from '../index';

export default {
  get(q = '', month = null, status = '') {
    let url = '/blog/posts';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    month && filters.push(`month=${month}`);
    status.trim() !== '' && filters.push(`status=${status}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  getById(id) {
    return api.get(`/blog/posts/${id}`);
  },
  create(payload) {
    return api.post(`/blog/posts`, { blog_posts: payload });
  },
  update(payload) {
    return api.put(`/blog/posts`, { blog_posts: [payload] });
  },
  createSection(payload) {
    return api.post(`/blog/post-section-images`, {
      blog_post_section_images: payload,
    });
  },
  updateSection(id, payload) {
    return api.put(`/blog/post-section-images/${id}`, {
      blog_post_section_images: payload,
    });
  },
  deleteSection(id) {
    return api.delete(`/blog/post-section-images/${id}`);
  },
};
