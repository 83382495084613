import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { get } from 'lodash';

import { Col, Layout, Row, Typography, Button, message, Modal } from 'antd';

import BannerApi from 'api/banner';
import { PageSpinner } from 'components/page-spinner';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;
const { confirm } = Modal;

export function PromoBanner() {
  const [banners, setBanners] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    async function getBannerList() {
      try {
        const { data } = await BannerApi.listBanners();
        const bannerList = data.data;

        setBanners(bannerList);
      } finally {
        setLoadingPage(false);
      }
    }

    getBannerList();
  }, []);

  function onHandleDelete(id) {
    confirm({
      title: 'Are you sure hide this banner?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        async function deletingBanner() {
          try {
            setLoadingButton(true);
            await BannerApi.deleteBanner(id);
            await BannerApi.updateBannerOrder([
              { id, order_level: banners.length + 1 },
            ]);

            message.success('Berhasil menyembunyikan banner');
          } catch {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          } finally {
            setLoadingButton(false);
            window.location.reload();
          }
        }
        deletingBanner();
      },
      onCancel() {},
    });
  }

  async function onHandleRestore(banner) {
    try {
      setLoadingButton(true);
      await BannerApi.updateBannerDetail({
        id: get(banner, 'id', ''),
        status: 'active',
      });
      message.success('Berhasil merestore banner');
    } catch (err) {
      if (err.response.data.message) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    } finally {
      setLoadingButton(false);
      // window.location.reload();
    }
  }

  function countHidden() {
    return banners.filter(({ status = '' }) => status === 'inactive').length;
  }

  const SortableItem = SortableElement(({ value: banner }) => {
    return (
      <div
        key={banner.id}
        className="cms-list draggable-item"
        style={{ marginTop: 20 }}>
        <Row type="flex" align="middle" gutter={16}>
          <Col xs={6}>
            <div className="container--image">
              <img
                src={banner.desktop_media_url}
                alt={banner.title}
                className="scale-img"
              />
            </div>
          </Col>

          <Col xs={13}>
            <div className="container--center">
              <div className="title">{banner.title}</div>

              <div className="description">{banner.description}</div>
            </div>
          </Col>

          <Col xs={{ span: 4, offset: 1 }}>
            <div className="container--center container--center-row">
              {banner.status === 'active' && (
                <Button
                  type="danger"
                  onClick={() => onHandleDelete(banner.id)}
                  loading={loadingButton}>
                  Hide
                </Button>
              )}
              <Button type="primary" loading={loadingButton}>
                <Link
                  to={{
                    pathname: `/cms/home/banners/${banner.id}`,
                    data: { banner },
                  }}>
                  Edit
                </Link>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map(
          (item, index) =>
            item.status === 'active' && (
              <SortableItem key={item.id} index={index} value={item} />
            )
        )}
      </div>
    );
  });

  async function onSortEnd({ oldIndex, newIndex }) {
    let list = [...banners];
    let temp = list[oldIndex];
    list.splice(oldIndex, 1);
    list.splice(newIndex, 0, temp);

    let payload = [];
    list.forEach((item, index) => {
      let order_attr = {
        id: item.id,
        order_level: index,
      };
      payload.push(order_attr);
    });

    try {
      setBanners(list);
      await BannerApi.updateBannerOrder(payload);
      message.success('Urutan banner telah berhasil diubah');
    } catch {
      message.error('Tidak dapat menghubungi server, cek koneksi');
    }
  }

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-home-promo-banner pd-cms-form-1">
      <Header>
        <Title>Banners</Title>
        {/* <Button type="primary" style={{ fontSize: '18px' }}>
          <Link to={'/cms/home/promo-banners/create'}>Create New Banner</Link>
        </Button> */}
      </Header>
      <Content>
        <Title level={2}>Active Banners</Title>

        <div id="drag-content">
          <SortableList
            items={banners}
            onSortEnd={onSortEnd}
            lockAxis="y"
            pressDelay={200}
            helperContainer={() => document.getElementById('drag-content')}
          />
        </div>

        {countHidden() > 0 && (
          <>
            <Title style={{ marginTop: '50px' }} level={2}>
              Hidden Banners
            </Title>

            {banners &&
              banners.map(
                (banner) =>
                  banner.status === 'inactive' && (
                    <div
                      key={banner.id}
                      className="cms-list"
                      style={{ marginTop: 20 }}>
                      <Row type="flex" align="middle" gutter={16}>
                        <Col xs={6}>
                          <div className="container--image">
                            <img
                              src={banner.desktop_media_url}
                              alt={banner.title}
                              className="scale-img"
                            />
                          </div>
                        </Col>

                        <Col xs={13}>
                          <div className="container--center">
                            <div className="title">{banner.title}</div>

                            <div className="description">
                              {banner.description}
                            </div>
                          </div>
                        </Col>

                        <Col xs={{ span: 4, offset: 1 }}>
                          <div className="container--center container--center-row">
                            <Button
                              type="primary"
                              onClick={() => onHandleRestore(banner)}
                              loading={loadingButton}>
                              Restore
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )
              )}
          </>
        )}
      </Content>
    </Layout>
  );
}

export default { PromoBanner };
