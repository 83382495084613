import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ClinicApi from 'api/clinic';

import { alertErr } from 'utils';

import SelectedMessage from 'components/blog/selected-message';

import {
  Button,
  Col,
  Icon,
  Layout,
  Pagination,
  Table,
  Typography,
  Row,
  Switch,
  Modal,
  message,
} from 'antd';

import { PageSpinner } from 'components/page-spinner';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;
const { confirm } = Modal;

const Promos = () => {
  const [promos, setPromos] = useState([]);
  const [pages, setPages] = useState({
    total_data: 0,
    current_page: 1,
    next_page: 0,
    prev_page: 0,
    total_pages: 1,
  });
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const fetchClinic = async () => {
    try {
      setLoadingPage(true);
      const { data } = await ClinicApi.get();
      const response = data.data;

      const total_data = data.total_data;
      const current_page = data.current_page;
      const next_page = data.next_page;
      const prev_page = data.prev_page;
      const total_pages = data.total_pages;

      setPages({
        total_data,
        current_page,
        next_page,
        prev_page,
        total_pages,
      });

      manipulateDataWithTableProps(response);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleChange = async (key, data, id, checkedValue) => {
    try {
      await ClinicApi.updateStatus({ id, [key]: !checkedValue }, id);

      const temp = data.slice();

      const updatedArticles = temp.map((article) => {
        if (article.id === id) {
          article[key] = !article[key];
        }

        return article;
      });

      manipulateDataWithTableProps(updatedArticles);
      message.success('Berhasil mengubah status klinik');
    } catch (err) {
      alertErr(err);
    }
  };

  const showDeleteConfirm = (id, title) => {
    confirm({
      title: 'Delete This Item?',
      content: `Are you sure to delete "${title}"`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleDelete = async (id) => {
    try {
      await ClinicApi.delete(id);

      setPromos((prevState) => prevState.filter((item) => item.id !== id));
      message.success('Berhasil menghapus klinik');

      fetchClinic();
    } catch (err) {
      alertErr(err);
    }
  };

  const handleMultipleDelete = async () => {
    const payload = selectedRows.map((row) => {
      row.is_deleted = true;

      return row;
    });

    try {
      await ClinicApi.updateMultiple(payload);

      fetchClinic();
      setSelectedRows([]);
      message.success('Berhasil menghapus artikel');
    } catch (err) {
      alertErr(err);
    }
  };

  const manipulateDataWithTableProps = (data) => {
    data.forEach((datum) => {
      datum.key = datum.id;

      datum.is_publish = (
        <Switch
          checked={datum.is_publish}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={() =>
            handleChange('is_publish', data, datum.id, datum.is_publish)
          }
        />
      );

      datum.action = (
        <div className="action-icon">
          <div className="edit">
            <Link to={`/cms/clinics/edit/${datum.id}`}>
              <Icon type="edit" />
            </Link>
          </div>

          <div className="delete">
            <div
              className="delete"
              onClick={() => showDeleteConfirm(datum.id, datum.name)}>
              <Icon type="delete" />
            </div>
          </div>
        </div>
      );
    });

    setPromos(data);
  };

  useEffect(() => {
    fetchClinic();

    return () => setPromos([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Clinic Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      sorter: (a, b) => a.location_name.length - b.location_name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Address',
      dataIndex: 'address',
      sorter: (a, b) => a.address.length - b.address.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      sorter: (a, b) => a.phone - b.phone,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Publish',
      dataIndex: 'is_publish',
      key: 'is_publish',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const handleChangePage = async (value) => {
    pages.current_page = value;
    setPages({ ...pages });

    const { data } = await ClinicApi.get(value);

    const response = data.data;
    manipulateDataWithTableProps(response);
  };

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="rata-cms-review-and-promo-promos pd-cms-form-1">
      <Header>
        <Title>Clinic Rata</Title>
      </Header>

      <Content>
        <Row>
          <Col xs={24}>
            <Link to="/cms/clinics/create">
              <Button type="primary">Create New Clinic</Button>
            </Link>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table dataSource={promos} columns={columns} pagination={false} />

            <div className="pagination">
              <Pagination
                current={pages.current_page}
                onChange={handleChangePage}
                total={pages.total_data}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <SelectedMessage
              handleMultipleDelete={handleMultipleDelete}
              rows={selectedRows.length}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Promos;
