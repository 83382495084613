import api from '../index';

export default {
  get(q = '', month = null, status = '') {
    let url = '/blog/rata-updates';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    month && filters.push(`month=${month}`);
    status.trim() !== '' && filters.push(`status=${status}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  getById(id) {
    return api.get(`/blog/rata-updates/${id}`);
  },
  create(payload) {
    return api.post(`/blog/rata-updates`, { blog_rata_updates: payload });
  },
  update(payload) {
    return api.put(`/blog/rata-updates`, { blog_rata_updates: [payload] });
  },
  updateMultiple(payload) {
    return api.put(`/blog/rata-updates`, { blog_rata_updates: payload });
  },
  updatePriority(payload) {
    return api.put(`/blog/rata-updates/priority`, {
      blog_rata_updates: [payload],
    });
  },
  createSection(payload) {
    return api.post(`/blog/rata-update-section-images`, {
      blog_rata_update_section_images: payload,
    });
  },
  updateSection(id, payload) {
    return api.put(`/blog/rata-update-section-images/${id}`, {
      blog_rata_update_section_images: payload,
    });
  },
  deleteSection(id) {
    return api.delete(`/blog/rata-update-section-images/${id}`);
  },
};
