import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Layout, Typography, message } from 'antd';
import './style.scss';
import { PdUploader } from 'components/pd-uploader';
import _ from 'lodash';
import { alertErr, hasFormErrors } from 'utils';
import { PageSpinner } from 'components/page-spinner';
import buyingStepsAPI from 'api/v2/buyingStepPage';
const { Header, Content } = Layout;
const { Title } = Typography;

const FIELDS = {
  TITLE: 'header_title',
  DESCRIPTION: 'header_description',
  DESKTOP_MEDIA_URL: 'header_desktop_media',
  MOBILE_MEDIA_URL: 'header_mobile_media',
};

function CaraKonsultasiBanner(props) {
  const [banner, setBanner] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const { form } = props;
  const { TextArea } = Input;

  const { getFieldDecorator, getFieldsError } = form;

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          // avoid overfetch and unwanted mutation
          const payload = _.pick(
            { ...banner, ...values },
            Object.values(FIELDS)
          );
          await buyingStepsAPI.updateBuyingStepPage(payload);
          message.success('Data has been updated');
        } catch (err) {
          alertErr(err);
        } finally {
          setLoadingButton(false);
          await getBanner();
        }
      }
    });
  }

  async function getBanner() {
    setLoadingPage(true);
    try {
      const res = await buyingStepsAPI.getBuyingStep();
      const banner = _.pick(_.get(res, 'data.data'), Object.values(FIELDS));
      setBanner(banner);
    } catch (err) {
      alertErr(err);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getBanner();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-home-promo-banner pd-cms-form-1">
      <Header>
        <Title>Banner</Title>
      </Header>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Desktop Banner (1440x660 px)">
            <PdUploader
              purpose="banner"
              afterUpload={(url) => {
                setBanner((prevState) => ({
                  ...prevState,
                  [FIELDS.DESKTOP_MEDIA_URL]: url,
                }));
              }}
              imgUrl={banner[FIELDS.DESKTOP_MEDIA_URL]}
            />
          </Form.Item>

          <Form.Item label="Mobile Banner (640x1190 px)">
            <PdUploader
              purpose="banner"
              afterUpload={(url) => {
                setBanner((prevState) => ({
                  ...prevState,
                  [FIELDS.MOBILE_MEDIA_URL]: url,
                }));
              }}
              imgUrl={banner[FIELDS.MOBILE_MEDIA_URL]}
            />
          </Form.Item>

          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              initialValue: banner[FIELDS.TITLE],
            })(<Input placeholder="Specify the banner title" />)}
          </Form.Item>

          <Form.Item label="Description">
            {getFieldDecorator(FIELDS.DESCRIPTION, {
              initialValue: banner[FIELDS.DESCRIPTION],
            })(<TextArea placeholder="Write the banner description" />)}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const CaraKonsultasiBannerForm = Form.create({
  name: 'validate_hone_promo_banner',
})(CaraKonsultasiBanner);
