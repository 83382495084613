import api from './index';

export default {
  get(q = '', month = null, status = '') {
    let url = '/users';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    month && filters.push(`month=${month}`);
    status.trim() !== '' && filters.push(`status=${status}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },

  getByCampaign(id) {
    return api.get(`/users/campaigns/${id}`);
  },
};
