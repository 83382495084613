import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Icon,
  Row,
  Layout,
  Table,
  Typography,
  message,
  Select,
  Input,
} from 'antd';

import moment from 'moment';

import MONTHS from 'constants/index';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import QuizAnswersApi from 'api/v3/quiz_answer';

const { Header, Content } = Layout;
const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const QuizAnswers = () => {
  const [defaultValue, setDefaultValue] = useState([]);
  const [answers, setAnswers] = useState([]);

  const columns = [
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
    },
    {
      title: 'Time',
      dataIndex: 'Time',
      key: 'Time',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Telp',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Bentuk Gigi',
      dataIndex: 'teeth_type',
      render: (teeth_type) => <div>{teeth_type}</div>,
    },
    {
      title: 'Senyum Gigi',
      dataIndex: 'is_teeth_when_smile',
      key: 'is_teeth_when_smile',
      render: (is_teeth_when_smile) => (
        <div>{is_teeth_when_smile ? 'Tentu Saja' : 'Malu'}</div>
      ),
    },
    {
      title: 'Makanan Nyangkut',
      dataIndex: 'is_food_stuck',
      key: 'is_food_stuck',
      render: (is_food_stuck) => (
        <div>{is_food_stuck ? 'Iya dong' : 'Gak dong!'}</div>
      ),
    },
    {
      title: 'Gusi Berdarah',
      dataIndex: 'is_bleed',
      key: 'is_bleed',
      render: (is_bleed) => <div>{is_bleed ? 'Kok tau sih' : 'Ga dong!'}</div>,
    },
  ];

  const manipulateDataWithTableProps = (data) => {
    data.forEach((datum, index) => {
      datum.number = index + 1;

      datum['Date'] = moment(datum.created_at).format('DD-MMMM-YYYY');
      datum['Time'] = moment(datum.created_at).format('HH:mm:ss');
      if (datum.user !== null) {
        datum['name'] = datum.user_name;
        datum['phone'] = datum.user_phone;
        datum['email'] = datum.user_email;
        datum['teeth_type'] =
          datum.user_teeth_type.charAt(0).toUpperCase() +
          datum.user_teeth_type.slice(1);
      } else {
        datum['name'] = '';
        datum['phone'] = '';
        datum['email'] = '';
        datum['teeth_type'] = '';
      }
    });

    setAnswers(data);
  };

  const renameKeys = (obj, newKeys) => {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });

    return Object.assign({}, ...keyValues);
  };

  const exportToCSV = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const newKeys = {
      name: 'Name',
      email: 'Email',
      phone: 'Telp',
      teeth_type: 'Bentuk Gigi',
      is_teeth_when_smile: 'Senyum Gigi',
      is_food_stuck: 'Makanan Nyangkut',
      is_bleed: 'Gusi Berdarah',
    };

    const newAnswerKey = answers.map((answer) => {
      delete answer.id;
      delete answer.user;
      delete answer.number;
      delete answer.created_at;
      delete answer.updated_at;

      answer.is_teeth_when_smile = answer.is_teeth_when_smile ? 'Yes' : 'No';
      answer.is_food_stuck = answer.is_food_stuck ? 'Yes' : 'No';
      answer.is_bleed = answer.is_bleed ? 'Yes' : 'No';

      return renameKeys(answer, newKeys);
    });

    const ws = XLSX.utils.json_to_sheet(newAnswerKey);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, 'rata_patient_quiz' + fileExtension);
  };

  const handleChangeMonth = (value) => {
    const find = defaultValue.filter((item) =>
      item['Date'].toLowerCase().includes(value.toLowerCase())
    );

    manipulateDataWithTableProps(find);
  };

  const handleSearch = async (value) => {
    const find = defaultValue.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.email.toLowerCase().includes(value.toLowerCase()) ||
        item.phone.toLowerCase().includes(value.toLowerCase())
    );

    manipulateDataWithTableProps(find);
  };

  useEffect(() => {
    const getQuizAnswers = async () => {
      try {
        const { data } = await QuizAnswersApi.getNew();
        const answers = data.data;

        setDefaultValue(answers);
        manipulateDataWithTableProps(answers);
      } catch {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    };

    getQuizAnswers();
  }, []);

  return (
    <Layout className="rata-cms-quiz-answers pd-cms-form-1">
      <Header>
        <Title>Quiz Report</Title>
      </Header>

      <Content>
        <Row gutter={[24, 12]}>
          <Col xs={12}>
            <div className="label">Search by</div>
            <Search onSearch={(value) => handleSearch(value)} />
          </Col>

          <Col xs={12}>
            <div className="label">Filter by</div>

            <Row gutter={[12, 0]}>
              <Col xs={8}>
                <Select
                  defaultValue="All Month"
                  onChange={handleChangeMonth}
                  style={{ width: '100%' }}>
                  {MONTHS.map((month, id) => (
                    <Option key={id} value={month.name}>
                      {month.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col xs={8}>
                <Button
                  type="primary"
                  style={{ marginBottom: 20 }}
                  onClick={exportToCSV}>
                  <Icon type="download" />
                  Download Report
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table dataSource={answers} columns={columns} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default QuizAnswers;
