import React, { useEffect, useState } from 'react';
import { Button, Form, Icon, Input, Layout, Typography, message } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import TestimonyApi from 'api/v3/testimony';

import { PageSpinner } from 'components/page-spinner';

import './style.scss';
import { PdUploader } from 'components/pd-uploader';
import { hasFormErrors } from 'utils';

const { Header, Content } = Layout;
const { Title } = Typography;
const FIELDS = {
  NAME: 'name',
  DURATION: 'duration',
  MESSAGE: 'message',
  PICTURE: 'media_url',
  PICTURE_ALT_TEXT: 'media_alt_text',
};

export function TestimonialsCreateEdit({ form, match, history }) {
  const [testimony, setTestimony] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const id = match.params.id;

  useEffect(() => {
    async function getTesimonyList() {
      setLoadingPage(true);
      try {
        const { data } = await TestimonyApi.getTestimony(id);
        const testimonyData = data.data;

        setTestimony(testimonyData);
      } finally {
        setLoadingPage(false);
      }
    }

    if (id) {
      getTesimonyList();
    }
  }, [id]);

  const { TextArea } = Input;

  const { getFieldDecorator, getFieldsError } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      const testimonyId = match.params.id;
      if (!err) {
        try {
          setLoadingButton(true);
          const payload = {
            ...testimony,
            ...values,
          };

          if (testimonyId) {
            await TestimonyApi.updateTestimonyDetail(id, {
              testimonies: payload,
            });
            message.success('Data has been updated');
          } else {
            await TestimonyApi.createTestimony({
              ...payload,
              order_level: 0,
              status: 'active',
            });
            message.success('Data has been created');
          }

          history.push({
            pathname: '/cms/home/testimonials',
          });
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/home/testimonials">
            <Icon type="caret-left" />
          </Link>
          {id ? 'Edit' : 'Add New'} Testimony
        </Title>
      </Header>
      <Content>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item label="Name">
            {getFieldDecorator(FIELDS.NAME, {
              rules: [{ required: true, message: 'Please input name!' }],
              initialValue: testimony[FIELDS.NAME],
            })(<Input placeholder="Write your name here" />)}
          </Form.Item>

          <Form.Item label="Duration">
            {getFieldDecorator(FIELDS.DURATION, {
              rules: [{ required: true, message: 'Please input duration!' }],
              initialValue: testimony[FIELDS.DURATION],
            })(<Input placeholder="Write the duration" />)}
          </Form.Item>

          <Form.Item label="Message">
            {getFieldDecorator(FIELDS.MESSAGE, {
              rules: [{ required: true, message: 'Please input message!' }],
              initialValue: testimony[FIELDS.MESSAGE],
            })(<TextArea placeholder="Write your message here" />)}
          </Form.Item>

          <Form.Item label="Picture (640x640 px)">
            <PdUploader
              purpose="testimony"
              imgUrl={_.get(testimony, FIELDS.PICTURE, '')}
              afterUpload={(url) => {
                setTestimony((prevState) => ({
                  ...prevState,
                  [FIELDS.PICTURE]: url,
                }));
              }}
            />
          </Form.Item>

          <Form.Item label="Picture Alt Text">
            {getFieldDecorator(FIELDS.PICTURE_ALT_TEXT, {
              rules: [{ message: 'Please enter image alt text' }],
              initialValue: testimony[FIELDS.PICTURE_ALT_TEXT],
            })(<Input placeholder="Please enter picture alt text" />)}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const TestimonialsCreateEditForm = Form.create({
  name: 'validate_testimonials_edit',
})(TestimonialsCreateEdit);

export default { TestimonialsCreateEdit };
