import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Layout, Switch, Typography, message } from 'antd';
import ColorPicker from 'rc-color-picker';
import descriptionAPI from 'api/v2/description';
import './style.scss';
import { PdUploader } from 'components/pd-uploader';
import _ from 'lodash';
import { hasFormErrors } from 'utils';
import { PageSpinner } from 'components/page-spinner';
const { Header, Content } = Layout;
const { Title } = Typography;

const FIELDS = {
  ID: 'id',
  TITLE: 'video_title',
  DESCRIPTION: 'video_description',
  MEDIA_ALT_TEXT: 'video_media_alt_text',
  DESKTOP_MEDIA_URL: 'video_url',
  VIDEO_THUMBNAIL_URL: 'video_thumbnail_url',
  ACTION_BUTTON: 'video_action_button',
  ACTION_BUTTON_IS_ACTIVE: 'video_action_button_is_active',
  LINK_URL: 'video_button_url',
  BUTTON_BACKGROUND_COLOR: 'video_button_background_color',
  BUTTON_FONT_COLOR: 'video_button_font_color',
  SECOND_BUTTON_FONT_COLOR: 'video_second_button_font_color',
  SECOND_ACTION_BUTTON: 'video_second_action_button',
  SECOND_ACTION_BUTTON_IS_ACTIVE: 'video_second_action_button_is_active',
  SECOND_LINK_URL: 'second_button_url',
  SECOND_BUTTON_COLOR: 'video_second_button_background_color',
};

function VideoSection(props) {
  const [banner, setBanner] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const { form } = props;
  const { TextArea } = Input;

  const { getFieldDecorator, getFieldsError } = form;

  function changeColor(value, key) {
    if (value.color) {
      setBanner((prevState) => ({
        ...prevState,
        [key]: value.color,
      }));
      return value.color;
    }
    return;
  }

  async function getDescription() {
    setLoadingPage(true);
    try {
      const res = await descriptionAPI.getDescription();
      const data = _.get(res, 'data.data');

      // use only key declared in FIELDS
      setBanner(_.pick(data, Object.values(FIELDS)));
    } catch (err) {
      const errMessage = _.get(err, 'response.data.message');
      message.error(errMessage || 'Cannot connect to the server');
    } finally {
      setLoadingPage(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const { id } = banner;

          setLoadingButton(true);
          // avoid overfetch and unwanted mutation
          const payload = _.pick(
            { ...banner, ...values },
            Object.values(FIELDS)
          );
          await descriptionAPI.updateDescription(id, payload);
          message.success('Data has been updated');
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
          getDescription();
        }
      }
    });
  }

  useEffect(() => {
    getDescription();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-video-section pd-cms-form-1">
      <Header>
        <Title>Video Section</Title>
      </Header>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Video">
            <PdUploader
              purpose="description"
              type="video"
              afterUpload={(url) => {
                setBanner((prevState) => ({
                  ...prevState,
                  [FIELDS.DESKTOP_MEDIA_URL]: url,
                }));
              }}
              imgUrl={banner[FIELDS.DESKTOP_MEDIA_URL]}
            />
          </Form.Item>

          <Form.Item label="Video Thumbnail (260x400 px)">
            <PdUploader
              purpose="description"
              type="image"
              afterUpload={(url) => {
                setBanner((prevState) => ({
                  ...prevState,
                  [FIELDS.VIDEO_THUMBNAIL_URL]: url,
                }));
              }}
              imgUrl={banner[FIELDS.VIDEO_THUMBNAIL_URL]}
            />
          </Form.Item>

          <Form.Item label="Banner Alt Text">
            {getFieldDecorator(FIELDS.MEDIA_ALT_TEXT, {
              initialValue: banner[FIELDS.MEDIA_ALT_TEXT],
            })(<Input placeholder="Enter alt text for the image" />)}
          </Form.Item>

          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              initialValue: banner[FIELDS.TITLE],
            })(<Input placeholder="Specify the banner title" />)}
          </Form.Item>

          <Form.Item label="Description">
            {getFieldDecorator(FIELDS.DESCRIPTION, {
              initialValue: banner[FIELDS.DESCRIPTION],
            })(<TextArea placeholder="Write the banner description" />)}
          </Form.Item>

          <Form.Item label="Show Action Button">
            {getFieldDecorator(FIELDS.ACTION_BUTTON_IS_ACTIVE, {
              valuePropName: 'checked',
              initialValue: banner[FIELDS.ACTION_BUTTON_IS_ACTIVE],
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Form.Item label="Action Button">
            {getFieldDecorator(FIELDS.ACTION_BUTTON, {
              initialValue: banner[FIELDS.ACTION_BUTTON],
            })(<Input placeholder="Specify the button text" />)}
          </Form.Item>

          <Form.Item label="Button Link Url">
            {getFieldDecorator(FIELDS.LINK_URL, {
              initialValue: banner[FIELDS.LINK_URL],
            })(<Input placeholder="Specify the button link url" />)}
          </Form.Item>

          <Form.Item label="Button Color">
            {getFieldDecorator(FIELDS.BUTTON_BACKGROUND_COLOR, {
              getValueFromEvent: (e) =>
                changeColor(e, FIELDS.BUTTON_BACKGROUND_COLOR),
              initialValue: banner[FIELDS.BUTTON_BACKGROUND_COLOR],
            })(
              <ColorPicker
                animation="slide-up"
                color={banner[FIELDS.BUTTON_BACKGROUND_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item label="Button Font Color">
            {getFieldDecorator(FIELDS.BUTTON_FONT_COLOR, {
              getValueFromEvent: (e) =>
                changeColor(e, FIELDS.BUTTON_FONT_COLOR),
              initialValue: banner[FIELDS.BUTTON_FONT_COLOR],
            })(
              <ColorPicker
                animation="slide-up"
                color={banner[FIELDS.BUTTON_FONT_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item label="Show Second Action Button">
            {getFieldDecorator(FIELDS.SECOND_ACTION_BUTTON_IS_ACTIVE, {
              valuePropName: 'checked',
              initialValue: banner[FIELDS.SECOND_ACTION_BUTTON_IS_ACTIVE],
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Form.Item label="Second Action Button">
            {getFieldDecorator(FIELDS.SECOND_ACTION_BUTTON, {
              initialValue: banner[FIELDS.SECOND_ACTION_BUTTON],
            })(<Input placeholder="Specify the second button text" />)}
          </Form.Item>

          <Form.Item label="Second Button Link Url">
            {getFieldDecorator(FIELDS.SECOND_LINK_URL, {
              initialValue: banner[FIELDS.SECOND_LINK_URL],
            })(<Input placeholder="Specify the second link url" />)}
          </Form.Item>

          <Form.Item label="Second Button Color">
            {getFieldDecorator(FIELDS.SECOND_BUTTON_COLOR, {
              initialValue: banner[FIELDS.SECOND_BUTTON_COLOR],
              getValueFromEvent: (e) =>
                changeColor(e, FIELDS.SECOND_BUTTON_COLOR),
            })(
              <ColorPicker
                animation="slide-up"
                color={banner[FIELDS.SECOND_BUTTON_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item label="Second Button Font Color">
            {getFieldDecorator(FIELDS.SECOND_BUTTON_FONT_COLOR, {
              getValueFromEvent: (e) =>
                changeColor(e, FIELDS.SECOND_BUTTON_FONT_COLOR),
              initialValue: banner[FIELDS.SECOND_BUTTON_FONT_COLOR],
            })(
              <ColorPicker
                animation="slide-up"
                color={banner[FIELDS.SECOND_BUTTON_FONT_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const VideoSectionForm = Form.create({
  name: 'validate_hone_promo_banner',
})(VideoSection);
