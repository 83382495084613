import React, { useState, useEffect } from 'react';

import ColorPicker from 'rc-color-picker';
import { Button, Form, Input, Layout, Typography, Switch, message } from 'antd';
import _ from 'lodash';
import { hasFormErrors } from 'utils';
import { PageSpinner } from 'components/page-spinner';

import RataEduApi from 'api/v3/rata_edu';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const FIELDS = {
  TITLE: 'section_title',
  DESCRIPTION: 'section_content',
  BUTTON_DISPLAY: 'button_display',
  BUTTON_TEXT: 'button_text',
  BUTTON_LINK: 'button_link',
  BUTTON_BACKGROUND_COLOR: 'button_background_color',
  BUTTON_FONT_COLOR: 'button_font_color',
};

function RataEdu(props) {
  const [content, setContent] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const { form } = props;
  const { TextArea } = Input;

  const { getFieldDecorator, getFieldsError } = form;

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      const payload = {
        ...values,
        button_background_color: content.button_background_color,
        button_font_color: content.button_font_color,
      };

      if (!err) {
        try {
          setLoadingButton(true);

          await RataEduApi.update(payload);
          message.success('Data has been updated');
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
          await getRataEdu();
        }
      }
    });
  }

  async function getRataEdu() {
    setLoadingPage(true);
    try {
      const { data } = await RataEduApi.get();
      const content = _.pick(_.get(data, 'data'), Object.values(FIELDS));
      setContent(content);
    } catch (err) {
      const errMessage = _.get(err, 'response.data.message');
      message.error(errMessage || 'Cannot connect to the server');
    } finally {
      setLoadingPage(false);
    }
  }

  const changeColor = (value, key) => {
    if (value.color) {
      setContent((prevState) => ({
        ...prevState,
        [key]: value.color,
      }));
      return value.color;
    }
    return;
  };

  useEffect(() => {
    getRataEdu();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-home-rata-edu pd-cms-form-1">
      <Header>
        <Title>Rata Edu</Title>
      </Header>
      <Content>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              initialValue: content[FIELDS.TITLE],
            })(<Input placeholder="Specify the banner title" />)}
          </Form.Item>

          <Form.Item label="Description">
            {getFieldDecorator(FIELDS.DESCRIPTION, {
              initialValue: content[FIELDS.DESCRIPTION],
            })(<TextArea placeholder="Write the banner description" />)}
          </Form.Item>

          <Form.Item label="Show Button">
            {getFieldDecorator(FIELDS.BUTTON_DISPLAY, {
              valuePropName: 'checked',
              initialValue: content[FIELDS.BUTTON_DISPLAY] || false,
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Form.Item label="Button Text">
            {getFieldDecorator(FIELDS.BUTTON_TEXT, {
              rules: [{ required: true, message: 'Please input button text!' }],
              initialValue: content[FIELDS.BUTTON_TEXT],
            })(<Input placeholder="Input button text" />)}
          </Form.Item>

          <Form.Item label="Button URL">
            {getFieldDecorator(FIELDS.BUTTON_LINK, {
              rules: [{ required: true, message: 'Please input button url!' }],
              initialValue: content[FIELDS.BUTTON_LINK],
            })(<Input placeholder="Input button url" />)}
          </Form.Item>

          <Form.Item label="Button Color">
            {getFieldDecorator(FIELDS.BUTTON_BACKGROUND_COLOR, {
              getValueFromEvent: (e) =>
                changeColor(e, FIELDS.BUTTON_BACKGROUND_COLOR),
            })(
              <ColorPicker
                animation="slide-up"
                color={content[FIELDS.BUTTON_BACKGROUND_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item label="Button Font Color">
            {getFieldDecorator(FIELDS.BUTTON_FONT_COLOR, {
              getValueFromEvent: (e) =>
                changeColor(e, FIELDS.BUTTON_FONT_COLOR),
            })(
              <ColorPicker
                animation="slide-up"
                color={content[FIELDS.BUTTON_FONT_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const RataEduForm = Form.create({
  name: 'rata_edu_form',
})(RataEdu);
