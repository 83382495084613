import React, { useEffect, useState } from 'react';

import FloatingPromoBannerApi from 'api/v3/floating_promo_banner';

import {
  Button,
  Input,
  Form,
  Layout,
  Typography,
  Row,
  Switch,
  message,
} from 'antd';

import { PageSpinner } from 'components/page-spinner';

import { FIELDS } from './constant';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const FloatingPromoBanner = ({ form }) => {
  const [bannerContent, setBannerContent] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const { getFieldDecorator } = form;

  const getFloatingPromoBanners = async () => {
    try {
      setLoadingPage(true);
      const { data } = await FloatingPromoBannerApi.get();
      const response = data.data;

      setBannerContent(response);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);

          await FloatingPromoBannerApi.update(bannerContent.id, values);

          message.success('Berhasil mengubah data');
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  };

  useEffect(() => {
    getFloatingPromoBanners();

    return () => setBannerContent([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="rata-cms-promo-floating-banners pd-cms-form-1">
      <Header>
        <Title>Promo Floating Banners</Title>
      </Header>

      <Content>
        <Row>
          <Form
            onSubmit={(event) => handleSubmit(event)}
            className="pd-admin-create-form">
            <Form.Item label="Title">
              {getFieldDecorator(FIELDS.TITLE, {
                rules: [
                  { required: true, message: 'Please input title promo!' },
                ],
                initialValue: bannerContent[FIELDS.TITLE],
              })(<Input placeholder="Input title promo" />)}
            </Form.Item>

            <Form.Item label="Promo URL">
              {getFieldDecorator(FIELDS.URL, {
                rules: [{ required: true, message: 'Please input your url!' }],
                initialValue: bannerContent[FIELDS.URL],
              })(<Input placeholder="Input url promo" autoComplete="false" />)}

              <p>
                Contoh pengisian: /promotions/:nama-promo{' '}
                <span>(harus diawali &apos;/&apos; didepan)</span>
              </p>
            </Form.Item>

            <Form.Item label="Show Promo">
              {getFieldDecorator(FIELDS.IS_ACTIVE, {
                valuePropName: 'checked',
                initialValue: bannerContent[FIELDS.IS_ACTIVE] || false,
              })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
            </Form.Item>

            <Form.Item>
              <Button
                className="btn-full"
                type="primary"
                htmlType="submit"
                loading={loadingButton}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Content>
    </Layout>
  );
};

export const FloatingPromoBannerForm = Form.create({
  name: 'validate_floating_banner',
})(FloatingPromoBanner);

export default FloatingPromoBanner;
