import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import AuthenticationApi from 'api/authentication';

export const AuthenticationContext = createContext();

function AuthenticationContextProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [authenticatedUserProfile, setAuthenticatedUserProfile] = useState({
    name: '',
    email: '',
  });

  useEffect(() => {
    localStorage.getItem('cms_token') &&
      localStorage.getItem('current_admin') &&
      setAuthenticated(true);

    return () => {
      setAuthenticated(false);
    };
  }, []);

  async function login(payload) {
    const { data } = await AuthenticationApi.login(payload);
    const accessToken = _.get(data, ['data', 'token', 'access_token'], null);
    const userProfile = _.get(data, ['data', 'admin'], null);

    if (accessToken && userProfile) {
      localStorage.setItem('cms_token', accessToken);
      localStorage.setItem('current_admin', userProfile.email);

      setAuthenticated(true);
      setAuthenticatedUserProfile(userProfile);
    }

    window.location.reload();
  }

  return (
    <AuthenticationContext.Provider
      value={{
        authenticated,
        authenticatedUserProfile,
        login,
      }}>
      {children}
    </AuthenticationContext.Provider>
  );
}

AuthenticationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object,
};

export default AuthenticationContextProvider;
