import React, { useContext, useRef, useState } from 'react';
import { Input, Layout, Typography, Modal, Button, message } from 'antd';
import { PromotionContext } from 'contexts/promotion';
import { List } from './list';
import { alertErr } from 'utils';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

export const Promotions = () => {
  const { createPromotion, getPromotion } = useContext(PromotionContext || {});
  const inputContainer = useRef('');
  const [modalVisible, setModalVisible] = useState(false);
  const handlePromotions = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    inputContainer.current.state.value = '';
    setModalVisible(false);
  };

  const handleSubmit = async () => {
    try {
      let inputContainerValue = inputContainer.current.state.value;

      await createPromotion(inputContainerValue);
      message.success('Berhasil menambahkan promo');
      await getPromotion();

      hideModal();
    } catch (err) {
      alertErr(err);
    }
  };

  return (
    <Layout className="pd-cms-promotions">
      <Header>
        <Title>Manage Promotions</Title>
      </Header>

      <Content id="drag-content">
        <Button
          type="primary"
          style={{ fontSize: '18px' }}
          className="pd-margin-bottom-lg"
          onClick={handlePromotions}>
          Add New Promotion Page
        </Button>
        <List modalVisible={modalVisible} hideModal={hideModal} />
      </Content>

      <Modal
        title="Add New Promotion"
        visible={modalVisible}
        onOk={handleSubmit}
        centered="true"
        onCancel={hideModal}>
        <Input ref={inputContainer} placeholder="Input promotion title" />
      </Modal>
    </Layout>
  );
};
