import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Layout, Form, Typography, Button, message } from 'antd';

import TermsConditionApi from 'api/termCondition';

import { PageSpinner } from 'components/page-spinner';

import './style.scss';
import { get } from 'lodash';

const { Header, Content } = Layout;
const { Title } = Typography;

const FIELDS = {
  TERMS_AND_CONDITIONS_TERMS: 'terms_and_conditions_terms',
};

export function Terms({ form }) {
  const [pageContent, setPageContent] = useState({});
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const [formLayout] = useState();

  const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
        }
      : null;

  useEffect(() => {
    async function getPageContentData() {
      const { data } = await TermsConditionApi.showTermConditionPage('terms');
      const pageContentData = data.data;

      setPageContent(pageContentData);
    }

    async function getData() {
      try {
        await getPageContentData();
      } finally {
        setLoadingPage(false);
      }
    }

    getData();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);

          await TermsConditionApi.updateTermConditionPage({
            ...pageContent,
            ...values,
          });

          message.success('Berhasil mengubah data');
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  function changeValue(value, key) {
    if (value) {
      setPageContent((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>Terms and Conditions: Ketentuan Pengguna</Title>
      </Header>
      <Content>
        <Form layout={formLayout} onSubmit={(event) => handleSubmit(event)}>
          <Form.Item {...formItemLayout}>
            <ReactQuill
              modules={modules}
              formats={formats}
              defaultValue={get(
                pageContent,
                FIELDS.TERMS_AND_CONDITIONS_TERMS,
                ''
              )}
              onChange={(val) =>
                changeValue(val, FIELDS.TERMS_AND_CONDITIONS_TERMS)
              }
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const TermsForm = Form.create({
  name: 'validate_home_how_to_work',
})(Terms);

export default { Terms };
