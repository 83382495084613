import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { message } from 'antd';

import { BlogCreateEditForm } from 'components/blog/createEdit';

import BlogYoutubeApi from 'api/v2/blog/youtube';

const YoutubesCreateEdit = () => {
  let history = useHistory();
  const { id } = useParams();

  const [content, setContent] = useState({});

  const handleContent = (val, key) => {
    if (val) {
      setContent((prevState) => ({
        ...prevState,
        [key]: val,
      }));
    }
  };

  const createData = async (payload) => {
    await BlogYoutubeApi.create(payload);

    message.success(`Successfully created new Youtube Post`);

    history.push({
      pathname: '/cms/reviews-and-promos/youtubes',
    });
  };

  const updateData = async (payload) => {
    await BlogYoutubeApi.update(payload);

    message.success(`Successfully updated new Youtube Post`);

    history.push({
      pathname: '/cms/reviews-and-promos/youtubes',
    });
  };

  useEffect(() => {
    const fetchPromo = async () => {
      const { data } = await BlogYoutubeApi.getById(id);
      const promoDetail = data.data;

      setContent(promoDetail);
    };

    id && fetchPromo();
  }, [id]);

  return (
    <BlogCreateEditForm
      pageName="Youtube Post"
      handleCreate={createData}
      handleUpdate={updateData}
      content={content}
      handleContent={handleContent}
    />
  );
};

export default YoutubesCreateEdit;
