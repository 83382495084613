import api from './index';

export default {
  get(q = '', category = '', month = null, status = '', page = 1) {
    let url = '/articles';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    month && filters.push(`month=${month}`);
    status.trim() !== '' && filters.push(`status=${status}`);
    category.trim() !== '' && filters.push(`article_category=${category}`);
    page !== '' && filters.push(`page=${page}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  getById(id) {
    return api.get(`articles/${id}`);
  },
  post(payload) {
    return api.post(`articles`, { articles: payload });
  },
  update(payload, id) {
    return api.put(`articles/${id}`, { articles: payload });
  },
  updateMultiple(payload) {
    return api.put(`/articles`, { articles: payload });
  },
  delete(id) {
    return api.delete(`articles/${id}`);
  },
};
