const FIELDS = {
  SUB_TITLE: 'sub_title',
  CONTENT: 'content',
  DESKTOP_IMAGES_URL: 'desktop_images_url',
  MOBILE_IMAGES_URL: 'mobile_images_url',
  SECTION_NUMBER: 'section_number',
  IMAGE_BUTTON_IS_ACTIVE: 'image_button_is_active',
  IMAGE_BUTTON_TEXT: 'image_button_text',
  IMAGE_BUTTON_URL: 'image_button_url',
  IMAGE_BUTTON_BG_COLOR: 'image_button_bg_color',
  IMAGE_BUTTON_FONT_COLOR: 'image_button_font_color',
  TEXT_BUTTON_IS_ACTIVE: 'text_button_is_active',
  TEXT_BUTTON_TEXT: 'text_button_text',
  TEXT_BUTTON_URL: 'text_button_url',
  TEXT_BUTTON_BG_COLOR: 'text_button_bg_color',
  TEXT_BUTTON_FONT_COLOR: 'text_button_font_color',
};

export default FIELDS;
