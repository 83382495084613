import api from './index';

export default {
  get() {
    return api.get(`/general_pages`);
  },
  update(payload) {
    return api.put(`/general_pages `, { general_page: payload });
  },
};
