import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Layout, Switch, Typography, message } from 'antd';
import './style.scss';
import _ from 'lodash';
import { alertErr, hasFormErrors } from 'utils';
import { PageSpinner } from 'components/page-spinner';
import generalContent from 'api/v2/generalContent';

const { Header, Content } = Layout;
const { Title } = Typography;

const FIELDS = {
  WHATSAPP_MESSAGE: 'whatsapp_message',
  WHATSAPP_NUMBER: 'phone_number',
  WHATSAPP_ACTION_BUTTON: 'whatsapp_action_button',
  WHATSAPP_ACTION_BUTTON_IS_ACTIVE: 'whatsapp_action_button_is_active',
  HEADER_ACTION_BUTTON: 'header_action_button',
  HEADER_ACTION_BUTTON_IS_ACTIVE: 'header_action_button_is_active',
  HEADER_ACTION_LINK_URL: 'header_action_button_url',
  INTERMEZZO_ACTION_BUTTON: 'intermezzo_action_button',
  INTERMEZZO_ACTION_BUTTON_IS_ACTIVE: 'intermezzo_action_button_is_active',
  INTERMEZZO_LINK_URL: 'intermezzo_action_button_url',
};

function GeneralContent(props) {
  const [pageContent, setPageContent] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const { form } = props;

  const { getFieldDecorator, getFieldsError } = form;

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          // avoid overfetch and unwanted mutation
          const payload = _.pick(
            { ...pageContent, ...values },
            Object.values(FIELDS)
          );

          await generalContent.update(payload);
          message.success('Data has been updated');
        } catch (err) {
          alertErr(err);
        } finally {
          setLoadingButton(false);
          await getPageData();
        }
      }
    });
  }

  async function getPageData() {
    setLoadingPage(true);
    try {
      const res = await generalContent.get();
      const pageData = _.pick(_.get(res, 'data.data'), Object.values(FIELDS));
      setPageContent(pageData);
    } catch (err) {
      alertErr(err);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getPageData();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-general-content pd-cms-form-1">
      <Header>
        <Title>General Content</Title>
      </Header>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Show Whatsapp Button">
            {getFieldDecorator(FIELDS.WHATSAPP_ACTION_BUTTON_IS_ACTIVE, {
              valuePropName: 'checked',
              initialValue:
                pageContent[FIELDS.WHATSAPP_ACTION_BUTTON_IS_ACTIVE],
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Form.Item label="Whatsapp Number">
            {getFieldDecorator(FIELDS.WHATSAPP_NUMBER, {
              initialValue: pageContent[FIELDS.WHATSAPP_NUMBER],
            })(<Input placeholder="Insert whatsapp number" />)}
          </Form.Item>

          <Form.Item label="Whatsapp Message">
            {getFieldDecorator(FIELDS.WHATSAPP_MESSAGE, {
              initialValue: decodeURIComponent(
                pageContent[FIELDS.WHATSAPP_MESSAGE].replace(/([+])/g, ' ')
              ),
            })(<Input placeholder="Insert whatsapp message" />)}
          </Form.Item>

          <Form.Item label="Whatsapp Button Text">
            {getFieldDecorator(FIELDS.WHATSAPP_ACTION_BUTTON, {
              initialValue: pageContent[FIELDS.WHATSAPP_ACTION_BUTTON],
            })(<Input placeholder="Insert whatsapp action button" />)}
          </Form.Item>

          <Form.Item label="Show Header Button">
            {getFieldDecorator(FIELDS.HEADER_ACTION_BUTTON_IS_ACTIVE, {
              valuePropName: 'checked',
              initialValue: pageContent[FIELDS.HEADER_ACTION_BUTTON_IS_ACTIVE],
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Form.Item label="Header Button Text">
            {getFieldDecorator(FIELDS.HEADER_ACTION_BUTTON, {
              initialValue: pageContent[FIELDS.HEADER_ACTION_BUTTON],
            })(<Input placeholder="Insert header action button" />)}
          </Form.Item>

          <Form.Item label="Header Link Url">
            {getFieldDecorator(FIELDS.HEADER_ACTION_LINK_URL, {
              initialValue: pageContent[FIELDS.HEADER_ACTION_LINK_URL],
            })(<Input placeholder="Insert header link url" />)}
          </Form.Item>

          <Form.Item label="Show intermezzo Button">
            {getFieldDecorator(FIELDS.INTERMEZZO_ACTION_BUTTON_IS_ACTIVE, {
              valuePropName: 'checked',
              initialValue:
                pageContent[FIELDS.INTERMEZZO_ACTION_BUTTON_IS_ACTIVE],
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Form.Item label="Intermezzo Button Text">
            {getFieldDecorator(FIELDS.INTERMEZZO_ACTION_BUTTON, {
              initialValue: pageContent[FIELDS.INTERMEZZO_ACTION_BUTTON],
            })(<Input placeholder="Insert intermezzo action button" />)}
          </Form.Item>

          <Form.Item label="Intermezzo Link Url">
            {getFieldDecorator(FIELDS.INTERMEZZO_LINK_URL, {
              initialValue: pageContent[FIELDS.INTERMEZZO_LINK_URL],
            })(<Input placeholder="Insert intermezzo link url" />)}
          </Form.Item>

          <Form.Item>
            <Button
              className="pd-margin-top-lg pd-margin-bottom-md"
              block
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const GeneralContentForm = Form.create({
  name: 'general_content_page',
})(GeneralContent);
