export const modules = {
  clipboard: {
    matchVisual: false,
  },
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
};

export const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

export const FIELDS = {
  TITLE: 'title',
  ADDRESS: 'address',
  MEDIA_URL: 'media_url',
  MEDIA_ALT_TEXT: 'media_alt_text',
  ACTION_BUTTON_TEXT: 'action_button',
  ACTION_BUTTON_IS_ACTIVE: 'action_button_is_active',
  ACTION_BUTTON_URL: 'action_button_url',
  ACTION_BUTTON_COLOR: 'action_button_color',
};
