import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { message } from 'antd';

import { ArticleCreateEditForm } from 'components/article/createEdit';

import ArticleApi from 'api/v3/article';

const ArticleCreateEdit = () => {
  let history = useHistory();
  const { id } = useParams();

  const [content, setContent] = useState({});

  const fetchArticles = async () => {
    const { data } = await ArticleApi.getById(id);
    const content = data.data;

    setContent(content);
  };

  const handleContent = (val, key) => {
    if (val) {
      setContent((prevState) => ({
        ...prevState,
        [key]: val,
      }));
    }
  };

  const createData = async (payload) => {
    try {
      await ArticleApi.post(payload);

      message.success(`Successfully created new Article`);

      history.push({
        pathname: '/cms/articles',
      });
    } catch {
      //
    }
  };

  const updateData = async (payload, id) => {
    await ArticleApi.update(payload, id);

    message.success(`Successfully updated new Article`);

    history.push({
      pathname: '/cms/articles',
    });
  };

  useEffect(() => {
    id && fetchArticles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ArticleCreateEditForm
      handleCreate={createData}
      handleUpdate={updateData}
      content={content}
      handleContent={handleContent}
    />
  );
};

export default ArticleCreateEdit;
