import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Layout, Switch, Typography, message } from 'antd';
import ColorPicker from 'rc-color-picker';
import dentistAPI from 'api/v2/dentist';
import './style.scss';
import { PdUploader } from 'components/pd-uploader';
import _ from 'lodash';
import { alertErr, hasFormErrors } from 'utils';
import { PageSpinner } from 'components/page-spinner';
const { Header, Content } = Layout;
const { Title } = Typography;

const FIELDS = {
  TITLE: 'header_title',
  DESCRIPTION: 'header_description',
  DESKTOP_MEDIA_URL: 'header_desktop_media',
  MOBILE_MEDIA_URL: 'header_mobile_media',
  MEDIA_ALT_TEXT: 'header_media_alt_text',
  ACTION_BUTTON: 'header_action_button',
  ACTION_BUTTON_IS_ACTIVE: 'header_action_button_is_active',
  LINK_URL: 'header_button_url',
  BUTTON_BACKGROUND_COLOR: 'header_button_background_color',
  BUTTON_FONT_COLOR: 'header_button_font_color',
};

function UntukDokter(props) {
  const [pageData, setPageData] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const { form } = props;
  const { TextArea } = Input;

  const { getFieldDecorator, getFieldsError } = form;

  function changeColor(value, key) {
    if (value.color) {
      setPageData((prevState) => ({
        ...prevState,
        [key]: value.color,
      }));
      return value.color;
    }
    return;
  }
  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          // avoid overfetch and unwanted mutation
          const payload = _.pick(
            { ...pageData, ...values },
            Object.values(FIELDS)
          );
          await dentistAPI.updatePageData(payload);
          message.success('Data has been updated');
        } catch (err) {
          alertErr(err);
        } finally {
          setLoadingButton(false);
          getPageData();
        }
      }
    });
  }

  async function getPageData() {
    setLoadingPage(true);
    try {
      const res = await dentistAPI.getPageData();
      const pageData = _.get(res, 'data.data');
      setPageData(pageData);
    } catch (err) {
      alertErr(err);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getPageData();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-untuk-dokter pd-cms-form-1">
      <Header>
        <Title>Untuk Dokter</Title>
      </Header>
      <Content>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item label="Desktop Banner (1440x574 px)">
            <PdUploader
              purpose="banner"
              afterUpload={(url) => {
                setPageData((prevState) => ({
                  ...prevState,
                  [FIELDS.DESKTOP_MEDIA_URL]: url,
                }));
              }}
              imgUrl={pageData[FIELDS.DESKTOP_MEDIA_URL]}
            />
          </Form.Item>

          <Form.Item label="Mobile Banner (640x1140 px)">
            <PdUploader
              purpose="banner"
              afterUpload={(url) => {
                setPageData((prevState) => ({
                  ...prevState,
                  [FIELDS.MOBILE_MEDIA_URL]: url,
                }));
              }}
              imgUrl={pageData[FIELDS.MOBILE_MEDIA_URL]}
            />
          </Form.Item>

          <Form.Item label="Banner Alt Text">
            {getFieldDecorator(FIELDS.MEDIA_ALT_TEXT, {
              initialValue: pageData[FIELDS.MEDIA_ALT_TEXT],
            })(<Input placeholder="Enter alt text for the image" />)}
          </Form.Item>

          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              initialValue: pageData[FIELDS.TITLE],
            })(<Input placeholder="Specify the banner title" />)}
          </Form.Item>

          <Form.Item label="Description">
            {getFieldDecorator(FIELDS.DESCRIPTION, {
              initialValue: pageData[FIELDS.DESCRIPTION],
            })(<TextArea placeholder="Write the banner description" />)}
          </Form.Item>

          <Form.Item label="Show Action Button">
            {getFieldDecorator(FIELDS.ACTION_BUTTON_IS_ACTIVE, {
              valuePropName: 'checked',
              initialValue: pageData[FIELDS.ACTION_BUTTON_IS_ACTIVE],
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Form.Item label="Action Button">
            {getFieldDecorator(FIELDS.ACTION_BUTTON, {
              initialValue: pageData[FIELDS.ACTION_BUTTON],
            })(<Input placeholder="Specify the button text" />)}
          </Form.Item>

          <Form.Item label="Button Link Url">
            {getFieldDecorator(FIELDS.LINK_URL, {
              initialValue: pageData[FIELDS.LINK_URL],
            })(<Input placeholder="Specify the link url" />)}
          </Form.Item>

          <Form.Item label="Button Color">
            {getFieldDecorator(FIELDS.BUTTON_BACKGROUND_COLOR, {
              initialValue: pageData[FIELDS.BUTTON_BACKGROUND_COLOR],
              getValueFromEvent: (e) =>
                changeColor(e, FIELDS.BUTTON_BACKGROUND_COLOR),
            })(
              <ColorPicker
                animation="slide-up"
                color={pageData[FIELDS.BUTTON_BACKGROUND_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item label="Button Font Color">
            {getFieldDecorator(FIELDS.BUTTON_FONT_COLOR, {
              initialValue: pageData[FIELDS.BUTTON_FONT_COLOR],
              getValueFromEvent: (e) =>
                changeColor(e, FIELDS.BUTTON_FONT_COLOR),
            })(
              <ColorPicker
                animation="slide-up"
                color={pageData[FIELDS.BUTTON_FONT_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const UntukDokterForm = Form.create({
  name: 'untuk_dokter_banner',
})(UntukDokter);
