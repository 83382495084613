import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Layout, Typography, message } from 'antd';
import './style.scss';
import { PdUploader } from 'components/pd-uploader';
import _ from 'lodash';
import { alertErr, hasFormErrors } from 'utils';
import { PageSpinner } from 'components/page-spinner';
import buyingStepAPI from 'api/v2/buyingStep';
const { Header, Content } = Layout;
const { Title } = Typography;

const FIELDS = {
  ID: 'id',
  TITLE: 'title',
  MEDIA_URL: 'media_url',
  MEDIA_ALT_TEXT: 'media_alt_text',
};

function CaraKonsultasi({ history, form }) {
  const [banner, setBanner] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const { getFieldDecorator, getFieldsError } = form;

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          // only include necessary key-values
          const payload = _.pick(
            { ...banner, ...values },
            Object.values(FIELDS)
          );
          if (id) {
            await buyingStepAPI.updateBuyingStepDetail(id, payload);
            message.success('Data has been updated');
          } else {
            await buyingStepAPI.createBuyingStep({
              ...payload,
              order_level: 0,
            });
            message.success('Data has been created');
          }

          history.push('/cms/cara-konsultasi/konsultasi');
        } catch (err) {
          alertErr(err);
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  useEffect(() => {
    async function getBanner() {
      setLoadingPage(true);
      try {
        const res = await buyingStepAPI.getBuyingStep(id);
        const banner = _.pick(_.get(res, 'data.data'), Object.values(FIELDS));
        setBanner(banner);
      } catch (err) {
        alertErr(err);
      } finally {
        setLoadingPage(false);
      }
    }
    if (id) {
      getBanner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-home-promo-banner pd-cms-form-1">
      <Header>
        <Title>{id ? 'Edit' : 'Add New'} Cara Konsultasi</Title>
      </Header>
      <Content>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              initialValue: banner[FIELDS.TITLE],
            })(<Input placeholder="Specify the title" />)}
          </Form.Item>

          <Form.Item label="Picture (724x526 px)">
            <PdUploader
              purpose="banner"
              afterUpload={(url) => {
                setBanner((prevState) => ({
                  ...prevState,
                  [FIELDS.MEDIA_URL]: url,
                }));
              }}
              imgUrl={banner[FIELDS.MEDIA_URL]}
            />
          </Form.Item>

          <Form.Item label="Picutre Alt Text">
            {getFieldDecorator(FIELDS.MEDIA_ALT_TEXT, {
              initialValue: banner[FIELDS.MEDIA_ALT_TEXT],
            })(<Input placeholder="Enter alt text for the image" />)}
          </Form.Item>

          <Form.Item>
            <Button
              className="pd-margin-top-xl"
              block
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const CaraKonsultasiForm = Form.create({
  name: 'cara_konsultasi',
})(CaraKonsultasi);
