import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Button,
  Col,
  Icon,
  Row,
  Layout,
  Table,
  Typography,
  message,
} from 'antd';

import moment from 'moment';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import UsersApi from 'api/v3/user';

import { PageSpinner } from 'components/page-spinner';

const { Header, Content } = Layout;
const { Title } = Typography;

const UserCampaigns = () => {
  const { campaign_id } = useParams();

  const [campaigns, setCampaigns] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [columns, setColumns] = useState([]);

  const COLUMN_TEMPLATE_A = [
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Domisili',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Telp',
      dataIndex: 'phone',
      key: 'phone',
    },
  ];

  const COLUMN_TEMPLATE_B = [
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ];

  const manipulateDataWithTableProps = (data) => {
    data.forEach((datum, index) => {
      datum.number = index + 1;

      datum['Data Created'] = moment(datum.created_at).format('DD/MMMM/YYYY');
      datum['Time Created'] = moment(datum.created_at).format('hh:mm:ss');
    });

    setCampaigns(data);
  };

  const renameKeys = (obj, newKeys) => {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });

    return Object.assign({}, ...keyValues);
  };

  const exportToCSV = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const newKeys = {
      email: 'Email',
      phone: 'Telp',
      location: 'Domisili',
    };

    const newAnswerKey = campaigns.map((answer) => {
      delete answer.id;
      delete answer.number;
      delete answer.created_at;
      delete answer.updated_at;

      return renameKeys(answer, newKeys);
    });

    const ws = XLSX.utils.json_to_sheet(newAnswerKey);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, 'rata_campaign' + fileExtension);
  };

  useEffect(() => {
    const getUsersCampaign = async (campaign_id) => {
      try {
        setLoadingPage(true);

        const { data } = await UsersApi.getByCampaign(campaign_id);
        const users = data.data;

        manipulateDataWithTableProps(users);
      } catch {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      } finally {
        setLoadingPage(false);
      }
    };

    if (campaign_id === '2') {
      setColumns(COLUMN_TEMPLATE_B);
    } else {
      setColumns(COLUMN_TEMPLATE_A);
    }

    getUsersCampaign(campaign_id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="rata-cms-users-campaign pd-cms-form-1">
      <Header>
        <Title> Campaign</Title>
      </Header>

      <Content>
        <Row>
          <Col xs={24}>
            <Button
              type="primary"
              style={{ marginBottom: 20 }}
              onClick={exportToCSV}>
              <Icon type="download" />
              Download Report
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table dataSource={campaigns} columns={columns} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default UserCampaigns;
