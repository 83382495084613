import api from './index';

export default {
  createTestimony(payload) {
    return api.post(`/testimonies`, { testimonies: payload });
  },
  listTestimonies() {
    return api.get(`/testimonies`);
  },
  getTestimony(id) {
    return api.get(`/testimonies/${id}`);
  },
  updateTestimonyDetail(id, payload) {
    return api.put(`/testimonies/${id}`, payload);
  },
  updateTestimonyOrder(payload) {
    return api.put(`/testimonies`, { testimonies: payload });
  },
  deleteTestimonyDetail(id) {
    return api.delete(`/testimonies/${id}`);
  },
};
