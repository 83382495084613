import api from './index';

export default {
  get() {
    return api.get(`/our-offers`);
  },

  getBy(id) {
    return api.get(`/our-offers/${id}`);
  },

  create(payload) {
    return api.post(`/our-offers`, { our_offers: payload });
  },

  update(id, payload) {
    return api.put(`/our-offers/${id}`, { our_offers: payload });
  },

  updateStatus(id, payload) {
    return api.put(`/our-offers/${id}/status`, { our_offers: payload });
  },

  bulkUpdate(payload) {
    return api.put(`/our-offers/bulk`, { our_offers: payload });
  },

  delete(id) {
    return api.delete(`/our-offers/${id}`);
  },
};
