import React, { useEffect, useState } from 'react';
import { Form, Layout, Input, Typography, Button, message } from 'antd';

import HomePageApi from 'api/v2/homePage';
import { PageSpinner } from 'components/page-spinner';
import _ from 'lodash';

import './style.scss';
import { PdTags } from 'components/pd-tags';
import { hasFormErrors } from 'utils';

const { Header, Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;

const FIELDS = {
  COMPARISON_DESCRIPTION: 'comparison_description',
  POINTS: 'comparison_points',
  TITLE: 'comparison_title',
};

export function Comparison({ form }) {
  const [comparison, setComparison] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const { getFieldDecorator, getFieldsError } = form;

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);

          // avoid overfetch by including keys in fields
          const payload = _.pick(
            { ...comparison, ...values },
            Object.values(FIELDS)
          );

          await HomePageApi.updateHomePage(payload);

          message.success('Data has been updated');
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(
            errMessage || 'Cannot connect to the server'
          );
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  useEffect(() => {
    async function getComparisonContent() {
      try {
        const res2 = await HomePageApi.showHomePage('comparison');
        const comparisonContent = res2.data.data;

        setComparison(comparisonContent);
      } finally {
        setLoadingPage(false);
      }
    }

    getComparisonContent();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-home-comparison pd-cms-form-1">
      <Header>
        <Title>Perbandingan</Title>
      </Header>
      <Content>
        <Form onSubmit={handleSubmit} className="form">
          <div className="items">
            <Form.Item label="Section Title">
              {getFieldDecorator(FIELDS.TITLE, {
                rules: [{ required: true, message: 'Please input Title!' }],
                initialValue: comparison[FIELDS.TITLE],
              })(<Input placeholder="Write your name here" />)}
            </Form.Item>

            <Form.Item label="Description">
              {getFieldDecorator(FIELDS.COMPARISON_DESCRIPTION, {
                rules: [
                  { required: true, message: 'Please input description' },
                ],
                initialValue: _.get(
                  comparison,
                  FIELDS.COMPARISON_DESCRIPTION,
                  ''
                ),
              })(<TextArea placeholder="Write your description here" />)}
            </Form.Item>

            <Form.Item label="Points">
              <PdTags
                tags={_.get(comparison, FIELDS.POINTS, [])}
                onChange={(points) => {
                  setComparison((prev) => ({
                    ...prev,
                    [FIELDS.POINTS]: points,
                  }));
                }}
              />
            </Form.Item>
          </div>

          <Button
            block
            className="button"
            type="primary"
            htmlType="submit"
            loading={loadingButton}
            disabled={hasFormErrors(getFieldsError())}>
            Publish
          </Button>
        </Form>
      </Content>
    </Layout>
  );
}

export const ComparisonContentForm = Form.create({
  name: 'validate_testimonials_edit',
})(Comparison);

export default { Comparison };
