import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import BlogPostApi from 'api/v2/blog/post';

import { Col, Icon, Layout, Table, Typography, Row } from 'antd';

import moment from 'moment';

import { PageSpinner } from 'components/page-spinner';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const StaticPosts = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);

  const manipulateDataWithTableProps = (data) => {
    data.forEach((datum) => {
      datum.key = datum.id;

      datum.is_published = datum.is_active ? 'Published' : 'Unpublished';

      datum.date = moment(datum.created_at).format('DD MMMM YYYY');

      datum.action = (
        <div className="action-icon">
          <div className="edit">
            <Link to={`/cms/reviews-and-promos/static-posts/edit/${datum.id}`}>
              <Icon type="edit" />
            </Link>
          </div>
        </div>
      );
    });

    setBlogPosts(data);
  };

  const columns = [
    {
      title: 'Post Title',
      dataIndex: 'title',
      sorter: (a, b) => a.title.length - b.title.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Publish Date',
      dataIndex: 'date',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: 'Status',
      dataIndex: 'is_published',
      key: 'is_published',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  useEffect(() => {
    const getResults = async () => {
      try {
        setLoadingPage(true);
        const { data } = await BlogPostApi.get();
        const response = data.data;

        manipulateDataWithTableProps(response);
      } finally {
        setLoadingPage(false);
      }
    };

    getResults();

    return () => setBlogPosts([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="rata-cms-review-and-promo-static-posts pd-cms-form-1">
      <Header>
        <Title>All Static Post</Title>
      </Header>

      <Content>
        <Row>
          <Col xs={24}>
            <Table dataSource={blogPosts} columns={columns} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default StaticPosts;
