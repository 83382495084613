import React, { useContext, useState, useEffect } from 'react';
import { Menu, Layout, Typography } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import AuthenticationApi from 'api/authentication';

import { PromotionContext } from 'contexts/promotion';

import './style.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Title } = Typography;

function renderMenu(opt) {
  const { name = '', link = '', type = 'Menu', children = [] } = opt;

  if (type === 'Menu') {
    return (
      <Menu.Item key={link}>
        <Link to={link}>{name}</Link>
      </Menu.Item>
    );
  } else {
    return (
      <SubMenu key={name} title={<span>{name}</span>}>
        {children.map((opt) => renderMenu(opt, `${name}-`))}
      </SubMenu>
    );
  }
}

export const Sidebar = withRouter(({ location }) => {
  const [menuOptions, setMenuOptions] = useState([
    {
      type: 'SubMenu',
      name: 'Home',
      children: [
        // { type: 'Menu', name: 'Banners', link: '/cms/home/banners' },
        // { type: 'Menu', name: 'Doctors', link: '/cms/home/doctors' },
        // { type: 'Menu', name: 'Harga', link: '/cms/home/pricing' },
        // { type: 'Menu', name: 'Prosedur', link: '/cms/home/prosedur' },
        // {
        //   type: 'Menu',
        //   name: 'Perbandingan',
        //   link: '/cms/home/comparison',
        // },
        { type: 'Menu', name: 'Our Offers', link: '/cms/home/our-offers' },
        { type: 'Menu', name: 'Testimonies', link: '/cms/home/testimonials' },
        { type: 'Menu', name: 'Rata Edu', link: '/cms/home/rata-edu' },
        // {
        //   type: 'Menu',
        //   name: 'Lokasi',
        //   link: '/cms/home/location',
        // },
      ],
    },

    {
      type: 'SubMenu',
      name: 'Apa Itu Rata',
      children: [
        { type: 'Menu', name: 'Banner', link: '/cms/apa-itu-rata/banner' },
        {
          type: 'Menu',
          name: 'Video Section',
          link: '/cms/apa-itu-rata/video',
        },
        { type: 'Menu', name: 'FAQ', link: '/cms/apa-itu-rata/faq' },
      ],
    },
    {
      type: 'SubMenu',
      name: 'Cara Konsultasi',
      children: [
        { type: 'Menu', name: 'Banner', link: '/cms/cara-konsultasi/banner' },
        {
          type: 'Menu',
          name: 'Cara Konsultasi',
          link: '/cms/cara-konsultasi/konsultasi',
        },
      ],
    },
    {
      type: 'SubMenu',
      name: 'Newsletter Campaigns',
      children: [
        {
          type: 'Menu',
          name: 'Footer',
          link: '/cms/users/campaigns/2',
        },
        {
          type: 'Menu',
          name: 'Promotion',
          link: '/cms/users/campaigns/3',
        },
      ],
    },
    {
      type: 'SubMenu',
      name: 'Quiz',
      children: [
        { type: 'Menu', name: 'Landing', link: '/cms/quiz/edit' },
        {
          type: 'Menu',
          name: 'Result A',
          link: '/cms/quiz/result-a',
        },
        {
          type: 'Menu',
          name: 'Old Quiz Report',
          link: '/cms/quiz/reports',
        },
        {
          type: 'Menu',
          name: 'New Quiz Report',
          link: '/cms/quiz/reports/new',
        },
      ],
    },
    {
      type: 'SubMenu',
      name: 'Clinic Rata',
      children: [
        {
          type: 'Menu',
          name: 'Clinic Rata',
          link: '/cms/clinics',
        },
        {
          type: 'Menu',
          name: 'SEO Desc',
          link: '/cms/clinics/seo',
        },
      ],
    },
    {
      type: 'Menu',
      name: 'Protokol Covid-19',
      link: '/cms/protokol-covid',
    },
    {
      type: 'Menu',
      name: 'Untuk Dokter',
      link: '/cms/untuk-dokter',
    },
    {
      type: 'SubMenu',
      name: 'Promotions',
      children: [
        {
          type: 'Menu',
          name: 'Manage',
          link: '/cms/promotions',
        },
      ],
    },
    {
      type: 'Menu',
      name: 'Article',
      link: '/cms/articles',
    },
    {
      type: 'SubMenu',
      name: 'Terms and Conditions',
      children: [
        {
          type: 'Menu',
          name: 'Ketentuan Penggunaan',
          link: '/cms/tnc/ketentuan-penggunaan',
        },
        {
          type: 'Menu',
          name: 'Kebijakan Privasi',
          link: '/cms/tnc/kebijakan-privasi',
        },
      ],
    },
    {
      type: 'Menu',
      name: 'Floating Promo Banner',
      link: '/cms/floating-promo-banners',
    },
    {
      type: 'Menu',
      name: 'General Content',
      link: '/cms/general-content',
    },
    {
      type: 'Menu',
      name: 'Generator',
      link: '/cms/generator',
    },
    {
      type: 'SubMenu',
      name: 'Administrator',
      children: [
        { type: 'Menu', name: 'Admins', link: '/cms/admin' },
        {
          type: 'Menu',
          name: 'Change My Password',
          link: '/cms/admin/edit/change-password',
        },
      ],
    },
  ]);
  const { promotionMenu } = useContext(PromotionContext || {});
  const currentPath = _.get(location, 'pathname');

  useEffect(() => {
    const newMenu = menuOptions.map((item) => {
      if (item.name === 'Promotions') {
        item.children = [
          {
            type: 'Menu',
            name: 'Manage',
            link: '/cms/promotions',
          },
          ...promotionMenu,
        ];

        return item;
      } else {
        return item;
      }
    });

    setMenuOptions(newMenu);

    return () => setMenuOptions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionMenu]);

  async function handleLogout() {
    const cms_token = localStorage.getItem('cms_token');

    if (cms_token) {
      AuthenticationApi.logout(cms_token);
      localStorage.removeItem('cms_token');
      localStorage.removeItem('current_admin');
    }
  }

  return (
    <Sider width="300" className="pd-cms-sidebar">
      <div className="wrapper">
        <div className="menu">
          <Title level={3}>
            <Link to={'/'}>
              <div className="logo-container">
                <img
                  src={`${process.env.PUBLIC_URL}/img/logo/logo-red.png`}
                  alt="Rata"
                />
              </div>
            </Link>
          </Title>
          <Menu
            mode="inline"
            defaultSelectedKeys={[currentPath]}
            defaultOpenKeys={[
              _.get(
                menuOptions.find(
                  (menu) =>
                    menu.type === 'SubMenu' &&
                    _.get(menu, 'children', []).find(
                      (child) => child.link === currentPath
                    )
                ),
                'name'
              ),
            ]}>
            {menuOptions.map((opt) => renderMenu(opt))}
          </Menu>
        </div>
        <div className="user-badge">
          <Title level={4} onClick={() => handleLogout()}>
            <Link to={'/'}>Logout</Link>
          </Title>
        </div>
      </div>
    </Sider>
  );
});
