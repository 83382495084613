import api from './index';

export default {
  get(current_page = 1) {
    return api.get(`/clinics?page=${current_page}&size=10`);
  },

  getById(id) {
    return api.get(`/clinics/${id}`);
  },

  getLocation() {
    return api.get(`/clinics/locations`);
  },

  getHeadlines() {
    return api.get(`/clinics/headlines`);
  },

  create(request) {
    return api.post(`/clinics`, { request });
  },

  update(request, id) {
    return api.put(`/clinics/${id}`, { request });
  },

  updateHeadline(request, id) {
    return api.put(`/clinics/headlines/${id}`, { request });
  },

  updateStatus(request, id) {
    return api.patch(`/clinics/${id}`, { request });
  },

  delete(id) {
    return api.delete(`/clinics/${id}`);
  },
};
