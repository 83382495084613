import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ArticleApi from 'api/v3/article';
import moment from 'moment';

import { alertErr } from 'utils';

import MONTHS from 'constants/index';

import SelectedMessage from 'components/blog/selected-message';

import {
  Button,
  Col,
  Icon,
  Input,
  Layout,
  Pagination,
  Popconfirm,
  Table,
  Typography,
  Row,
  Select,
  Switch,
  message,
} from 'antd';

import { PageSpinner } from 'components/page-spinner';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const Promos = () => {
  const [promos, setPromos] = useState([]);
  const [pages, setPages] = useState({
    total_data: 0,
    current_page: 1,
    next_page: 0,
    prev_page: 0,
    total_pages: 1,
  });
  const [search, setSearch] = useState('');
  const [monthFilter, setMonthFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const CATEGORIES = [
    {
      id: 0,
      key: '',
      name: 'All Category',
    },
    {
      id: 1,
      key: 'aligner-information',
      name: 'Aligner Information',
    },
    {
      id: 2,
      key: 'aligner-user-guide',
      name: 'Aligner User Guide',
    },
    {
      id: 3,
      key: 'real-stories',
      name: 'Real Stories',
    },
    {
      id: 3,
      key: 'dental-health',
      name: 'Dental Health',
    },
    {
      id: 4,
      key: 'our-community',
      name: 'Our Community',
    },
    {
      id: 5,
      key: 'updates',
      name: 'Updates',
    },
    {
      id: 6,
      key: 'our-stories',
      name: 'Our Stories',
    },
  ];

  const getArticles = async () => {
    try {
      setLoadingPage(true);
      const { data } = await ArticleApi.get();
      const response = data.data;

      const total_data = data.total_data;
      const current_page = data.current_page;
      const next_page = data.next_page;
      const prev_page = data.prev_page;
      const total_pages = data.total_pages;

      setPages({
        total_data,
        current_page,
        next_page,
        prev_page,
        total_pages,
      });

      manipulateDataWithTableProps(response);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleChange = async (key, data, datum, checkedValue) => {
    try {
      const payload = {
        ...datum,
        [key]: !datum[checkedValue],
      };
      await ArticleApi.update(payload, datum.id);

      const temp = data.slice();

      const updatedArticles = temp.map((article) => {
        if (article.id === datum.id) {
          article[key] = !article[key];
        }

        return article;
      });

      manipulateDataWithTableProps(updatedArticles);
      message.success('Berhasil mengubah artikel');
    } catch (err) {
      alertErr(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      await ArticleApi.delete(id);

      setPromos((prevState) => prevState.filter((item) => item.id !== id));
      message.success('Berhasil menghapus artikel');
    } catch (err) {
      alertErr(err);
    }
  };

  const handleMultipleDelete = async () => {
    const payload = selectedRows.map((row) => {
      row.is_deleted = true;

      return row;
    });

    try {
      await ArticleApi.updateMultiple(payload);

      getArticles();
      setSelectedRows([]);
      message.success('Berhasil menghapus artikel');
    } catch (err) {
      alertErr(err);
    }
  };

  const manipulateDataWithTableProps = (data) => {
    data.forEach((datum) => {
      datum.key = datum.id;

      datum.is_popular_action = (
        <Switch
          checked={datum.is_popular}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={() => handleChange('is_popular', data, datum, 'is_popular')}
        />
      );

      datum.is_highlight_action = (
        <Switch
          checked={datum.is_priority}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={() =>
            handleChange('is_priority', data, datum, 'is_priority')
          }
        />
      );

      datum.is_active_action = (
        <Switch
          checked={datum.is_active}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={() => handleChange('is_active', data, datum, 'is_active')}
        />
      );

      datum.date = moment(datum.updated_at).format('DD MMMM YYYY');

      datum.action = (
        <div className="action-icon">
          <div className="edit">
            <Link to={`/cms/articles/edit/${datum.id}`}>
              <Icon type="edit" />
            </Link>
          </div>

          <div className="delete">
            <Popconfirm
              title="Are you sure？"
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
              onConfirm={() => handleDelete(datum.id)}>
              <Icon type="delete" />
            </Popconfirm>
          </div>
        </div>
      );
    });

    setPromos(data);
  };

  useEffect(() => {
    getArticles();

    return () => setPromos([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatingTag = (value) => {
    if (!value) return '';
    return value
      .replaceAll('-', ' ')
      .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  };

  const columns = [
    {
      title: 'Promo Title',
      dataIndex: 'title',
      sorter: (a, b) => a.title.length - b.title.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Category',
      dataIndex: 'article_category',
      sorter: (a, b) => a.article_category.length - b.article_category.length,
      sortDirections: ['descend', 'ascend'],
      render: (article_category) => <div>{formatingTag(article_category)}</div>,
    },
    {
      title: 'Publish Date',
      dataIndex: 'date',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: 'Publish',
      dataIndex: 'is_active_action',
      key: 'is_active_action',
    },
    {
      title: 'Popular Post',
      dataIndex: 'is_popular_action',
      key: 'is_popular_action',
    },
    {
      title: 'Show in Article Page',
      dataIndex: 'is_highlight_action',
      key: 'is_highlight_action',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const { Search } = Input;
  const { Option } = Select;

  const handleChangeMonth = (value) => setMonthFilter(value);
  const handleChangeStatus = (value) => setStatusFilter(value);
  const handleChangeCategory = (value) => setCategoryFilter(value);

  const handleSearch = async (value) => {
    const { data } = await ArticleApi.get(
      value,
      categoryFilter,
      monthFilter,
      statusFilter,
      pages.current_page
    );
    const response = data.data;

    const total_data = data.total_data;
    const current_page = data.current_page;
    const next_page = data.next_page;
    const prev_page = data.prev_page;
    const total_pages = data.total_pages;

    setPages({
      total_data,
      current_page,
      next_page,
      prev_page,
      total_pages,
    });

    setSearch(value);
    manipulateDataWithTableProps(response);
  };

  const handleFilter = async () => {
    const { data } = await ArticleApi.get(
      search,
      categoryFilter,
      monthFilter,
      statusFilter
    );

    const response = data.data;
    const total_data = data.total_data;
    const current_page = data.current_page;
    const next_page = data.next_page;
    const prev_page = data.prev_page;
    const total_pages = data.total_pages;

    setPages({
      total_data,
      current_page,
      next_page,
      prev_page,
      total_pages,
    });

    manipulateDataWithTableProps(response);
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (key, value) => {
      setSelectedRows(value);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  const handleChangePage = async (value) => {
    pages.current_page = value;
    setPages({ ...pages });

    const { data } = await ArticleApi.get(
      search,
      categoryFilter,
      monthFilter,
      statusFilter,
      pages.current_page
    );

    const response = data.data;
    manipulateDataWithTableProps(response);
  };

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="rata-cms-review-and-promo-promos pd-cms-form-1">
      <Header>
        <Title>Articles</Title>
      </Header>

      <Content>
        <Row>
          <Col xs={24}>
            <Link to="/cms/articles/create">
              <Button type="primary">Create New Article</Button>
            </Link>
          </Col>
        </Row>
        <Row gutter={[24, 12]}>
          <Col xs={12}>
            <div className="label">Search by</div>
            <Search onSearch={(value) => handleSearch(value)} />
          </Col>
          <Col xs={12}>
            <div className="label">Filter by</div>

            <Row gutter={[12, 0]}>
              <Col xs={8}>
                <Select
                  defaultValue="All Category"
                  onChange={handleChangeCategory}
                  style={{ width: '100%' }}>
                  {CATEGORIES.map((category, id) => (
                    <Option key={id} value={category.key}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col xs={8}>
                <Select
                  defaultValue="All Month"
                  onChange={handleChangeMonth}
                  style={{ width: '100%' }}>
                  {MONTHS.map((month, id) => (
                    <Option key={id} value={month.number}>
                      {month.name}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col xs={8}>
                <Select
                  defaultValue="All Status"
                  onChange={handleChangeStatus}
                  style={{ width: '100%' }}>
                  <Option value="">All Status</Option>
                  <Option value="true">Active</Option>
                  <Option value="false">Unactive</Option>
                </Select>
              </Col>
            </Row>
          </Col>

          <Col xs={{ span: 8, offset: 16 }}>
            <Button
              onClick={handleFilter}
              type="primary"
              ghost
              style={{ width: '100%' }}>
              Apply
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table
              rowSelection={rowSelection}
              dataSource={promos}
              columns={columns}
              pagination={false}
            />

            <div className="pagination">
              <Pagination
                current={pages.current_page}
                onChange={handleChangePage}
                total={pages.total_data}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <SelectedMessage
              handleMultipleDelete={handleMultipleDelete}
              rows={selectedRows.length}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Promos;
