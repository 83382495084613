import React, { createContext, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import PromotionApi from 'api/v2/promotion';

import { AuthenticationContext } from 'contexts/authentication';

export const PromotionContext = createContext();

function PromotionContextProvider({ children }) {
  const [promotions, setPromotions] = useState([]);
  const [promotionMenu, setPromotionMenu] = useState([]);
  const { authenticated } = useContext(AuthenticationContext || {});

  const mapPromotionsToMenuItem = (items) => {
    const menuItem = items.map((item) => ({
      type: 'Menu',
      name: item.title,
      link: `/cms/promotions/${item.id}`,
    }));
    setPromotionMenu(menuItem);
  };

  async function getPromotion() {
    const { data } = await PromotionApi.get();
    const PROMOTIONS = data.data;
    const ACTIVE_PROMOTIONS = PROMOTIONS.filter((item) => item.is_active);

    mapPromotionsToMenuItem(ACTIVE_PROMOTIONS);
    setPromotions(PROMOTIONS);
  }

  const SET_PROMOTIONS = (promotions) => {
    setPromotions(promotions);
  };

  const createPromotion = async (title) => {
    await PromotionApi.create({ title });
  };

  const duplicatePromotion = async (id) => {
    await PromotionApi.duplicate(id);
  };

  const updatePromotion = async (id, payload) => {
    await PromotionApi.update(id, payload);

    const updatedPromotions = promotions.map((promotion) => {
      if (promotion.id === id) {
        promotion.is_active = !promotion.is_active;
        return promotion;
      } else {
        return promotion;
      }
    });
    const ACTIVE_PROMOTIONS = updatedPromotions.filter(
      (item) => item.is_active
    );

    setPromotions(updatedPromotions);
    mapPromotionsToMenuItem(ACTIVE_PROMOTIONS);
  };

  const deletePromotion = async (id, payload) => {
    await PromotionApi.delete(id, payload);

    const filteredPromotions = promotions.filter(
      (promotion) => promotion.id !== id
    );
    const ACTIVE_PROMOTIONS = filteredPromotions.filter(
      (item) => item.is_active
    );

    setPromotions(filteredPromotions);
    mapPromotionsToMenuItem(ACTIVE_PROMOTIONS);
  };

  useEffect(() => {
    authenticated && getPromotion();

    return () => setPromotions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  return (
    <PromotionContext.Provider
      value={{
        promotions,
        promotionMenu,
        getPromotion,
        SET_PROMOTIONS,
        createPromotion,
        updatePromotion,
        deletePromotion,
        duplicatePromotion,
      }}>
      {children}
    </PromotionContext.Provider>
  );
}

PromotionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PromotionContextProvider;
