// from: https://3x.ant.design/components/tag/#header
// todo: refactor to functional component
import React from 'react';
import { Tag, Input, Tooltip, Icon } from 'antd';
import './style.scss';
import _ from 'lodash';

export class PdTags extends React.Component {
  static defaultProps = {
    onChange: () => {},
    tags: [],
    unremovables: [],
  };

  state = {
    inputVisible: false,
    inputValue: '',
  };

  handleClose = (removedTag) => {
    const tags = this.props.tags.filter((tag) => tag !== removedTag);
    this.props.onChange(tags);
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = (e) => {
    e.preventDefault();
    const { inputValue } = this.state;
    let { tags } = this.props;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    this.props.onChange(tags);
    this.setState(
      {
        inputValue: '',
      },
      () => this.input.focus()
    );
  };

  handleBlur = () => {
    this.setState({
      inputValue: '',
      inputVisible: false,
    });
  };

  saveInputRef = (input) => (this.input = input);

  render() {
    const { inputVisible, inputValue } = this.state;
    const { tags } = this.props;
    return (
      <div className="pd-tags">
        {tags.map((tag) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              key={tag}
              closable={!_.get(this, 'props.unremovables', []).includes(tag)}
              visible={tags.includes(tag)}
              onClose={this.handleClose.bind(this, tag)}>
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleBlur}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag
            onClick={this.showInput}
            style={{
              background: '#fff',
              borderStyle: 'dashed',
              cursor: 'pointer',
            }}>
            <Icon type="plus" /> Add New
          </Tag>
        )}
      </div>
    );
  }
}
