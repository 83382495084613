import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { message } from 'antd';

import { BlogCreateEditForm } from 'components/blog/createEdit';

import BlogReviewApi from 'api/v2/blog/review';

import _ from 'lodash';

const ReviewsCreateEdit = () => {
  let history = useHistory();
  const { id } = useParams();
  const [content, setContent] = useState({});

  const fetchReviews = async () => {
    const { data } = await BlogReviewApi.getById(id);
    const content = data.data;

    setContent(content);
  };

  const handleContent = (val, key) => {
    if (val) {
      setContent((prevState) => ({
        ...prevState,
        [key]: val,
      }));
    }
  };

  const createData = async (payload) => {
    await BlogReviewApi.create(payload);

    message.success(`Successfully created new Banner`);

    history.push({
      pathname: '/cms/reviews-and-promos/reviews',
    });
  };

  const updateData = async (payload) => {
    await BlogReviewApi.update(payload);

    message.success(`Successfully updated new Banner`);

    history.push({
      pathname: '/cms/reviews-and-promos/reviews',
    });
  };

  const handleCreateSection = async (section_images) => {
    const payload = {
      url_link: '',
      url_link_mobile: '',
      promotion_title: '',
      section_number:
        section_images.length > 0
          ? section_images[section_images.length - 1].section_number + 1
          : 1,
    };

    try {
      await BlogReviewApi.createSection({
        ...payload,
        blog_review_id: Number(id),
      });

      message.success('New section created');
      fetchReviews();
    } catch (err) {
      const errMessage = _.get(err, 'response.data.message');
      message.error(errMessage || 'Cannot connect to the server');
    }
  };

  const handleDeleteSection = async (id) => {
    await BlogReviewApi.deleteSection(id);
    fetchReviews();
  };

  const handleUpdateSection = async (payload) => {
    await BlogReviewApi.updateSection(payload.id, payload);
  };

  useEffect(() => {
    id && fetchReviews();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BlogCreateEditForm
      pageName="Blog Post"
      handleCreate={createData}
      handleUpdate={updateData}
      content={content}
      handleContent={handleContent}
      handleCreateSection={handleCreateSection}
      handleUpdateSection={handleUpdateSection}
      handleDeleteSection={handleDeleteSection}
    />
  );
};

export default ReviewsCreateEdit;
