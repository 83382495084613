import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { message } from 'antd';

import { BlogCreateEditForm } from 'components/blog/createEdit';

import BlogStoryApi from 'api/v2/blog/story';

const StoryCreateEdit = () => {
  let history = useHistory();
  const { id } = useParams();

  const [content, setContent] = useState({});

  const handleContent = (val, key) => {
    if (val) {
      setContent((prevState) => ({
        ...prevState,
        [key]: val,
      }));
    }
  };

  const createData = async (payload) => {
    await BlogStoryApi.create(payload);

    message.success(`Successfully created new Story`);

    history.push({
      pathname: '/cms/reviews-and-promos/stories',
    });
  };

  const updateData = async (payload) => {
    await BlogStoryApi.update(payload);

    message.success(`Successfully updated new Story`);

    history.push({
      pathname: '/cms/reviews-and-promos/stories',
    });
  };

  useEffect(() => {
    const fetchStory = async () => {
      const { data } = await BlogStoryApi.getById(id);
      const StoryDetail = data.data;

      setContent(StoryDetail);
    };

    id && fetchStory();
  }, [id]);

  return (
    <BlogCreateEditForm
      pageName="Cerita Pengguna Rata"
      handleCreate={createData}
      handleUpdate={updateData}
      content={content}
      handleContent={handleContent}
    />
  );
};

export default StoryCreateEdit;
