import React, { useEffect, useState } from 'react';
import { Button, Form, Icon, Input, Layout, Typography, message } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { PageSpinner } from 'components/page-spinner';

import './style.scss';
import { hasFormErrors } from 'utils';
import question from 'api/v2/question';

const { Header, Content } = Layout;
const { Title } = Typography;
const FIELDS = {
  ID: 'id',
  QUESTION: 'question',
  ANSWER: 'answer',
  ORDER_LEVEL: 'order_level',
};

function FAQsCreateEdit({ form, history }) {
  const [testimony, setTestimony] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const questionId = urlParams.get('id');

  useEffect(() => {
    async function getTesimonyList() {
      setLoadingPage(true);
      try {
        const { data } = await question.getQuestion(questionId);
        const testimonyData = data.data;

        setTestimony(testimonyData);
      } finally {
        setLoadingPage(false);
      }
    }

    if (questionId) {
      getTesimonyList();
    }
  }, [questionId]);

  const { TextArea } = Input;

  const { getFieldDecorator, getFieldsError } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          const payload = _.pick(
            {
              ...testimony,
              ...values,
            },
            Object.values(FIELDS)
          );
          if (questionId) {
            await question.updateQuestionDetail(questionId, {
              ...payload,
              ...{ id: questionId },
            });
            message.success('Data has been updated');
          } else {
            await question.createQuestion({
              ...payload,
              order_level: 0,
            });
            message.success('Data has been created');
          }

          history.push({
            pathname: '/cms/apa-itu-rata/faq',
          });
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/apa-itu-rata/faq">
            <Icon type="caret-left" />
          </Link>
          {questionId ? 'Edit' : 'Add New'} FAQ
        </Title>
      </Header>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Question">
            {getFieldDecorator(FIELDS.QUESTION, {
              rules: [{ required: true, message: 'Please input name!' }],
              initialValue: testimony[FIELDS.QUESTION],
            })(<Input placeholder="Write your name here" />)}
          </Form.Item>

          <Form.Item label="Answer">
            {getFieldDecorator(FIELDS.ANSWER, {
              rules: [{ required: true, message: 'Please input message!' }],
              initialValue: testimony[FIELDS.ANSWER],
            })(<TextArea placeholder="Write your message here" />)}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const FAQsForm = Form.create({
  name: 'validate_testimonials_edit',
})(FAQsCreateEdit);
