import api from './index';

export default {
  get() {
    return api.get(`quiz/intro-contents`);
  },
  update(payload) {
    return api.put(`quiz/intro-contents`, { quiz_intro_contents: payload });
  },
};
