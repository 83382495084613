import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement(({ value, render }) => {
  return <div>{render(value)}</div>;
});

export const PdSortable = SortableContainer(
  ({ items, render, rowKey = 'id' }) => {
    return (
      <div>
        {items.map((item, index) => (
          <SortableItem
            key={item[rowKey] || index}
            index={index}
            value={item}
            render={render}
          />
        ))}
      </div>
    );
  }
);
