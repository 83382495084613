import api from './index';

export default {
  get() {
    return api.get('quiz/result-page');
  },
  update(payload) {
    return api.put('quiz/result-page/1', { quiz_result_pages: payload });
  },
};
