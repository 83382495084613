import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Icon,
  Input,
  Layout,
  Switch,
  Upload,
  Typography,
  message,
  // Radio,
} from 'antd';
import { Link } from 'react-router-dom';
import ColorPicker from 'rc-color-picker';

import UploadApi from 'api/upload';
import BannerApi from 'api/banner';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const FIELDS = {
  TITLE: 'title',
  TITLE_FONT_COLOR: 'title_font_color',
  DESCRIPTION: 'description',
  DESCRIPTION_FONT_COLOR: 'description_font_color',
  DESKTOP_MEDIA_URL: 'desktop_media_url',
  MOBILE_MEDIA_URL: 'mobile_media_url',
  MEDIA_ALT_TEXT: 'media_alt_text',
  STATUS: 'status',
  LINK_URL: 'link_url',
  ACTION_BUTTON: 'action_button',
  ACTION_BUTTON_IS_ACTIVE: 'action_button_is_active',
  SECOND_LINK_URL: 'second_button_url',
  SECOND_BUTTON_COLOR: 'second_button_outline_color',
  SECOND_ACTION_BUTTON: 'second_action_button',
  SECOND_ACTION_BUTTON_IS_ACTIVE: 'second_action_button_is_active',
  BACKGROUND_COLOR: 'background_color',
  BUTTON_BACKGROUND_COLOR: 'button_background_color',
  BUTTON_FONT_COLOR: 'button_font_color',
};

export function PromoBannerEdit(props) {
  const [formLayout] = useState();
  const [value, setValue] = useState({});
  // const [linkType, setLinkType] = useState('link');
  // const [linkURL, setLinkURL] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState({});
  const [uploadURL, setUploadURL] = useState({});

  const { history, location, form, match } = props;
  const { TextArea } = Input;

  const id = match.params.id;

  const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
        }
      : null;

  const buttonItemLayout =
    formLayout === 'horizontal'
      ? {
          wrapperCol: { span: 14, offset: 4 },
        }
      : null;

  const { getFieldDecorator, getFieldsError } = form;

  // function changeRadio(e) {
  //   setLinkType(e.target.value);
  //   if (e.target.value === 'whatsapp') {
  //     setValue((prevState) => ({
  //       ...prevState,
  //       [FIELDS.LINK_URL]: process.env.REACT_APP_WHATSAPP_LINK,
  //     }));
  //   }
  // }

  // function changeValue(e, key) {
  //   e.persist();
  //   if (e.target.value) {
  //     if (key === FIELDS.LINK_URL) {
  //       setLinkURL(e.target.value);
  //     }
  //     setValue((prevState) => ({
  //       ...prevState,
  //       [key]: e.target.value,
  //     }));
  //   }
  // }

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }

  async function beforeUpload(file) {
    try {
      const ext = file.type.split('/')[1];
      const { data } = await UploadApi.getUploadURL(ext, 'banner');
      setUploadURL(data.data);
    } catch (err) {
      if (err.response.data.message) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    }
  }

  async function awsUpload(file, key) {
    try {
      setLoadingUpload((prevState) => ({
        ...prevState,
        [key]: true,
      }));
      await UploadApi.uploadImage(file.file, uploadURL.upload_url);
      setValue((prevState) => ({
        ...prevState,
        [key]: uploadURL.secure_url,
      }));
      message.success('Berhasil mengupload');
    } catch (err) {
      if (err.response.data.message) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    } finally {
      setLoadingUpload((prevState) => ({
        ...prevState,
        [key]: false,
      }));
    }
  }

  function changeColor(value, key) {
    if (value.color) {
      setValue((prevState) => ({
        ...prevState,
        [key]: value.color,
      }));
      return value.color;
    }
    return;
  }

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          if (id) {
            await BannerApi.updateBannerDetail({
              ...value,
              ...values,
              ...{ id },
            });
            message.success('Berhasil mengupdate banner');
          } else {
            await BannerApi.createBanner({
              ...value,
              ...values,
            });
            message.success('Berhasil membuat banner');
          }
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
        history.push({
          pathname: '/cms/home/banners',
        });
      }
    });
  }

  useEffect(() => {
    if (id) {
      if (location.data) {
        setValue(location.data.banner);
        // if (
        //   location.data.banner[FIELDS.LINK_URL] ===
        //   process.env.REACT_APP_WHATSAPP_LINK
        // ) {
        //   setLinkType('whatsapp');
        // } else {
        //   setLinkType('link');
        //   setLinkURL(location.data.banner[FIELDS.LINK_URL]);
        // }
      } else {
        history.push({
          pathname: '/cms/home/banners',
        });
      }
    }
  }, [history, location, id]);

  return (
    <Layout className="pd-cms-home-promo-banner pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/home/banners">
            <Icon type="caret-left" />
          </Link>
          {id ? 'Edit' : 'Create New'} Banner Slides
        </Title>
      </Header>
      <Content>
        <Form layout={formLayout} onSubmit={(event) => handleSubmit(event)}>
          <Form.Item label="Desktop Banner (1440x920 px)" {...formItemLayout}>
            <Upload.Dragger
              name="Foto Banner (Desktop)"
              customRequest={(file) =>
                awsUpload(file, FIELDS.DESKTOP_MEDIA_URL)
              }
              showUploadList={false}
              beforeUpload={beforeUpload}>
              {value[FIELDS.DESKTOP_MEDIA_URL] ? (
                loadingUpload[FIELDS.DESKTOP_MEDIA_URL] ? (
                  <Icon
                    type={
                      loadingUpload[FIELDS.DESKTOP_MEDIA_URL]
                        ? 'loading'
                        : 'plus'
                    }
                  />
                ) : (
                  <img
                    src={value[FIELDS.DESKTOP_MEDIA_URL]}
                    alt={FIELDS.DESKTOP_MEDIA_URL}
                    style={{ width: 'inherit', height: '500px' }}
                  />
                )
              ) : (
                <>
                  <p className="ant-upload-drag-icon">
                    <Icon
                      type={
                        loadingUpload[FIELDS.DESKTOP_MEDIA_URL]
                          ? 'loading'
                          : 'inbox'
                      }
                    />
                  </p>
                  <p className="ant-upload-text">
                    Pilih atau Seret Picture kesini untuk upload Picture
                  </p>
                </>
              )}
            </Upload.Dragger>
          </Form.Item>

          <Form.Item label="Mobile Banner (640x1800 px)" {...formItemLayout}>
            <Upload.Dragger
              name="Foto Banner (Mobile)"
              customRequest={(file) => awsUpload(file, FIELDS.MOBILE_MEDIA_URL)}
              showUploadList={false}
              beforeUpload={beforeUpload}>
              {value[FIELDS.MOBILE_MEDIA_URL] ? (
                loadingUpload[FIELDS.MOBILE_MEDIA_URL] ? (
                  <Icon
                    type={
                      loadingUpload[FIELDS.MOBILE_MEDIA_URL]
                        ? 'loading'
                        : 'plus'
                    }
                  />
                ) : (
                  <img
                    src={value[FIELDS.MOBILE_MEDIA_URL]}
                    alt={FIELDS.MOBILE_MEDIA_URL}
                    style={{ width: 'inherit', height: '500px' }}
                  />
                )
              ) : (
                <>
                  <p className="ant-upload-drag-icon">
                    <Icon
                      type={
                        loadingUpload[FIELDS.MOBILE_MEDIA_URL]
                          ? 'loading'
                          : 'inbox'
                      }
                    />
                  </p>
                  <p className="ant-upload-text">
                    Pilih atau Seret Picture kesini untuk upload Picture
                  </p>
                </>
              )}
            </Upload.Dragger>
          </Form.Item>

          <Form.Item label="Banner Alt Text" {...formItemLayout}>
            {getFieldDecorator(FIELDS.MEDIA_ALT_TEXT, {
              initialValue: value[FIELDS.MEDIA_ALT_TEXT],
            })(<Input placeholder="Enter alt text for the image" />)}
          </Form.Item>

          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              initialValue: value[FIELDS.TITLE],
            })(<Input placeholder="Specify the banner title" />)}
          </Form.Item>

          <Form.Item label="Title Font Color">
            {getFieldDecorator(FIELDS.TITLE_FONT_COLOR, {
              getValueFromEvent: (e) => changeColor(e, FIELDS.TITLE_FONT_COLOR),
            })(
              <ColorPicker
                animation="slide-up"
                color={value[FIELDS.TITLE_FONT_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item label="Description">
            {getFieldDecorator(FIELDS.DESCRIPTION, {
              initialValue: value[FIELDS.DESCRIPTION],
            })(<TextArea placeholder="Write the banner description" />)}
          </Form.Item>

          <Form.Item label="Description Font Color">
            {getFieldDecorator(FIELDS.DESCRIPTION_FONT_COLOR, {
              getValueFromEvent: (e) =>
                changeColor(e, FIELDS.DESCRIPTION_FONT_COLOR),
            })(
              <ColorPicker
                animation="slide-up"
                color={value[FIELDS.DESCRIPTION_FONT_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item label="Show Action Button">
            {getFieldDecorator(FIELDS.ACTION_BUTTON_IS_ACTIVE, {
              valuePropName: 'checked',
              initialValue: value[FIELDS.ACTION_BUTTON_IS_ACTIVE],
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Form.Item label="Action Button">
            {getFieldDecorator(FIELDS.ACTION_BUTTON, {
              initialValue: value[FIELDS.ACTION_BUTTON],
            })(<Input placeholder="Specify the button text" />)}
          </Form.Item>

          <Form.Item label="Action Button URL" {...formItemLayout}>
            {getFieldDecorator(FIELDS.LINK_URL, {
              initialValue: value[FIELDS.LINK_URL],
            })(<Input placeholder="Specify the link url" />)}
          </Form.Item>

          <Form.Item label="Banner Background Color">
            {getFieldDecorator(FIELDS.BACKGROUND_COLOR, {
              getValueFromEvent: (e) => changeColor(e, FIELDS.BACKGROUND_COLOR),
            })(
              <ColorPicker
                animation="slide-up"
                color={value[FIELDS.BACKGROUND_COLOR]}
              />
            )}
          </Form.Item>

          {/* <Form.Item label="Link Type" {...formItemLayout}>
            <Radio.Group onChange={changeRadio} value={linkType}>
              <Radio value={'link'}>Custom URL</Radio>
              <Radio value={'whatsapp'}>WhatsApp URL</Radio>
            </Radio.Group>
          </Form.Item> */}

          {/* {linkType === 'link' && (
            <Form.Item label="URL" {...formItemLayout}>
              {getFieldDecorator(FIELDS.LINK_URL, {
                initialValue: linkURL,
              })(
                <Input
                  placeholder="Specify the link url"
                  onChange={(e) => changeValue(e, FIELDS.LINK_URL)}
                />
              )}
            </Form.Item>
          )} */}

          <Form.Item label="Button Color">
            {getFieldDecorator(FIELDS.BUTTON_BACKGROUND_COLOR, {
              getValueFromEvent: (e) =>
                changeColor(e, FIELDS.BUTTON_BACKGROUND_COLOR),
            })(
              <ColorPicker
                animation="slide-up"
                color={value[FIELDS.BUTTON_BACKGROUND_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item label="Button Font Color">
            {getFieldDecorator(FIELDS.BUTTON_FONT_COLOR, {
              getValueFromEvent: (e) =>
                changeColor(e, FIELDS.BUTTON_FONT_COLOR),
            })(
              <ColorPicker
                animation="slide-up"
                color={value[FIELDS.BUTTON_FONT_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item {...buttonItemLayout}>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const PromoBannerEditForm = Form.create({
  name: 'validate_hone_promo_banner',
})(PromoBannerEdit);

export default { PromoBannerEdit };
