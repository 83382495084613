import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { message } from 'antd';

import { BlogCreateEditForm } from 'components/blog/createEdit';

import BlogPromoApi from 'api/v2/blog/promo';

import _ from 'lodash';

const PromoCreateEdit = () => {
  let history = useHistory();
  const { id } = useParams();

  const [content, setContent] = useState({
    section_images: [],
  });

  const fetchPromos = async () => {
    const { data } = await BlogPromoApi.getPromo(id);
    const content = data.data;

    setContent(content);
  };

  const handleContent = (val, key) => {
    if (val) {
      setContent((prevState) => ({
        ...prevState,
        [key]: val,
      }));
    }
  };

  const createData = async (payload) => {
    await BlogPromoApi.createPromo(payload);

    message.success(`Successfully created new Promo`);

    history.push({
      pathname: '/cms/reviews-and-promos/promos',
    });
  };

  const updateData = async (payload) => {
    await BlogPromoApi.updatePromo(payload);

    message.success(`Successfully updated new Promo`);

    history.push({
      pathname: '/cms/reviews-and-promos/promos',
    });
  };

  const handleCreateSection = async (section_images) => {
    const payload = {
      url_link: '',
      url_link_mobile: '',
      promotion_title: '',
      section_number:
        section_images.length > 0
          ? section_images[section_images.length - 1].section_number + 1
          : 1,
    };

    try {
      await BlogPromoApi.createSection({
        ...payload,
        blog_promo_id: Number(id),
      });

      message.success('New section created');
      fetchPromos();
    } catch (err) {
      const errMessage = _.get(err, 'response.data.message');
      message.error(errMessage || 'Cannot connect to the server');
    }
  };

  const handleDeleteSection = async (id) => {
    await BlogPromoApi.deleteSection(id);
    fetchPromos();
  };

  const handleUpdateSection = async (payload) => {
    await BlogPromoApi.updateSection(payload.id, payload);
  };

  useEffect(() => {
    id && fetchPromos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <BlogCreateEditForm
      pageName="Promo"
      handleCreate={createData}
      handleUpdate={updateData}
      content={content}
      handleContent={handleContent}
      handleCreateSection={handleCreateSection}
      handleUpdateSection={handleUpdateSection}
      handleDeleteSection={handleDeleteSection}
    />
  );
};

export default PromoCreateEdit;
