import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Layout, Row, Typography, Button, message, Modal } from 'antd';

import { PageSpinner } from 'components/page-spinner';
import './style.scss';
import { PdSortable } from 'components/pd-sortable';
import questionApi from 'api/v2/question';

const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;
const { confirm } = Modal;

export const FAQ = () => {
  const [questions, setQestion] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);

  async function deleteQuestion(id) {
    try {
      await questionApi.deleteQuestion(id);
      getQuestions();
      message.success('Item has been deleted');
    } catch {
      message.error('Cannot connect to the server');
    }
  }

  function handleDelete(id) {
    confirm({
      title: 'Are you sure to delete this?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteQuestion(id);
      },
      onCancel() {},
    });
  }

  async function getQuestions() {
    try {
      setLoadingPage(true);
      const { data } = await questionApi.listQuestions();
      const questions = data.data;

      setQestion(questions);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getQuestions();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const SortableItem = (question) => {
    return (
      <Row
        type="flex"
        align="middle"
        justify="space-around"
        className="faq-item draggable-item"
        gutter={32}>
        {/* message and name */}
        <Col span={12}>
          <div className="container--center">
            <Title level={4}>Q: {question.question}</Title>
            <Paragraph
              ellipsis={{ rows: 3, expandable: true }}
              style={{ width: '100%', fontStyle: 'italic' }}>
              A: {question.answer}
            </Paragraph>
          </div>
        </Col>

        {/* button group */}
        <Col span={12}>
          <Row type="flex" justify="end">
            <Button
              type="danger"
              onClick={handleDelete.bind(this, question.id)}>
              Delete
            </Button>
            <Button style={{ marginLeft: 24 }} type="primary">
              <Link to={`/cms/apa-itu-rata/faq/edit?id=${question.id}`}>
                Edit
              </Link>
            </Button>
          </Row>
        </Col>
      </Row>
    );
  };

  async function onSortEnd({ oldIndex, newIndex }) {
    let list = [...questions];
    let temp = list[oldIndex];
    list.splice(oldIndex, 1);
    list.splice(newIndex, 0, temp);

    const payload = list.map((item, index) => {
      return {
        id: item.id,
        order_level: index,
        question: item.question,
        answer: item.answer,
      };
    });

    try {
      setQestion(list);
      await questionApi.updateQuestionBulk(payload);
      message.success('Ordering has been updated');
    } catch {
      message.error('Cannot connect to the server');
    }
  }

  return (
    <Layout className="pd-cms-faq pd-cms-form-1">
      <Header>
        <Title>FAQ</Title>
      </Header>

      <Content id="drag-content">
        <Button
          type="primary"
          style={{ fontSize: '18px' }}
          className="pd-margin-bottom-lg">
          <Link to={'/cms/apa-itu-rata/faq/create'}>Add New FAQ</Link>
        </Button>
        <PdSortable
          render={SortableItem}
          items={questions}
          onSortEnd={onSortEnd}
          lockAxis="y"
          helperClass="item-dragged"
          pressDelay={100}
          helperContainer={() => document.getElementById('drag-content')}
        />
      </Content>
    </Layout>
  );
};
