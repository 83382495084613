import api from './index';

export default {
  get() {
    return api.get(`/doctors`);
  },
  create(payload) {
    return api.post(`/doctors`, { doctor: payload });
  },
  getOne(id) {
    return api.get(`/doctors/${id}`);
  },
  update(id, payload) {
    return api.put(`/doctors`, { doctors: [{ id, ...payload }] });
  },
  updateOrder(payload) {
    return api.put(`/doctors`, { doctors: payload });
  },
  delete(id) {
    return api.delete(`/doctors/${id}`);
  },
};
