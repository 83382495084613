import React, { useState, useRef } from 'react';

import { Button, Input, Layout, Typography, Icon, message } from 'antd';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const Generator = () => {
  const inputPhoneRef = useRef('');
  const inputUrlTextRef = useRef('');

  const [urlResult, setUrlResult] = useState('');

  const generateLink = () => {
    const number = inputPhoneRef.current.state.value;
    const message = inputUrlTextRef.current.state.value.replaceAll(' ', '%20');
    const url = 'https://api.whatsapp.com';
    const end_url = `${url}/send?phone=${number}&text=${message}`;

    setUrlResult(end_url);
  };

  const copyToClipboard = () => {
    const cb = navigator.clipboard;
    const paragraph = urlResult;

    cb.writeText(paragraph).then(() => {
      message.success('Pesan telah berhasil disalin ke clipboard');
    });
  };

  return (
    <Layout className="pd-cms-generator">
      <Header>
        <Title>URL Generator</Title>
      </Header>

      <Content>
        <div className="input-container">
          <label>Phone Number</label>
          <Input ref={inputPhoneRef} placeholder="Input phone" />
        </div>

        <div className="input-container">
          <label>Message Text</label>
          <Input ref={inputUrlTextRef} placeholder="Input message" />
        </div>

        <Button type="primary" onClick={generateLink}>
          Generate
        </Button>

        {urlResult !== '' && (
          <div className="url-result">
            <h4>Result</h4>
            <p>{urlResult}</p>

            <div className="copy-btn" onClick={copyToClipboard}>
              <Icon type="copy" /> Copy
            </div>
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default Generator;
