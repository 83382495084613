import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { message } from 'antd';

import { BlogCreateEditForm } from 'components/blog/createEdit';

import BlogRataUpdateApi from 'api/v2/blog/rata-update';

import _ from 'lodash';

const NewsCreateEdit = () => {
  let history = useHistory();
  const { id } = useParams();

  const [content, setContent] = useState({});

  const fetchNews = async () => {
    const { data } = await BlogRataUpdateApi.getById(id);
    const content = data.data;

    setContent(content);
  };

  const handleContent = (val, key) => {
    if (val) {
      setContent((prevState) => ({
        ...prevState,
        [key]: val,
      }));
    }
  };

  const createData = async (payload) => {
    await BlogRataUpdateApi.create(payload);

    message.success(`Successfully created new News`);

    history.push({
      pathname: '/cms/reviews-and-promos/rata-updates',
    });
  };

  const updateData = async (payload) => {
    await BlogRataUpdateApi.update(payload);

    message.success(`Successfully updated new News`);

    history.push({
      pathname: '/cms/reviews-and-promos/rata-updates',
    });
  };

  const handleCreateSection = async (section_images) => {
    const payload = {
      url_link: '',
      url_link_mobile: '',
      promotion_title: '',
      section_number:
        section_images.length > 0
          ? section_images[section_images.length - 1].section_number + 1
          : 1,
    };

    try {
      await BlogRataUpdateApi.createSection({
        ...payload,
        blog_rata_update_id: Number(id),
      });

      message.success('New section created');
      fetchNews();
    } catch (err) {
      const errMessage = _.get(err, 'response.data.message');
      message.error(errMessage || 'Cannot connect to the server');
    }
  };

  const handleDeleteSection = async (id) => {
    await BlogRataUpdateApi.deleteSection(id);
    fetchNews();
  };

  const handleUpdateSection = async (payload) => {
    await BlogRataUpdateApi.updateSection(payload.id, payload);
  };

  useEffect(() => {
    id && fetchNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BlogCreateEditForm
      pageName="Rata Update"
      handleCreate={createData}
      handleUpdate={updateData}
      content={content}
      handleContent={handleContent}
      handleCreateSection={handleCreateSection}
      handleUpdateSection={handleUpdateSection}
      handleDeleteSection={handleDeleteSection}
    />
  );
};

export default NewsCreateEdit;
