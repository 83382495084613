import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Layout, Typography, message } from 'antd';
import QuizIntroContentApi from 'api/v3/quiz_intro_content';
import { PdUploader } from 'components/pd-uploader';
import { hasFormErrors } from 'utils';
import { PageSpinner } from 'components/page-spinner';

import ReactQuill from 'react-quill';

import _ from 'lodash';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const FIELDS = {
  HEADLINE: 'headline',
  DESCRIPTION: 'description',
  BUTTON_TEXT: 'button_text',
  MEDIA_URL: 'media_url',
};

function QuizIntroContent(props) {
  const [content, setContent] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const { form } = props;

  const { getFieldDecorator, getFieldsError } = form;

  const getContent = async () => {
    setLoadingPage(true);
    try {
      const res = await QuizIntroContentApi.get();
      const data = _.get(res, 'data.data');

      // use only key declared in FIELDS
      setContent(_.pick(data, Object.values(FIELDS)));
    } catch (err) {
      const errMessage = _.get(err, 'response.data.message');
      message.error(errMessage || 'Cannot connect to the server');
    } finally {
      setLoadingPage(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          // avoid overfetch and unwanted mutation
          const payload = _.pick(
            { ...content, ...values },
            Object.values(FIELDS)
          );
          await QuizIntroContentApi.update(payload);
          message.success('Data has been updated');
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
        }
      }
    });
  };

  useEffect(() => {
    getContent();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-video-section pd-cms-form-1">
      <Header>
        <Title>Quiz Intro</Title>
      </Header>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Headline">
            {getFieldDecorator(FIELDS.HEADLINE, {
              initialValue: content[FIELDS.HEADLINE],
            })(<Input placeholder="Enter headline" />)}
          </Form.Item>

          <Form.Item label="Content">
            {getFieldDecorator(FIELDS.DESCRIPTION, {
              rules: [{ required: true, message: 'Please input description!' }],
              initialValue: content[FIELDS.DESCRIPTION] || '',
            })(
              <ReactQuill
                placeholder="Write your description here"
                modules={modules}
                formats={formats}
              />
            )}
          </Form.Item>

          <Form.Item label="Button Text">
            {getFieldDecorator(FIELDS.BUTTON_TEXT, {
              initialValue: content[FIELDS.BUTTON_TEXT],
            })(<Input placeholder="Enter button text" />)}
          </Form.Item>

          <Form.Item label="Picture">
            <PdUploader
              purpose="quiz_video"
              afterUpload={(url) => {
                setContent((prevState) => ({
                  ...prevState,
                  [FIELDS.MEDIA_URL]: url,
                }));
              }}
              imgUrl={content[FIELDS.MEDIA_URL]}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const QuizIntroContentForm = Form.create({
  name: 'quiz_intro_content',
})(QuizIntroContent);
