import React, { useEffect, useState } from 'react';
import ColorPicker from 'rc-color-picker';

import { Button, Input, Layout, Form, Switch, Typography, message } from 'antd';

import HomePageApi from 'api/v2/homePage';

import { PageSpinner } from 'components/page-spinner';

import _ from 'lodash';

import './style.scss';
import { PdTags } from 'components/pd-tags';
import { PdUploader } from 'components/pd-uploader';

const { Header, Content } = Layout;
const { Title } = Typography;

// TODO add more fields, and refers hardcoded key with this
const FIELDS = {
  TITLE: 'pricing_title',
  PRICING_BENEFITS: 'pricing_benefits',
  PRICING_BUTTON_FONT_COLOR: 'pricing_button_font_color',
  DESKTOP_MEDIA_URL: 'pricing_media_url',
  MOBILE_MEDIA_URL: 'pricing_media_url_mobile',
  ACTION_LINK: 'pricing_action',
  ACTION_LINK_IS_ACTIVE: 'pricing_action_is_active',
  ACTION_LINK_URL: 'pricing_action_url',
  SECTION_IMAGE_ALT_TEXT: 'pricing_media_alt_text',
};

export function HeaderComponent({ form }) {
  const [value, setValue] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const { getFieldDecorator, getFieldsError } = form;

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }

  useEffect(() => {
    async function getBuyingStepList() {
      try {
        const { data } = await HomePageApi.showHomePage('pricing');
        const pricingHeaders = data.data;

        setValue(pricingHeaders);
      } finally {
        setLoadingPage(false);
      }
    }

    getBuyingStepList();
  }, []);

  function setColor(value, key) {
    if (value.color) {
      setValue((prevState) => ({
        ...prevState,
        [key]: value.color,
      }));
      return value.color;
    }
    return;
  }

  function changeValue(e, key) {
    e.persist();
    setValue((prevState) => ({
      ...prevState,
      [key]: _.get(e, 'target.value'),
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);

          // send only selected key-values, avoid overfetch and unwanted mutation
          const payload = _.pick(
            { ...value, ...values },
            Object.values(FIELDS)
          );

          await HomePageApi.updateHomePage(payload);

          message.success('Data has been updated');
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>Harga</Title>
      </Header>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              rules: [
                {
                  required: true,
                  message: 'Please input the Title!',
                },
              ],
              initialValue: _.get(value, FIELDS.TITLE, ''),
            })(
              <Input
                type="text"
                onChange={(e) => changeValue(e, FIELDS.TITLE)}
              />
            )}
          </Form.Item>

          <Form.Item label="Benefits">
            <PdTags
              tags={_.get(value, FIELDS.PRICING_BENEFITS, [])}
              onChange={(benefits) => {
                setValue((prev) => ({
                  ...prev,
                  [FIELDS.PRICING_BENEFITS]: benefits,
                }));
              }}
            />
          </Form.Item>

          <Form.Item label="Show Link Action">
            {getFieldDecorator(FIELDS.ACTION_LINK_IS_ACTIVE, {
              valuePropName: 'checked',
              initialValue: _.get(value, FIELDS.ACTION_LINK_IS_ACTIVE, ''),
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Form.Item label="Link Action">
            {getFieldDecorator(FIELDS.ACTION_LINK, {
              rules: [
                {
                  required: true,
                  message: 'Please input the Button Text!',
                },
              ],
              initialValue: _.get(value, FIELDS.ACTION_LINK, ''),
            })(
              <Input
                type="text"
                onChange={(e) => changeValue(e, FIELDS.ACTION_LINK)}
              />
            )}
          </Form.Item>

          <Form.Item label="Link Action Font Color">
            {getFieldDecorator(FIELDS.PRICING_BUTTON_FONT_COLOR, {
              getValueFromEvent: (e) =>
                setColor(e, FIELDS.PRICING_BUTTON_FONT_COLOR),
              initialValue: _.get(value, FIELDS.PRICING_BUTTON_FONT_COLOR, ''),
            })(
              <ColorPicker
                animation="slide-up"
                color={value[FIELDS.PRICING_BUTTON_FONT_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item label="Action link url">
            {getFieldDecorator(FIELDS.ACTION_LINK_URL, {
              rules: [
                {
                  required: true,
                  message: 'Please input the action url',
                },
              ],
              initialValue: _.get(value, FIELDS.ACTION_LINK_URL, ''),
            })(<Input type="text" />)}
          </Form.Item>

          <Form.Item label="Section Desktop Image (480x580 px)">
            <PdUploader
              purpose="price"
              imgUrl={_.get(value, FIELDS.DESKTOP_MEDIA_URL, '')}
              afterUpload={(url) => {
                setValue((prevState) => ({
                  ...prevState,
                  [FIELDS.DESKTOP_MEDIA_URL]: url,
                }));
              }}
            />
          </Form.Item>

          <Form.Item label="Section Mobile Image (610x640 px)">
            <PdUploader
              purpose="price"
              imgUrl={_.get(value, FIELDS.MOBILE_MEDIA_URL, '')}
              afterUpload={(url) => {
                setValue((prevState) => ({
                  ...prevState,
                  [FIELDS.MOBILE_MEDIA_URL]: url,
                }));
              }}
            />
          </Form.Item>

          <Form.Item label="Section Image Alt Text">
            {getFieldDecorator(FIELDS.SECTION_IMAGE_ALT_TEXT, {
              rules: [{ message: 'Please input your Title!' }],
              initialValue: _.get(value, FIELDS.SECTION_IMAGE_ALT_TEXT, ''),
            })(
              <Input
                type="text"
                onChange={(e) => changeValue(e, FIELDS.SECTION_IMAGE_ALT_TEXT)}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
              loading={loadingButton}>
              Change
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const PricingForm = Form.create({ name: 'validate_cara_kerja' })(
  HeaderComponent
);

export default { HeaderComponent };
