import api from '../index';

export default {
  get(q = '', month = null, status = '') {
    let url = '/blog/reviews';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    month && filters.push(`month=${month}`);
    status.trim() !== '' && filters.push(`status=${status}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  getById(id) {
    return api.get(`/blog/reviews/${id}`);
  },
  create(payload) {
    return api.post(`/blog/reviews`, {
      blog_reviews: payload,
    });
  },
  update(payload) {
    return api.put(`/blog/reviews`, {
      blog_reviews: [payload],
    });
  },
  updateMultiple(payload) {
    return api.put(`/blog/reviews`, {
      blog_reviews: payload,
    });
  },
  updatePriority(payload) {
    return api.put(`/blog/reviews/priority`, {
      blog_reviews: [payload],
    });
  },
  createSection(payload) {
    return api.post(`/blog/review-section-images`, {
      blog_review_section_images: payload,
    });
  },
  updateSection(id, payload) {
    return api.put(`/blog/review-section-images/${id}`, {
      blog_review_section_images: payload,
    });
  },
  deleteSection(id) {
    return api.delete(`/blog/review-section-images/${id}`);
  },
};
