import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ColorPicker from 'rc-color-picker';
import ReactQuill from 'react-quill';

import {
  Button,
  Col,
  Input,
  Layout,
  Form,
  Typography,
  Row,
  Select,
  Switch,
  message,
} from 'antd';

import { BlogSectionForm } from 'components/blog/section';
import { PdUploader } from 'components/pd-uploader';

import { get } from 'lodash';

import { FIELDS, PAGES_SECTION_AVAILABLE } from './constant';

import 'react-quill/dist/quill.snow.css';

const { Header, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const BlogCreateEdit = ({
  handleContent,
  form,
  pageName = '',
  content,
  handleUpdate,
  handleCreate,
  handleCreateSection,
  handleUpdateSection,
  handleDeleteSection,
}) => {
  const { id } = useParams();

  const bannerPlacement = [
    {
      id: 1,
      title: '1. Front Page - Top Long Banner',
    },
    {
      id: 2,
      title: '2. Front Page - Vertical Banner (Review & Blog Section)',
    },
    {
      id: 3,
      title:
        '3. Landing Getting Started - Vertical Banner (Cerita Pengguna Rata Section)',
    },
    {
      id: 4,
      title: '4. Detail Blog & Promo Page',
    },
  ];
  const [value, setValue] = useState({});
  const [mapImages, setMapImages] = useState({
    desktop_media_url: '',
    mobile_media_url: '',
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const handleImages = (val) => {
    setValue((prevState) => ({
      ...prevState,
      section_images: val,
    }));
  };

  function changeColor(value, key) {
    if (value.color) {
      setValue((prevState) => ({
        ...prevState,
        [key]: value.color,
      }));
      return value.color;
    }
    return;
  }

  const submitPromotion = (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      const payload = { ...value, ...values, ...mapImages };

      if (!err) {
        try {
          setLoadingButton(true);

          if (id) {
            await handleUpdate({ ...payload, ...{ id } });
          } else {
            await handleCreate(payload);
          }
        } catch (err) {
          message.error('Tidak dapat menghubungi server, cek koneksi');
        } finally {
          setLoadingButton(false);
        }
      }
    });
  };

  const handleMapImages = (key, value) => {
    setMapImages((prevState) => ({ ...prevState, [key]: value }));
  };

  const { getFieldDecorator, getFieldsError } = form;

  const hasErrors = (fieldsError) =>
    Object.keys(fieldsError).some((field) => fieldsError[field]);

  const onChange = (val, key) => {
    handleContent(key, val);
  };

  useEffect(() => {
    handleMapImages(
      FIELDS.DESKTOP_MEDIA_URL,
      content[FIELDS.DESKTOP_MEDIA_URL]
    );
    handleMapImages(FIELDS.MOBILE_MEDIA_URL, content[FIELDS.MOBILE_MEDIA_URL]);
  }, [content]);

  return (
    <Layout className="rata-cms-blog-create-edit">
      <Header>
        <Title>
          {id ? 'Update' : 'Upload New'} {pageName}
        </Title>
      </Header>

      <Content>
        <Form
          onSubmit={(event) => submitPromotion(event)}
          className="pd-admin-create-form">
          {pageName !== 'Banner' &&
            pageName !== 'Hasil Pakai Rata' &&
            pageName !== 'Cerita Pengguna Rata' &&
            pageName !== 'Youtube Post' && (
              <Form.Item label="Publish Post">
                {getFieldDecorator(FIELDS.IS_ACTIVE, {
                  valuePropName: 'checked',
                  initialValue: content[FIELDS.IS_ACTIVE] || false,
                })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
              </Form.Item>
            )}

          {pageName === 'Post' && (
            <Form.Item label="Title">
              {getFieldDecorator(FIELDS.HEADER, {
                rules: [
                  {
                    required: true,
                    message: 'Please input the title!',
                  },
                ],
                initialValue: content[FIELDS.HEADER],
              })(<Input placeholder="Insert title" />)}
            </Form.Item>
          )}

          {pageName === 'Youtube Post' && (
            <Form.Item label="Youtube Link">
              {getFieldDecorator(FIELDS.URL, {
                rules: [
                  {
                    required: true,
                    message: 'Please input the Youtube link!',
                  },
                ],
                initialValue: content[FIELDS.URL],
              })(<Input placeholder="Insert your youtube link" />)}
            </Form.Item>
          )}

          {pageName === 'Banner' && (
            <Form.Item label="Banner Placement">
              {getFieldDecorator(FIELDS.PLACEMENT_ID, {
                rules: [{ required: true, message: 'Please input the name!' }],
                initialValue: content[FIELDS.PLACEMENT_ID],
              })(
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select banner placement"
                  optionFilterProp="children"
                  onChange={(val) => onChange(val, FIELDS.CITY)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {bannerPlacement.length > 0 &&
                    bannerPlacement.map((banner) => (
                      <Option value={banner.id} key={banner.id}>
                        {banner.title}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          )}

          {(pageName === 'Promo' ||
            pageName === 'Hasil Pakai Rata' ||
            pageName === 'Rata Update') && (
            <>
              <Form.Item label="Title">
                {getFieldDecorator(FIELDS.TITLE, {
                  rules: [
                    { required: true, message: 'Please input the title!' },
                  ],
                  initialValue: content[FIELDS.TITLE],
                })(<Input placeholder="Input title" />)}
              </Form.Item>
            </>
          )}

          <Row gutter={[16, 0]}>
            <Col xs={12}>
              <Form.Item label="Desktop Background Picture (1440x920)">
                <PdUploader
                  purpose={FIELDS.DESKTOP_MEDIA_URL}
                  imgUrl={
                    get(mapImages, FIELDS.DESKTOP_MEDIA_URL, '') ||
                    content[FIELDS.DESKTOP_MEDIA_URL]
                  }
                  afterUpload={(url) => {
                    handleMapImages([FIELDS.DESKTOP_MEDIA_URL], url);
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item label="Mobile Background Picture">
                <PdUploader
                  purpose={FIELDS.MOBILE_MEDIA_URL}
                  imgUrl={
                    get(mapImages, FIELDS.MOBILE_MEDIA_URL, '') ||
                    content[FIELDS.MOBILE_MEDIA_URL]
                  }
                  afterUpload={(url) => {
                    handleMapImages([FIELDS.MOBILE_MEDIA_URL], url);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Pictures Alt Text">
            {getFieldDecorator(FIELDS.MEDIA_ALT_TEXT, {
              rules: [{ required: true, message: 'Please input alt text!' }],
              initialValue: content[FIELDS.MEDIA_ALT_TEXT],
            })(<Input placeholder="Input alt text" />)}
          </Form.Item>

          {pageName !== 'Banner' && pageName !== 'Youtube Post' && (
            <>
              <Form.Item label="Title">
                {getFieldDecorator(FIELDS.TITLE, {
                  rules: [
                    { required: true, message: 'Please input the title!' },
                  ],
                  initialValue: content[FIELDS.TITLE],
                })(<Input placeholder="Input Article title" />)}
              </Form.Item>
              <Form.Item label="Content">
                {getFieldDecorator(FIELDS.CONTENT, {
                  rules: [{ required: true, message: 'Please input content!' }],
                  initialValue: content[FIELDS.CONTENT] || '',
                })(
                  <ReactQuill
                    placeholder="Write your content here"
                    modules={modules}
                    formats={formats}
                  />
                )}
              </Form.Item>
            </>
          )}

          {pageName === 'Banner' && (
            <>
              <Form.Item label="Show Action Button">
                {getFieldDecorator(FIELDS.BUTTON_IS_ACTIVE, {
                  valuePropName: 'checked',
                  initialValue: content[FIELDS.BUTTON_IS_ACTIVE] || false,
                })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
              </Form.Item>

              <Form.Item label="Action Button Text">
                {getFieldDecorator(FIELDS.BUTTON_TEXT, {
                  initialValue: content[FIELDS.BUTTON_TEXT],
                })(<Input placeholder="Input action button name" />)}
              </Form.Item>

              <Form.Item label="Button Link URL">
                {getFieldDecorator(FIELDS.BUTTON_URL, {
                  initialValue: content[FIELDS.BUTTON_URL],
                })(<Input placeholder="Type Link URL" />)}
              </Form.Item>

              <Form.Item label="Button Color">
                {getFieldDecorator(FIELDS.BUTTON_BACKGROUND_COLOR, {
                  getValueFromEvent: (e) =>
                    changeColor(e, FIELDS.BUTTON_BACKGROUND_COLOR),
                })(
                  <ColorPicker
                    animation="slide-up"
                    color={content[FIELDS.BUTTON_BACKGROUND_COLOR]}
                  />
                )}
              </Form.Item>

              <Form.Item label="Button Font Color">
                {getFieldDecorator(FIELDS.BUTTON_FONT_COLOR, {
                  getValueFromEvent: (e) =>
                    changeColor(e, FIELDS.BUTTON_FONT_COLOR),
                })(
                  <ColorPicker
                    animation="slide-up"
                    color={content[FIELDS.BUTTON_FONT_COLOR]}
                  />
                )}
              </Form.Item>
            </>
          )}

          {(pageName === 'Promo' || pageName === 'Rata Update') && (
            <>
              <Form.Item label="Show Image Action Button">
                {getFieldDecorator(FIELDS.BUTTON_IMAGE_IS_ACTIVE, {
                  valuePropName: 'checked',
                  initialValue: content[FIELDS.BUTTON_IMAGE_IS_ACTIVE] || false,
                })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
              </Form.Item>

              <Form.Item label="Image Action Button Text">
                {getFieldDecorator(FIELDS.BUTTON_IMAGE_TEXT, {
                  initialValue: content[FIELDS.BUTTON_IMAGE_TEXT],
                })(<Input placeholder="Input action button name" />)}
              </Form.Item>

              <Form.Item label="Image Action Button Link URL">
                {getFieldDecorator(FIELDS.BUTTON_IMAGE_LINK_URL, {
                  initialValue: content[FIELDS.BUTTON_IMAGE_LINK_URL],
                })(<Input placeholder="Type Link URL" />)}
              </Form.Item>

              <Form.Item label="Button Color">
                {getFieldDecorator(FIELDS.BUTTON_IMAGE_BACKGROUND_COLOR, {
                  getValueFromEvent: (e) =>
                    changeColor(e, FIELDS.BUTTON_IMAGE_BACKGROUND_COLOR),
                })(
                  <ColorPicker
                    animation="slide-up"
                    color={content[FIELDS.BUTTON_IMAGE_BACKGROUND_COLOR]}
                  />
                )}
              </Form.Item>

              <Form.Item label="Button Font Color">
                {getFieldDecorator(FIELDS.BUTTON_IMAGE_FONT_COLOR, {
                  getValueFromEvent: (e) =>
                    changeColor(e, FIELDS.BUTTON_IMAGE_FONT_COLOR),
                })(
                  <ColorPicker
                    animation="slide-up"
                    color={content[FIELDS.BUTTON_IMAGE_FONT_COLOR]}
                  />
                )}
              </Form.Item>
            </>
          )}

          {(pageName === 'Rata Update' || pageName === 'Blog Post') && (
            <>
              <Form.Item label="Show Download Button">
                {getFieldDecorator(FIELDS.BUTTON_DOWNLOAD_IS_ACTIVE, {
                  valuePropName: 'checked',
                  initialValue:
                    content[FIELDS.BUTTON_DOWNLOAD_IS_ACTIVE] || false,
                })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
              </Form.Item>

              <Form.Item label="Download Link URL">
                {getFieldDecorator(FIELDS.BUTTON_DOWNLOAD_LINK_URL, {
                  initialValue: content[FIELDS.BUTTON_DOWNLOAD_LINK_URL],
                })(<Input placeholder="Input link url" />)}
              </Form.Item>
            </>
          )}

          {pageName !== 'Banner' &&
            pageName !== 'Hasil Pakai Rata' &&
            pageName !== 'Cerita Pengguna Rata' &&
            pageName !== 'Youtube Post' && (
              <Form.Item label="Meta Description">
                {getFieldDecorator(FIELDS.META_DESCRIPTION, {
                  initialValue: content[FIELDS.META_DESCRIPTION],
                })(<Input placeholder="Input meta description" />)}
              </Form.Item>
            )}

          <Form.Item>
            <Button
              style={{ width: '100%' }}
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>

        <BlogSectionForm
          section_images={content.section_images}
          handleImages={handleImages}
          handleCreate={handleCreateSection}
          handleDelete={handleDeleteSection}
          handleUpdate={handleUpdateSection}
          isSectionActive={PAGES_SECTION_AVAILABLE.includes(pageName)}
        />
      </Content>
    </Layout>
  );
};

export const BlogCreateEditForm = Form.create({
  name: 'blog_create_edit_form',
})(BlogCreateEdit);

export default BlogCreateEditForm;
