import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Layout,
  Row,
  Typography,
  Button,
  message,
  Modal,
  Form,
  Input,
} from 'antd';

import locationApi from 'api/v2/location';
import homeApi from 'api/v2/homePage';
import { PageSpinner } from 'components/page-spinner';
import _ from 'lodash';
import './style.scss';
import { PdSortable } from 'components/pd-sortable';
import HtmlRender from 'components/html-render';
import { hasFormErrors } from 'utils';

const { Header, Content } = Layout;
const { Title } = Typography;
const { confirm } = Modal;
const FIELDS = {
  LOCATION_TITLE: 'location_title',
  LOCATION_MOBILE_TITLE: 'location_mobile_title',
};
export const LocationForm = Form.create({ name: 'locations' })(({ form }) => {
  const [locations, setLocations] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [titles, setTitles] = useState({});

  async function getTestimonyList() {
    try {
      setLoadingPage(true);
      const { data } = await locationApi.listLocation();
      const locationList = data.data;
      const {
        data: { data: homeData },
      } = await homeApi.showHomePage('location');
      setTitles(_.pick(homeData, Object.values(FIELDS)));
      setLocations(locationList);
    } finally {
      setLoadingPage(false);
    }
  }

  function handleDelete(id) {
    confirm({
      title: 'Are you sure to delete this?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        async function deletingLocation() {
          try {
            await locationApi.deleteLocation(id);
            await getTestimonyList();
            message.success('Item has been deleted');
          } catch {
            message.error('Cannot connect to the server');
          }
        }
        deletingLocation();
      },
      onCancel() {},
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setSubmitLoading(true);
          await homeApi.updateHomePage(values);
          message.success('Data has been updated');
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setSubmitLoading(false);
        }
      }
    });
  }

  async function onSortEnd({ oldIndex, newIndex }) {
    let list = [...locations];
    let temp = list[oldIndex];
    list.splice(oldIndex, 1);
    list.splice(newIndex, 0, temp);

    const payload = list.map((item, index) => {
      return {
        id: item.id,
        order_level: index,
      };
    });

    try {
      setLocations(list);
      await locationApi.updateLocationsOrder(payload);
      message.success('Ordering has been updated');
    } catch {
      message.error('Cannot connect to the server');
    }
  }

  useEffect(() => {
    getTestimonyList();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const SortableItem = (location) => {
    return (
      <Row
        type="flex"
        align="middle"
        justify="space-around"
        className="location-item draggable-item"
        gutter={32}>
        {/* image */}
        <Col span={5}>
          <div className="profile-image">
            <img src={location.media_url} alt={location.title} />
          </div>
        </Col>

        {/* title and address */}
        <Col span={11}>
          <div className="container--center">
            <Typography.Title level={4}>{location.title}</Typography.Title>
            <div className="address-description">
              <HtmlRender content={location.address} />
            </div>
          </div>
        </Col>

        {/* button group */}
        <Col span={8}>
          <Row type="flex" justify="end">
            <Button
              type="danger"
              onClick={handleDelete.bind(this, location.id)}>
              Delete
            </Button>
            <Button style={{ marginLeft: 24 }} type="primary">
              <Link to={`/cms/home/location/edit?id=${location.id}`}>Edit</Link>
            </Button>
          </Row>
        </Col>
      </Row>
    );
  };
  const { getFieldDecorator, getFieldsError } = form;

  return (
    <Layout className="pd-cms-home-locations">
      <Header>
        <Title>Lokasi</Title>
      </Header>

      <Content id="drag-content">
        <Form onSubmit={handleSubmit} className="pd-margin-bottom-xl">
          <Form.Item label="Desktop title">
            {getFieldDecorator(FIELDS.LOCATION_TITLE, {
              rules: [{ required: true, message: 'Please input the title!' }],
              initialValue: titles[FIELDS.LOCATION_TITLE],
            })(<Input placeholder="Input title" />)}
          </Form.Item>

          <Form.Item label="Mobile title">
            {getFieldDecorator(FIELDS.LOCATION_MOBILE_TITLE, {
              rules: [{ required: true, message: 'Please input the title!' }],
              initialValue: titles[FIELDS.LOCATION_MOBILE_TITLE],
            })(<Input placeholder="Input mobile title" />)}
          </Form.Item>

          <Form.Item>
            <Button
              loading={submitLoading}
              block
              disabled={hasFormErrors(getFieldsError())}
              type="primary"
              htmlType="submit">
              Publish
            </Button>
          </Form.Item>
        </Form>
        <Button
          type="primary"
          style={{ fontSize: '18px' }}
          className="pd-margin-bottom-lg">
          <Link to={'/cms/home/location/create'}>Add New location</Link>
        </Button>
        <PdSortable
          render={SortableItem}
          items={locations}
          onSortEnd={onSortEnd}
          lockAxis="y"
          pressDelay={200}
          helperContainer={() => document.getElementById('drag-content')}
        />
      </Content>
    </Layout>
  );
});
