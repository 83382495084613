import api from './index';

export default {
  getBuyingStep(id) {
    return api.get(`/buying_steps/${id}`);
  },
  deleteBuyingStep(id) {
    return api.delete(`/buying_steps/${id}`);
  },
  createBuyingStep(payload) {
    return api.post(`/buying_steps`, { buying_steps: payload });
  },
  listBuyingSteps() {
    return api.get(`/buying_steps`);
  },
  updateBuyingStepDetail(id, buying_steps) {
    return api.put(`/buying_steps/${id}`, { buying_steps });
  },
  updateBuyingStepBulk(payload) {
    return api.put(`/buying_steps`, { buying_steps: [payload] });
  },
  updateBuyingStepOrder(payload) {
    return api.put(`/buying_steps`, { buying_steps: payload });
  },
};
