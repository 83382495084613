import api from '../index';

export default {
  get(q = '', month = null, status = '') {
    let url = '/blog/youtubes';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    month && filters.push(`month=${month}`);
    status.trim() !== '' && filters.push(`status=${status}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  getById(id) {
    return api.get(`/blog/youtubes/${id}`);
  },
  create(payload) {
    return api.post(`/blog/youtubes`, { blog_youtubes: payload });
  },
  update(payload) {
    return api.put(`/blog/youtubes`, { blog_youtubes: [payload] });
  },
  updatePriority(payload) {
    return api.put(`/blog/youtubes/priority`, { blog_youtubes: [payload] });
  },
};
