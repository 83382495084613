import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Switch,
  Typography,
  message,
} from 'antd';

import _ from 'lodash';

import ColorPicker from 'rc-color-picker';
import ReactQuill from 'react-quill';

import { PdUploader } from 'components/pd-uploader';

import FIELDS from './constants';

import 'react-quill/dist/quill.snow.css';

const { Title } = Typography;

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const BlogSection = ({
  isSectionActive = false,
  section_images = [],
  handleCreate = () => {},
  handleDelete = () => {},
  handleUpdate = () => {},
}) => {
  const location = useLocation();
  const [sectionImages, setSectionImages] = useState([]);
  const [uploadImage, setUploadImage] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);

  const updateSectionImage = async (payload) => {
    setLoadingButton(true);

    try {
      await handleUpdate(payload);
      message.success('Section successfully updated');
    } catch (err) {
      const errMessage = _.get(err, 'response.data.message');
      message.error(errMessage || 'Cannot connect to the server');
    } finally {
      setLoadingButton(false);
    }
  };

  const handleSubmit = (e, index, id) => {
    e.preventDefault();
    const payload = sectionImages[index];

    updateSectionImage({ ...payload, id });
  };

  const remove = (id) => {
    setLoadingButton(true);

    if (section_images.length > 1) {
      try {
        handleDelete(id);

        // handleImages((prevState) => prevState.filter((item) => item.id !== id));
        message.success('Section successfully deleted');
      } catch {
        message.error('Cannot connect to the server');
      } finally {
        setLoadingButton(false);
      }
    }
  };

  const handleSectionValue = (index, value, type) => {
    const temp = [...section_images];
    temp[index][type] = value;

    setSectionImages(temp);
  };

  const changeColor = (index, value, type) => {
    if (value.color) {
      const temp = [...section_images];
      temp[index][type] = value.color;

      setSectionImages(temp);

      return value.color;
    }
    return;
  };

  useEffect(() => {
    if (uploadImage.url !== '') {
      const temp = [...section_images];

      const newArray = temp.map((item, indexArray) => {
        if (indexArray === uploadImage.index) {
          item[uploadImage.purpose] = uploadImage.url;
        }

        return item;
      });

      setSectionImages(newArray);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadImage]);

  useEffect(() => {
    section_images.length > 0 && setSectionImages(section_images);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section_images]);

  return (
    <>
      {sectionImages.length > 0 &&
        sectionImages.map((section_image, index) => (
          <Form
            onSubmit={(event) => handleSubmit(event, index, section_image.id)}
            key={index}>
            <Row>
              <Col xs={9}>
                <Title level={3}>Section {index + 1}</Title>
              </Col>

              <Col xs={{ offset: 12, span: 3 }}>
                {section_images.length > 1 ? (
                  <Button
                    style={{ width: '100%' }}
                    className="btn-full"
                    type="danger"
                    loading={loadingButton}
                    onClick={() => remove(_.get(section_image, 'id', ''))}>
                    Delete
                  </Button>
                ) : null}
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item label="Sub Title">
                  <Input
                    value={_.get(section_image, FIELDS.SUB_TITLE, '')}
                    placeholder="Input sub title"
                    onChange={(event) =>
                      handleSectionValue(
                        index,
                        event.target.value,
                        FIELDS.SUB_TITLE
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={12}>
                <Form.Item label="Desktop Picture (1440x920)">
                  <PdUploader
                    purpose="section_images"
                    imgUrl={_.get(section_image, FIELDS.DESKTOP_IMAGES_URL, '')}
                    afterUpload={(url) => {
                      setUploadImage({
                        index,
                        purpose: FIELDS.DESKTOP_IMAGES_URL,
                        url,
                      });
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={12}>
                <Form.Item label="Mobile Picture">
                  <PdUploader
                    purpose="section_image"
                    imgUrl={_.get(section_image, FIELDS.MOBILE_IMAGES_URL, '')}
                    afterUpload={(url) => {
                      setUploadImage({
                        index,
                        purpose: FIELDS.MOBILE_IMAGES_URL,
                        url,
                      });
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Show Image Button">
                  <Switch
                    checked={_.get(
                      section_image,
                      FIELDS.IMAGE_BUTTON_IS_ACTIVE,
                      ''
                    )}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(value) =>
                      handleSectionValue(
                        index,
                        value,
                        FIELDS.IMAGE_BUTTON_IS_ACTIVE
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Image Button Text">
                  <Input
                    value={_.get(section_image, FIELDS.IMAGE_BUTTON_TEXT, '')}
                    placeholder="Input button text"
                    onChange={(event) =>
                      handleSectionValue(
                        index,
                        event.target.value,
                        FIELDS.IMAGE_BUTTON_TEXT
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Image Button Url">
                  <Input
                    value={_.get(section_image, FIELDS.IMAGE_BUTTON_URL, '')}
                    placeholder="Input button url"
                    onChange={(event) =>
                      handleSectionValue(
                        index,
                        event.target.value,
                        FIELDS.IMAGE_BUTTON_URL
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Image Button Color">
                  <ColorPicker
                    animation="slide-up"
                    color={section_image[FIELDS.IMAGE_BUTTON_BG_COLOR]}
                    onChange={(value) =>
                      changeColor(index, value, FIELDS.IMAGE_BUTTON_BG_COLOR)
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Image Button Font Color">
                  <ColorPicker
                    animation="slide-up"
                    color={section_image[FIELDS.IMAGE_BUTTON_FONT_COLOR]}
                    onChange={(value) =>
                      changeColor(index, value, FIELDS.IMAGE_BUTTON_FONT_COLOR)
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Content">
                  <ReactQuill
                    value={_.get(section_image, FIELDS.CONTENT, '')}
                    placeholder="Write your content here"
                    modules={modules}
                    formats={formats}
                    onChange={(value) =>
                      handleSectionValue(index, value, FIELDS.CONTENT)
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Show Text Button">
                  <Switch
                    checked={_.get(
                      section_image,
                      FIELDS.TEXT_BUTTON_IS_ACTIVE,
                      ''
                    )}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(value) =>
                      handleSectionValue(
                        index,
                        value,
                        FIELDS.TEXT_BUTTON_IS_ACTIVE
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Text Button Text">
                  <Input
                    value={_.get(section_image, FIELDS.TEXT_BUTTON_TEXT, '')}
                    placeholder="Input button text"
                    onChange={(event) =>
                      handleSectionValue(
                        index,
                        event.target.value,
                        FIELDS.TEXT_BUTTON_TEXT
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Text Button Url">
                  <Input
                    value={_.get(section_image, FIELDS.TEXT_BUTTON_URL, '')}
                    placeholder="Input button text"
                    onChange={(event) =>
                      handleSectionValue(
                        index,
                        event.target.value,
                        FIELDS.TEXT_BUTTON_URL
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Text Button Color">
                  <ColorPicker
                    animation="slide-up"
                    color={section_image[FIELDS.TEXT_BUTTON_BG_COLOR]}
                    onChange={(value) =>
                      changeColor(index, value, FIELDS.TEXT_BUTTON_BG_COLOR)
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Text Button Font Color">
                  <ColorPicker
                    animation="slide-up"
                    color={section_image[FIELDS.TEXT_BUTTON_FONT_COLOR]}
                    onChange={(value) =>
                      changeColor(index, value, FIELDS.TEXT_BUTTON_FONT_COLOR)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Col xs={24}>
              <Button
                className="btn-full confirm-btn"
                htmlType="submit"
                loading={loadingButton}
                style={{ width: '100%' }}
                type="primary">
                Confirm Section {index + 1}
              </Button>
            </Col>
          </Form>
        ))}

      {isSectionActive && location.pathname.split('/').includes('edit') && (
        <Row
          style={{
            margin: '55px 0 30px',
          }}>
          <Col xs={24}>
            <Button
              className="btn-full"
              style={{ width: '100%' }}
              onClick={() => handleCreate(section_images)}>
              <Icon type="plus" /> Add Section
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export const BlogSectionForm = Form.create({
  name: 'validate_blog_section',
})(BlogSection);

export default withRouter({ BlogSection });
