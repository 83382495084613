import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Icon, Input, Layout, Typography, message } from 'antd';

import _ from 'lodash';
import 'react-quill/dist/quill.snow.css';

import DoctorApi from 'api/v2/doctor';

import { PageSpinner } from 'components/page-spinner';
import { DOCTOR_FIELDS } from './constants';
import { PdUploader } from 'components/pd-uploader';
import { hasFormErrors } from 'utils';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

export function DoctorCreateEdit(props) {
  const [formLayout] = useState();
  const [doctor, setDoctor] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const { history, form } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  const { getFieldDecorator, getFieldsError } = form;

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      const payload = _.pick(
        {
          ...doctor,
          ...values,
        },
        Object.values(DOCTOR_FIELDS)
      );

      if (!err) {
        try {
          setLoadingButton(true);

          if (id) {
            await DoctorApi.update(id, payload);

            message.success('Data has been updated');
          } else {
            await DoctorApi.create(payload);

            message.success('Data has been created');
          }
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
        }
        history.push({
          pathname: '/cms/home/doctors',
        });
      }
    });
  }

  useEffect(() => {
    async function getDoctor(id) {
      setLoadingPage(true);

      try {
        const {
          data: { data: doctor },
        } = await DoctorApi.getOne(id);

        setDoctor(doctor);
      } catch (err) {
        const errMessage = _.get(err, 'response.data.message');
        message.error(errMessage || 'Cannot connect to the server');
      } finally {
        setLoadingPage(false);
      }
    }

    if (id) {
      getDoctor(id);
    }
  }, [id]);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/home/doctors">
            <Icon type="caret-left" />
          </Link>
          {id ? 'Edit' : 'Add New'} Doctor
        </Title>
      </Header>
      <Content>
        <Form layout={formLayout} onSubmit={handleSubmit}>
          <Form.Item label="Name">
            {getFieldDecorator(DOCTOR_FIELDS.NAME, {
              rules: [{ required: true, message: 'Please input the name!' }],
              initialValue: doctor[DOCTOR_FIELDS.NAME],
            })(<Input placeholder="Input doctor name" />)}
          </Form.Item>

          <Form.Item label="Picture">
            <PdUploader
              purpose="doctor"
              imgUrl={_.get(doctor, DOCTOR_FIELDS.MEDIA_URL, '')}
              afterUpload={(url) => {
                setDoctor((prevState) => ({
                  ...prevState,
                  [DOCTOR_FIELDS.MEDIA_URL]: url,
                }));
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const DoctorCreateEditForm = Form.create({
  name: 'validate_doctor_create_edit',
})(DoctorCreateEdit);

export default { DoctorCreateEdit };
