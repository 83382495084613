import api from './index';

export default {
  get() {
    return api.get(`quiz/answers`);
  },

  getNew() {
    return api.get(`users/quizzes`);
  },
};
