import api from './index';

export default {
  createLocation(payload) {
    return api.post(`/locations`, { location: payload });
  },
  listLocation() {
    return api.get(`/locations`);
  },
  getLocation(id) {
    return api.get(`/locations/${id}`);
  },
  updateLocation(id, payload) {
    return api.put(`/locations`, { locations: [{ id, ...payload }] });
  },
  updateLocationsOrder(payload) {
    return api.put(`/locations`, { locations: payload });
  },
  deleteLocation(id) {
    return api.delete(`/locations/${id}`);
  },
};
