import React, { useEffect, useState } from 'react';

import { Button, Form, Input, Layout, Typography, message } from 'antd';

import { get, pickBy } from 'lodash';

import HomePageApi from 'api/v2/homePage';

import { PageSpinner } from 'components/page-spinner';

import './style.scss';
import { PdTags } from 'components/pd-tags';
import { PdUploader } from 'components/pd-uploader';

const { Header, Content } = Layout;
const { Title } = Typography;
const FIELDS = {
  TITLE: 'procedure_title',
  DESCRIPTION: 'procedure_description',
  BENEFITS: 'procedure_benefits',
  BENEFIT_DESCRIPTION: 'procedure_benefit_descriptions',
  SECTION_DESKTOP_IMAGE: 'procedure_thumbnail_media',
  SECTION_MOBILE_IMAGE: 'procedure_thumbnail_mobile',
  SECTION_IMAGE_ALT_TEXT: 'procedure_thumbnail_alt_text',
};

export function HomeProcedure({ form }) {
  const [pageContent, setPageContent] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const { TextArea } = Input;

  useEffect(() => {
    async function getHomePageProcedure() {
      try {
        const { data } = await HomePageApi.showHomePage('procedure');
        const homePageProcedure = data.data;

        setPageContent(homePageProcedure);
      } finally {
        setLoadingPage(false);
      }
    }

    getHomePageProcedure();
  }, []);

  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
  } = form;

  const titleError = isFieldTouched('title') && getFieldError('title');
  const descriptionError =
    isFieldTouched('description') && getFieldError('description');

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);

          // avoid overfetch by including keys in fields
          const payload = pickBy({ ...values, ...pageContent }, (_, key) =>
            Object.values(FIELDS).includes(key)
          );

          await HomePageApi.updateHomePage(payload);

          message.success('Data has been updated');
        } catch (err) {
          const errMessage = get(err, 'response.data.message');
          message.error(
            errMessage || 'Cannot connect to the server'
          );
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  function changeValue(e, key) {
    e.persist();
    if (e.target.value) {
      setPageContent((prevState) => ({
        ...prevState,
        [key]: e.target.value,
      }));
    }
  }

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>Prosedur</Title>
      </Header>
      <Content>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item
            label="Title"
            validateStatus={titleError ? 'error' : ''}
            help={titleError || ''}>
            {getFieldDecorator('procedure_title', {
              rules: [{ required: true, message: 'Please input title!' }],
              initialValue: get(pageContent, 'procedure_title', ''),
            })(
              <Input
                placeholder="Write your title here"
                onChange={(e) => changeValue(e, 'procedure_title')}
              />
            )}
          </Form.Item>

          <Form.Item
            label="Subtitle"
            validateStatus={descriptionError ? 'error' : ''}
            help={descriptionError || ''}>
            {getFieldDecorator('procedure_description', {
              rules: [{ required: true, message: 'Please input title!' }],
              initialValue: get(pageContent, 'procedure_description', ''),
            })(
              <TextArea
                placeholder="Write your description here"
                onChange={(e) => changeValue(e, 'procedure_description')}
              />
            )}
          </Form.Item>

          <Form.Item label="Benefits">
            <PdTags
              tags={get(pageContent, FIELDS.BENEFITS, [])}
              onChange={(benefits) => {
                setPageContent((prev) => ({
                  ...prev,
                  [FIELDS.BENEFITS]: benefits,
                }));
              }}
            />
          </Form.Item>

          <Form.Item label="Benefits">
            <PdTags
              tags={get(pageContent, FIELDS.BENEFIT_DESCRIPTION, [])}
              onChange={(benefits) => {
                setPageContent((prev) => ({
                  ...prev,
                  [FIELDS.BENEFIT_DESCRIPTION]: benefits,
                }));
              }}
            />
          </Form.Item>
          <Form.Item label="Section Desktop Image (700x750 px)">
            <PdUploader
              purpose="description"
              imgUrl={get(pageContent, FIELDS.SECTION_DESKTOP_IMAGE, '')}
              afterUpload={(url) => {
                setPageContent((prevState) => ({
                  ...prevState,
                  [FIELDS.SECTION_DESKTOP_IMAGE]: url,
                }));
              }}
            />
          </Form.Item>

          <Form.Item label="Section Mobile Image (640x600 px)">
            <PdUploader
              purpose="description"
              imgUrl={get(pageContent, FIELDS.SECTION_MOBILE_IMAGE, '')}
              afterUpload={(url) => {
                setPageContent((prevState) => ({
                  ...prevState,
                  [FIELDS.SECTION_MOBILE_IMAGE]: url,
                }));
              }}
            />
          </Form.Item>

          <Form.Item
            label="Thumbnail Alt Text"
            validateStatus={titleError ? 'error' : ''}
            help={titleError || ''}>
            {getFieldDecorator('procedure_thumbnail_alt_text', {
              rules: [{ required: true, message: 'Please input title!' }],
              initialValue: get(
                pageContent,
                'procedure_thumbnail_alt_text',
                ''
              ),
            })(
              <Input
                placeholder="Enter thumbnail alt text"
                onChange={(e) => changeValue(e, 'procedure_thumbnail_alt_text')}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
              loading={loadingButton}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const HomeProcedureForm = Form.create({
  name: 'validate_home_how_to_work',
})(HomeProcedure);

export default { HomeProcedure };
