import { Icon, message, Upload } from 'antd';
import uploadApi from 'api/upload';
import React, { useState } from 'react';
import _ from 'lodash';
import './style.scss';
import ReactPlayer from 'react-player';

export const PdUploader = ({
  imgUrl = '',
  imgStyle = {},
  alt = '',
  purpose = '',
  type = 'image',
  afterUpload = () => {},
}) => {
  const [uploading, setUploading] = useState(false);

  async function beforeUpload(file, purpose) {
    try {
      setUploading(true);
      const isLt500kb = file.size / 1024 / 1024 < 0.5;

      if (!isLt500kb) {
        message.error('Image must smaller or equal than 512kb!');
      } else {
        const { data } = await uploadApi.uploadImage({
          file_purpose: purpose,
          file_media: file,
        });

        message.success('Berhasil mengupload');
        afterUpload(data.data.mediaUrl);
      }
    } catch (err) {
      const errMessage = _.get(err, 'response.data.message');
      if (errMessage) {
        message.error(errMessage);
      } else {
        message.error('Cannot connect to server, please check connection');
      }
    } finally {
      setUploading(false);
    }
  }

  return (
    <Upload.Dragger
      className="pd-uploader"
      showUploadList={false}
      beforeUpload={(file) => {
        beforeUpload(file, purpose);
      }}>
      {!imgUrl || uploading ? (
        <>
          <p className="ant-upload-drag-icon">
            <Icon type={uploading ? 'loading' : 'inbox'} />
          </p>
          <p className="ant-upload-text">Click or drag to import an image</p>
        </>
      ) : type === 'image' ? (
        <img
          className="img-container"
          style={imgStyle}
          alt={alt}
          src={imgUrl}
        />
      ) : (
        <ReactPlayer style={{ margin: '0 auto' }} controls url={imgUrl} />
      )}
    </Upload.Dragger>
  );
};
