export const FIELDS = {
  TITLE: 'title',
  HEADER: 'header',
  URL: 'url',
  ALT_TEXT: 'alt_text',
  IS_ACTIVE: 'is_active',
  CONTENT: 'content',
  BUTTON_IS_ACTIVE: 'button_is_active',
  BUTTON_URL: 'button_url',
  BUTTON_TEXT: 'button_text',
  BUTTON_BACKGROUND_COLOR: 'button_background_color',
  BUTTON_FONT_COLOR: 'button_font_color',
  BUTTON_IMAGE_IS_ACTIVE: 'button_image_is_active',
  BUTTON_IMAGE_TEXT: 'button_image_text',
  BUTTON_IMAGE_LINK_URL: 'button_image_link_url',
  BUTTON_IMAGE_BACKGROUND_COLOR: 'button_image_background_color',
  BUTTON_IMAGE_FONT_COLOR: 'button_image_font_color',
  BUTTON_DOWNLOAD_IS_ACTIVE: 'button_download_is_active',
  BUTTON_DOWNLOAD_LINK_URL: 'button_download_link_url',
  DESKTOP_MEDIA_URL: 'desktop_media_url',
  MOBILE_MEDIA_URL: 'mobile_media_url',
  MEDIA_ALT_TEXT: 'media_alt_text',
  META_DESCRIPTION: 'meta_description',
  PLACEMENT_ID: 'placement_id',
};

export const PAGES_SECTION_AVAILABLE = [
  'Promo',
  'Post',
  'Rata Update',
  'Blog Post',
];
