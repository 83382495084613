import api from './index';

export default {
  createBanner(payload) {
    return api.post(`/banners`, { banner: payload });
  },
  listBanners() {
    return api.get(`/banners`);
  },
  getBanner(id) {
    return api.get(`/banners/${id}`);
  },
  updateBannerDetail(payload) {
    return api.put(`/banners`, { banners: [payload] });
  },
  updateBannerOrder(payload) {
    return api.put(`/banners`, { banners: payload });
  },
  deleteBanner(id) {
    return api.delete(`/banners/${id}`);
  },
};
