import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ColorPicker from 'rc-color-picker';
import ReactQuill from 'react-quill';

import {
  Button,
  Input,
  Layout,
  Form,
  Typography,
  Select,
  Switch,
  message,
  Col,
  Row,
} from 'antd';

// import { BlogSectionForm } from 'components/blog/section';
import { PdUploader } from 'components/pd-uploader';

import ArticleCategoryApi from 'api/v3/article_category';

import { get } from 'lodash';

import { FIELDS } from './constant';

import 'react-quill/dist/quill.snow.css';

const { Header, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const ArticleCreateEdit = ({
  handleContent,
  form,
  content,
  handleUpdate,
  handleCreate,
}) => {
  const { id } = useParams();

  const [articleCategories, setArticleCategories] = useState([]);
  const [mapImages, setMapImages] = useState({
    media_url: '',
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const submit = (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      delete values[FIELDS.BUTTON_BACKGROUND_COLOR];
      delete values[FIELDS.BUTTON_FONT_COLOR];

      const payload = { ...content, ...values, ...mapImages };

      if (!err) {
        try {
          setLoadingButton(true);

          if (id) {
            await handleUpdate(payload, id);
          } else {
            await handleCreate(payload);
          }
        } catch (err) {
          message.error('Tidak dapat menghubungi server, cek koneksi');
        } finally {
          setLoadingButton(false);
        }
      }
    });
  };

  const handleMapImages = (key, value) => {
    setMapImages((prevState) => ({ ...prevState, [key]: value }));
  };

  const { getFieldDecorator, getFieldsError } = form;

  const hasErrors = (fieldsError) =>
    Object.keys(fieldsError).some((field) => fieldsError[field]);

  const onChange = (val, key) => {
    handleContent(val, key);
  };

  const generateLink = () => {
    const number = content[FIELDS.TEMPORARY_WHATSAPP_NUMBER];
    const message = content[FIELDS.TEMPORARY_WHATSAPP_MESSAGE].replaceAll(
      ' ',
      '%20'
    );
    const url = 'https://api.whatsapp.com';
    const end_url = `${url}/send?phone=${number}&text=${message}`;

    onChange(end_url, [FIELDS.BUTTON_LINK_URL]);
  };

  useEffect(() => {
    handleMapImages(FIELDS.MEDIA_URL, content[FIELDS.MEDIA_URL]);
  }, [content]);

  useEffect(() => {
    const getArticleCategories = async () => {
      const { data } = await ArticleCategoryApi.get();
      const categories = data.data;

      setArticleCategories(categories);
    };

    getArticleCategories();
  }, []);

  return (
    <Layout className="rata-cms-blog-create-edit">
      <Header>
        <Title>{id ? 'Update' : 'Upload New'} Artikel</Title>
      </Header>

      <Content>
        <Form
          onSubmit={(event) => submit(event)}
          className="pd-admin-create-form">
          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              rules: [
                {
                  required: true,
                  message: 'Please input the title!',
                },
              ],
              initialValue: content[FIELDS.TITLE],
            })(<Input placeholder="Insert title" />)}
          </Form.Item>

          <Form.Item label="Slug">
            {getFieldDecorator(FIELDS.SLUG, {
              rules: [
                {
                  required: true,
                  message: 'Please input the slug!',
                },
              ],
              initialValue: content[FIELDS.SLUG],
            })(<Input placeholder="Insert slug" />)}
          </Form.Item>

          <Form.Item label="Content">
            {getFieldDecorator(FIELDS.CONTENT, {
              rules: [{ required: true, message: 'Please input content!' }],
              initialValue: content[FIELDS.CONTENT] || '',
            })(
              <ReactQuill
                placeholder="Write your content here"
                modules={modules}
                formats={formats}
              />
            )}
          </Form.Item>

          <Form.Item label="Content Summary">
            {getFieldDecorator(FIELDS.SHORT_CONTENT, {
              rules: [
                {
                  required: true,
                  message: 'Please input the summary!',
                },
              ],
              initialValue: content[FIELDS.SHORT_CONTENT],
            })(<Input placeholder="Insert summary" />)}
          </Form.Item>

          <Form.Item label="Category">
            {getFieldDecorator(FIELDS.ARTICLE_CATEGORY, {
              rules: [{ required: true, message: 'Please input the name!' }],
              initialValue: content[FIELDS.ARTICLE_CATEGORY],
            })(
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select banner placement"
                optionFilterProp="children"
                onChange={(val) => onChange(val, FIELDS.ARTICLE_CATEGORY)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }>
                {articleCategories.length > 0 &&
                  articleCategories.map((category) => (
                    <Option value={category.key} key={category.id}>
                      {category.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Banner Picture (1440x920)">
            <PdUploader
              purpose={FIELDS.MEDIA_URL}
              imgUrl={
                get(mapImages, FIELDS.MEDIA_URL, '') ||
                content[FIELDS.MEDIA_URL]
              }
              afterUpload={(url) => {
                handleMapImages([FIELDS.MEDIA_URL], url);
              }}
            />
          </Form.Item>

          <Form.Item label="Source Image">
            {getFieldDecorator(FIELDS.SOURCE_IMAGE, {
              rules: [
                { required: true, message: 'Please input source image!' },
              ],
              initialValue: content[FIELDS.SOURCE_IMAGE],
            })(<Input placeholder="Type source image" />)}
          </Form.Item>

          <Form.Item label="Show Button">
            {getFieldDecorator(FIELDS.BUTTON_IS_ACTIVE, {
              valuePropName: 'checked',
              initialValue: content[FIELDS.BUTTON_IS_ACTIVE] || false,
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Row gutter={[16, 0]}>
            <Col xs={12}>
              <Form.Item label="Desktop Banner Picture (760x200)">
                <PdUploader
                  purpose={FIELDS.BUTTON_DESKTOP_MEDIA_URL}
                  imgUrl={
                    get(mapImages, FIELDS.BUTTON_DESKTOP_MEDIA_URL, '') ||
                    content[FIELDS.BUTTON_DESKTOP_MEDIA_URL]
                  }
                  afterUpload={(url) => {
                    handleMapImages([FIELDS.BUTTON_DESKTOP_MEDIA_URL], url);
                  }}
                />
              </Form.Item>

              <Form.Item label="Desktop Banner ALT Text">
                {getFieldDecorator(FIELDS.DESKTOP_MEDIA_URL_ALT, {
                  rules: [
                    {
                      required: true,
                      message:
                        'Please input button urlDesktop Banner ALT Text!',
                    },
                  ],
                  initialValue: content[FIELDS.DESKTOP_MEDIA_URL_ALT],
                })(<Input placeholder="Input Desktop Banner ALT Text" />)}
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item label="Mobile Banner Picture">
                <PdUploader
                  purpose={FIELDS.MOBILE_MEDIA_URL}
                  imgUrl={
                    get(mapImages, FIELDS.MOBILE_MEDIA_URL, '') ||
                    content[FIELDS.MOBILE_MEDIA_URL]
                  }
                  afterUpload={(url) => {
                    handleMapImages([FIELDS.MOBILE_MEDIA_URL], url);
                  }}
                />
              </Form.Item>

              <Form.Item label="Mobile Banner ALT Text">
                {getFieldDecorator(FIELDS.MOBILE_MEDIA_URL_ALT, {
                  rules: [
                    {
                      required: true,
                      message: 'Please input Mobile Banner ALT Text!',
                    },
                  ],
                  initialValue: content[FIELDS.MOBILE_MEDIA_URL_ALT],
                })(<Input placeholder="Input Mobile Banner ALT Text" />)}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Image Button Text">
            {getFieldDecorator(FIELDS.BUTTON_TEXT, {
              rules: [{ required: true, message: 'Please input button text!' }],
              initialValue: content[FIELDS.BUTTON_TEXT],
            })(<Input placeholder="Input button text" />)}
          </Form.Item>

          <Row gutter={[16, 0]}>
            <Col xs={8}>
              <Form.Item label="Generate Whatsapp Number">
                <Input
                  value={get(content, FIELDS.TEMPORARY_WHATSAPP_NUMBER, '')}
                  placeholder="Input whatsapp number"
                  onChange={(event) =>
                    onChange(
                      event.target.value,
                      FIELDS.TEMPORARY_WHATSAPP_NUMBER
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={16}>
              <Form.Item label="Generate Whatsapp Message">
                <Input
                  value={get(content, FIELDS.TEMPORARY_WHATSAPP_MESSAGE, '')}
                  placeholder="Input whatsapp message"
                  onChange={(event) =>
                    onChange(
                      event.target.value,
                      FIELDS.TEMPORARY_WHATSAPP_MESSAGE
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Button
                style={{ marginBottom: 20 }}
                onClick={() => generateLink()}>
                Generate
              </Button>
            </Col>
          </Row>

          <Form.Item label="Image Button URL">
            {getFieldDecorator(FIELDS.BUTTON_LINK_URL, {
              rules: [{ required: true, message: 'Please input button url!' }],
              initialValue: content[FIELDS.BUTTON_LINK_URL],
            })(<Input placeholder="Type URL" />)}
          </Form.Item>

          <Form.Item label="Image Button Background Color">
            {getFieldDecorator(FIELDS.BUTTON_BACKGROUND_COLOR, {
              getValueFromEvent: (e) =>
                onChange(e.color, FIELDS.BUTTON_BACKGROUND_COLOR),
              initialValue: content[FIELDS.BUTTON_BACKGROUND_COLOR],
            })(
              <ColorPicker
                animation="slide-up"
                color={content[FIELDS.BUTTON_BACKGROUND_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item label="Image Button Font Color">
            {getFieldDecorator(FIELDS.BUTTON_FONT_COLOR, {
              getValueFromEvent: (e) =>
                onChange(e.color, FIELDS.BUTTON_FONT_COLOR),
              initialValue: content[FIELDS.BUTTON_FONT_COLOR],
            })(
              <ColorPicker
                animation="slide-up"
                color={content[FIELDS.BUTTON_FONT_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: '100%' }}
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};

export const ArticleCreateEditForm = Form.create({
  name: 'blog_create_edit_form',
})(ArticleCreateEdit);

export default ArticleCreateEditForm;
