import React, { useState } from 'react';

import { Button, Input, Layout, Form, Typography, message } from 'antd';

import ReactQuill from 'react-quill';

import ClinicApi from 'api/clinic';

import { FIELDS } from './constant';

import 'react-quill/dist/quill.snow.css';

const { Header, Content } = Layout;
const { Title } = Typography;

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const ClinicCreateEdit = ({ form, content }) => {
  const { id } = content;
  const [loadingButton, setLoadingButton] = useState(false);

  const submit = (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      const payload = { ...values };

      if (!err) {
        try {
          setLoadingButton(true);

          if (id) {
            const { data } = await ClinicApi.updateHeadline(payload, id);

            if (data.success) {
              message.success('Berhasil mengubah data');
            }
          }
        } catch (err) {
          message.error('Tidak dapat menghubungi server, cek koneksi');
        } finally {
          setLoadingButton(false);
        }
      }
    });
  };

  const { getFieldDecorator, getFieldsError } = form;

  const hasErrors = (fieldsError) =>
    Object.keys(fieldsError).some((field) => fieldsError[field]);

  return (
    <Layout className="rata-cms-clinic-create-edit">
      <Header>
        <Title>SEO Desc</Title>
      </Header>

      <Content>
        <Form
          onSubmit={(event) => submit(event)}
          className="pd-admin-create-form">
          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              rules: [
                {
                  required: true,
                  message: 'Please input the title!',
                },
              ],
              initialValue: content[FIELDS.TITLE],
            })(<Input placeholder="Insert title" />)}
          </Form.Item>

          <Form.Item label="Content">
            {getFieldDecorator(FIELDS.CONTENT, {
              rules: [{ required: true, message: 'Please input content!' }],
              initialValue: content[FIELDS.CONTENT] || '',
            })(
              <ReactQuill
                placeholder="Write your content here"
                modules={modules}
                formats={formats}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: '100%' }}
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};

export const ClinicSeoForm = Form.create({
  name: 'clinic_create_edit_form',
})(ClinicCreateEdit);

export default ClinicCreateEdit;
