export const modules = {
  clipboard: {
    matchVisual: false,
  },
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
};

export const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

export const FIELDS = {
  ID: 'id',
  TITLE: 'title',
  DESCRIPTION: 'description',
  MEDIA_URL_ALT: 'media_url_alt',
  BUTTON_TEXT: 'promo_button_text',
  BUTTON_LINK: 'promo_button_link',
  MEDIA_URL: 'media_url',
  LINK_URL: 'link_url',
  IS_ACTIVE: 'is_active',
};
