import api from './index';

export default {
  createAdmin(payload) {
    return api.post(`/admins`, { admin: payload });
  },
  listAdmin() {
    return api.get(`/admins`);
  },
  updateAdmin(payload) {
    return api.put(`/admins`, { admin: payload });
  },
  changeAdminPassword(payload) {
    return api.post(`/admins/change_password`, payload);
  },
  deleteAdmin(id) {
    return api.delete(`/admins/${id}`);
  },
};
