import React, { useEffect, useState } from 'react';
import { message, Button, Form, Icon, Input, Layout, Typography } from 'antd';
import { Link } from 'react-router-dom';

import AdminApi from 'api/admin';

const { Header, Content } = Layout;
const { Title } = Typography;

export function AdminCreateEdit({ form, history, location }) {
  const [values, setValues] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);

          if (id) {
            await AdminApi.updateAdmin(values);
          } else {
            await AdminApi.createAdmin(values);
          }

          history.push({
            pathname: '/cms/admin',
          });

          message.success('Berhasil membuat admin baru');
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  function compareToFirstPassword(rule, value, callback) {
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

  useEffect(() => {
    if (id) {
      if (!location.data) {
        history.push({
          pathname: '/cms/admin',
        });
      } else {
        setValues(location.data.admin);
      }
    } else {
      setValues([]);
    }
  }, [location, history, id]);

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/admin">
            <Icon type="caret-left" />
          </Link>
          {id ? 'Edit' : 'Create'} Admin
        </Title>
      </Header>
      <Content>
        <Form
          onSubmit={(event) => handleSubmit(event)}
          className="pd-admin-create-form">
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please input your name!' }],
              initialValue: values && values.name,
            })(<Input placeholder="Name" />)}
          </Form.Item>

          <Form.Item label="Email">
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please input your email!' }],
              initialValue: values && values.email,
            })(<Input placeholder="Email" autoComplete="false" />)}
          </Form.Item>

          {!id && (
            <>
              <Form.Item label="Password">
                {getFieldDecorator('password', {
                  rules: [
                    { required: true, message: 'Please input your Password!' },
                  ],
                  initialValue: values.password,
                })(
                  <Input
                    type="password"
                    placeholder="Password"
                    autoComplete="false"
                  />
                )}
              </Form.Item>

              <Form.Item label="Password Confirmation">
                {getFieldDecorator('password_confirmation', {
                  rules: [
                    { required: true, message: 'Please input your Password!' },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                  initialValue: values.password,
                })(
                  <Input type="password" placeholder="Password Confirmation" />
                )}
              </Form.Item>
            </>
          )}

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const AdminForm = Form.create({
  name: 'validate_create_admin',
})(AdminCreateEdit);

export default { AdminCreateEdit };
