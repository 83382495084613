import React from 'react';

import { Button, Col, Row, Modal } from 'antd';

import './style.scss';

const { confirm } = Modal;

const SelectedMessage = ({
  rows,
  purpose,
  handleHideOrShow,
  handleMultipleDelete,
}) => {
  const showDeleteConfirm = () => {
    confirm({
      title: `You will delete ${rows} posts.`,
      content: "After deleted, you can't undo the process.",
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        handleMultipleDelete();
      },
    });
  };

  return (
    <>
      {rows > 0 && (
        <Row className="rata-cms-selected-message" type="flex" align="middle">
          <Col xs={20}>{rows} rows selected</Col>
          <Col xs={4}>
            {purpose === 'hideOrShow' ? (
              <Button type="primary" onClick={handleHideOrShow}>
                Show on Homepage
              </Button>
            ) : (
              <Button type="primary" onClick={showDeleteConfirm}>
                Delete
              </Button>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default SelectedMessage;
