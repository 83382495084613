export const FIELDS = {
  TITLE: 'title',
  SLUG: 'slug',
  URL: 'url',
  ALT_TEXT: 'alt_text',
  IS_ACTIVE: 'is_active',
  CONTENT: 'content',
  SHORT_CONTENT: 'short_content',
  MEDIA_URL: 'media_url',
  SOURCE_IMAGE: 'source_image',
  ARTICLE_CATEGORY: 'article_category',
  BUTTON_DESKTOP_MEDIA_URL: 'desktop_banner_media_url',
  DESKTOP_MEDIA_URL_ALT: 'desktop_banner_media_url_alt',
  MOBILE_MEDIA_URL: 'mobil_banner_media_url',
  MOBILE_MEDIA_URL_ALT: 'mobil_banner_media_url_alt',
  BUTTON_IS_ACTIVE: 'button_is_active',
  BUTTON_TEXT: 'button_text',
  BUTTON_LINK_URL: 'button_link_url',
  BUTTON_BACKGROUND_COLOR: 'button_background_color',
  BUTTON_FONT_COLOR: 'button_font_color',
  TEMPORARY_WHATSAPP_NUMBER: 'temporary_whatsapp_number',
  TEMPORARY_WHATSAPP_MESSAGE: 'temporary_whatsapp_message',
};

export const CATEGORIES = [
  {
    id: 1,
    key: 'aligner-information',
    name: 'Aligner Information',
  },
  {
    id: 2,
    key: 'aligner-user-guide',
    name: 'Aligner User Guide',
  },
  {
    id: 3,
    key: 'real-stories',
    name: 'Real Stories',
  },
  {
    id: 3,
    key: 'dental-health',
    name: 'Dental Health',
  },
  {
    id: 4,
    key: 'our-community',
    name: 'Our Community',
  },
  {
    id: 5,
    key: 'updates',
    name: 'Updates',
  },
  {
    id: 6,
    key: 'our-stories',
    name: 'Our Stories',
  },
];
