import React, { useEffect, useState } from 'react';
import ColorPicker from 'rc-color-picker';

import { Link } from 'react-router-dom';
import {
  Col,
  Layout,
  Row,
  Typography,
  Button,
  message,
  Modal,
  Form,
  Input,
} from 'antd';

import doctorApi from 'api/v2/doctor';
import homeApi from 'api/v2/homePage';
import { PageSpinner } from 'components/page-spinner';
import _ from 'lodash';
import './style.scss';
import { PdSortable } from 'components/pd-sortable';

import { FIELDS } from './constants';

import { hasFormErrors } from 'utils';

const { Header, Content } = Layout;
const { Title } = Typography;
const { confirm } = Modal;

export const DoctorForm = Form.create({ name: 'doctors' })(({ form }) => {
  const [doctorPageContent, setDoctorPageContent] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  function changeColor(value, key) {
    if (value.color) {
      setDoctorPageContent((prevState) => ({
        ...prevState,
        [key]: value.color,
      }));
      return value.color;
    }
    return;
  }

  function handleDelete(id) {
    confirm({
      title: 'Are you sure to delete this?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        async function deletingDoctor() {
          try {
            await doctorApi.delete(id);
            const newDoctorData = doctors.filter((doctor) => doctor.id !== id);

            setDoctors(newDoctorData);
            message.success('Item has been deleted');
          } catch {
            message.error('Cannot connect to the server');
          }
        }
        deletingDoctor();
      },
      onCancel() {},
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setSubmitLoading(true);
          await homeApi.updateHomePage(values);
          message.success('Data has been updated');
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setSubmitLoading(false);
        }
      }
    });
  }

  async function onSortEnd({ oldIndex, newIndex }) {
    let list = [...doctors];
    let temp = list[oldIndex];
    list.splice(oldIndex, 1);
    list.splice(newIndex, 0, temp);

    const payload = list.map((item, index) => {
      return {
        id: item.id,
        order_level: index,
      };
    });

    try {
      setDoctors(list);
      await doctorApi.updateOrder(payload);

      message.success('Ordering has been updated');
    } catch {
      message.error('Cannot connect to the server');
    }
  }

  useEffect(() => {
    async function getDoctorContent() {
      try {
        setLoadingPage(true);
        const {
          data: { data: homeData },
        } = await homeApi.showHomePage('doctor');

        setDoctorPageContent(_.pick(homeData, Object.values(FIELDS)));

        const { data } = await doctorApi.get();
        const content = data.data;
        setDoctors(content);
      } finally {
        setLoadingPage(false);
      }
    }

    getDoctorContent();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const SortableItem = (doctor) => {
    return (
      <Row
        type="flex"
        align="middle"
        justify="space-around"
        className="doctor-item draggable-item"
        gutter={32}>
        {/* image */}
        <Col span={3}>
          <div className="profile-image">
            <img src={doctor.media_url} alt={doctor.name} />
          </div>
        </Col>

        {/* name */}
        <Col span={13}>
          <div className="container--center">
            <Typography.Title level={4}>{doctor.name}</Typography.Title>
          </div>
        </Col>

        {/* button group */}
        <Col span={8}>
          <Row type="flex" justify="end">
            <Button type="danger" onClick={handleDelete.bind(this, doctor.id)}>
              Delete
            </Button>
            <Button style={{ marginLeft: 24 }} type="primary">
              <Link to={`/cms/home/doctors/edit?id=${doctor.id}`}>Edit</Link>
            </Button>
          </Row>
        </Col>
      </Row>
    );
  };

  const { getFieldDecorator, getFieldsError } = form;

  return (
    <Layout className="pd-cms-home-doctors">
      <Header>
        <Title>Doctors</Title>
      </Header>

      <Content id="drag-content">
        <Title level={2}>Section</Title>

        <Form onSubmit={handleSubmit} className="pd-margin-bottom-xl">
          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.DOCTOR_TITLE, {
              rules: [{ required: true, message: 'Please input the title!' }],
              initialValue: doctorPageContent[FIELDS.DOCTOR_TITLE],
            })(<Input placeholder="Input title" />)}
          </Form.Item>

          <Form.Item label="Description">
            {getFieldDecorator(FIELDS.DOCTOR_DESCRIPTION, {
              rules: [
                { required: true, message: 'Please input the description!' },
              ],
              initialValue: doctorPageContent[FIELDS.DOCTOR_DESCRIPTION],
            })(<Input placeholder="Input description" />)}
          </Form.Item>

          <Form.Item label="Button Text">
            {getFieldDecorator(FIELDS.DOCTOR_ACTION_BUTTON, {
              rules: [
                { required: true, message: 'Please input the button text!' },
              ],
              initialValue: doctorPageContent[FIELDS.DOCTOR_ACTION_BUTTON],
            })(<Input placeholder="Input button text" />)}
          </Form.Item>

          <Form.Item label="Background Color">
            {getFieldDecorator(FIELDS.DOCTOR_BACKGROUND_COLOR, {
              getValueFromEvent: (e) =>
                changeColor(e, FIELDS.DOCTOR_BACKGROUND_COLOR),
            })(
              <ColorPicker
                animation="slide-up"
                color={doctorPageContent[FIELDS.DOCTOR_BACKGROUND_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              loading={submitLoading}
              block
              disabled={hasFormErrors(getFieldsError())}
              type="primary"
              htmlType="submit">
              Publish
            </Button>
          </Form.Item>
        </Form>

        <Title level={2}>Doctor List</Title>

        <Button
          type="primary"
          style={{ fontSize: '18px' }}
          className="pd-margin-bottom-lg">
          <Link to={'/cms/home/doctors/create'}>Add New Doctor</Link>
        </Button>

        <PdSortable
          render={SortableItem}
          items={doctors}
          onSortEnd={onSortEnd}
          lockAxis="y"
          pressDelay={200}
          helperContainer={() => document.getElementById('drag-content')}
        />
      </Content>
    </Layout>
  );
});
