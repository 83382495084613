import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Layout, Typography, message } from 'antd';
import './style.scss';
import { PdUploader } from 'components/pd-uploader';
import _ from 'lodash';
import { alertErr, hasFormErrors } from 'utils';
import { PageSpinner } from 'components/page-spinner';
import covidAPI from 'api/v2/protokolCovid';
const { Header, Content } = Layout;
const { Title } = Typography;

const FIELDS = {
  TITLE: 'header_title',
  DESCRIPTION: 'header_description',
  DESKTOP_VIDEO_BG: 'header_video_background',
  HEADER_YT_LINK: 'header_video_link',
};

function ProtokolCovid(props) {
  const [page, setPage] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const { form } = props;
  const { TextArea } = Input;

  const { getFieldDecorator, getFieldsError } = form;

  function handleSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          // avoid overfetch and unwanted mutation
          const payload = _.pick({ ...page, ...values }, Object.values(FIELDS));
          await covidAPI.updateCovidData(payload);
          message.success('Data has been updated');
        } catch (err) {
          alertErr(err);
        } finally {
          setLoadingButton(false);
          await getPageData();
        }
      }
    });
  }

  async function getPageData() {
    setLoadingPage(true);
    try {
      const res = await covidAPI.getCovidData();
      const pageData = _.pick(_.get(res, 'data.data'), Object.values(FIELDS));
      setPage(pageData);
    } catch (err) {
      alertErr(err);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getPageData();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-protokol-covid pd-cms-form-1">
      <Header>
        <Title>Protokol Covid-19</Title>
      </Header>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Banner Video (1440x810 px)">
            <PdUploader
              purpose="covid"
              type="video"
              afterUpload={(url) => {
                setPage((prevState) => ({
                  ...prevState,
                  [FIELDS.DESKTOP_VIDEO_BG]: url,
                }));
              }}
              imgUrl={page[FIELDS.DESKTOP_VIDEO_BG]}
            />
          </Form.Item>

          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              initialValue: page[FIELDS.TITLE],
            })(<Input placeholder="Specify the page title" />)}
          </Form.Item>

          <Form.Item label="Description">
            {getFieldDecorator(FIELDS.DESCRIPTION, {
              initialValue: page[FIELDS.DESCRIPTION],
            })(<TextArea placeholder="Write the page description" />)}
          </Form.Item>

          <Form.Item label="Video Link">
            {getFieldDecorator(FIELDS.HEADER_YT_LINK, {
              initialValue: page[FIELDS.HEADER_YT_LINK],
            })(<Input placeholder="Specify the page youtube link" />)}
          </Form.Item>

          <Form.Item>
            <Button
              className="pd-margin-top-lg pd-margin-bottom-md"
              block
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasFormErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const ProtokolCovidForm = Form.create({
  name: 'covid_page',
})(ProtokolCovid);
